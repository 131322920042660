import { createRequestTypes, action } from '../utils/actions';
import { REQUEST, SUCCESS, FAILURE } from '../utils/constants';

export const GET_KEYCLOAK_USER_EVENTS = createRequestTypes('GET_KEYCLOAK_USER_EVENTS');
export const GET_ALL_AND_UNREAD_FEED_ITEMS_COUNT = createRequestTypes('GET_ALL_AND_UNREAD_FEED_ITEMS_COUNT');
export const GET_ALL_USERS = createRequestTypes('GET_ALL_USERS');
export const GET_ALL_USER_CUBES = createRequestTypes('GET_ALL_USER_CUBES');
export const GET_ALL_HEALTH_ASSISTANTS = createRequestTypes('GET_ALL_HEALTH_ASSISTANTS');
export const GET_ALL_USER_CUBES_FOR_ID = createRequestTypes('GET_ALL_USER_CUBES_FOR_ID');
export const GET_ACTIVITY_CSV = createRequestTypes('GET_ACTIVITY_CSV');

export const getKeycloakUserEvents = {
  request: (data) => action(GET_KEYCLOAK_USER_EVENTS[REQUEST], { data }),
  success: (payload) => action(GET_KEYCLOAK_USER_EVENTS[SUCCESS], { payload }),
  failure: (payload) => action(GET_KEYCLOAK_USER_EVENTS[FAILURE], { payload }),
};

export const getAllAndUnreadFeedItemsCount = {
  request: () => action(GET_ALL_AND_UNREAD_FEED_ITEMS_COUNT[REQUEST], {}),
  success: (payload) => action(GET_ALL_AND_UNREAD_FEED_ITEMS_COUNT[SUCCESS], { payload }),
  failure: (payload) => action(GET_ALL_AND_UNREAD_FEED_ITEMS_COUNT[FAILURE], { payload }),
};

export const getAllUsers = {
  request: () => action(GET_ALL_USERS[REQUEST], {}),
  success: (payload) => action(GET_ALL_USERS[SUCCESS], { payload }),
  failure: (payload) => action(GET_ALL_USERS[FAILURE], { payload }),
};

export const getAllUserCubes = {
  request: () => action(GET_ALL_USER_CUBES[REQUEST], {}),
  success: (payload) => action(GET_ALL_USER_CUBES[SUCCESS], { payload }),
  failure: (payload) => action(GET_ALL_USER_CUBES[FAILURE], { payload }),
};

export const getAllHealthAssistants = {
  request: () => action(GET_ALL_HEALTH_ASSISTANTS[REQUEST], {}),
  success: (payload) => action(GET_ALL_HEALTH_ASSISTANTS[SUCCESS], { payload }),
  failure: (payload) => action(GET_ALL_HEALTH_ASSISTANTS[FAILURE], { payload }),
};

export const getAllUserCubesForId = {
  request: (id) => action(GET_ALL_USER_CUBES_FOR_ID[REQUEST], { id }),
  success: (payload) => action(GET_ALL_USER_CUBES_FOR_ID[SUCCESS], { payload }),
  failure: (payload) => action(GET_ALL_USER_CUBES_FOR_ID[FAILURE], { payload }),
};

export const getActivityCsv = {
  request: () => action(GET_ACTIVITY_CSV[REQUEST], {}),
  success: (payload) => action(GET_ACTIVITY_CSV[SUCCESS], { payload }),
  failure: (payload) => action(GET_ACTIVITY_CSV[FAILURE], { payload }),
};
