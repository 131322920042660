import VerticalNavWrapper from '../AppNav/VerticalNavWrapper';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

import logoLight from '../../assets/utils/images/logo_white.png';
import { Stack } from 'react-bootstrap';

const Sidebar = ({ toggled, setToggled }) => (
  <>
    <Stack style={{ alignItems: 'center', backgroundColor: '#43425d', height: '100%', position: 'fixed', zIndex: 1001 }}>
      <img className="sidebar-logo" alt="sidebar-icon" src={logoLight} style={{ width: '190px', margin: '20px 0' }} />
      <ReactCSSTransitionGroup component="div" className={'d-sidebar-nav'} transitionName="SidebarAnimation" transitionAppear={true} transitionAppearTimeout={1500} transitionEnter={false} transitionLeave={false}>
        <VerticalNavWrapper toggled={toggled} setToggled={setToggled} />
      </ReactCSSTransitionGroup>
    </Stack>
    {/* HACK block space for fixed sidebar */}
    <Stack style={{ alignItems: 'center', backgroundColor: '#43425d', height: '100%', visibility: 'hidden' }}>
      <img className="sidebar-logo" alt="sidebar-icon" src={logoLight} style={{ width: '190px', margin: '20px 0' }} />
      <ReactCSSTransitionGroup component="div" className={'d-sidebar-nav'} transitionName="SidebarAnimation" transitionAppear={true} transitionAppearTimeout={1500} transitionEnter={false} transitionLeave={false}>
        <VerticalNavWrapper toggled={toggled} setToggled={setToggled} />
      </ReactCSSTransitionGroup>
    </Stack>
  </>
);

export default Sidebar;
