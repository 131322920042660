import * as ActionTypes from 'actions/Filters';
import { get } from 'lodash';

const initialState = {
  filters: [],
  allFilters: [],
  filter: {},
  filtersTableFilter: '',
  filtersTableSorting: [],
  listPage: 0,
  listPages: 0,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.RESET_FILTER:
      return { ...initialState };
    case ActionTypes.GET_FILTERS.SUCCESS:
      return {
        ...state,
        filters: get(action, 'payload.content', []),
        listPage: get(action, 'payload.currentPage', 0),
        listPages: get(action, 'payload.totalPages', 1),
      };
    case ActionTypes.GET_FILTER.SUCCESS:
      return {
        ...state,
        filter: action.payload,
      };
    case ActionTypes.GET_ALL_FILTERS.SUCCESS:
      return {
        ...state,
        allFilters: action.payload,
      };
    case ActionTypes.UPDATE_FILTER.SUCCESS:
      return {
        ...state,
        filter: action.payload,
      };
    case ActionTypes.DELETE_FILTER.SUCCESS:
      return {
        ...state,
        filters: state.filters.filter((el) => el.id !== action.payload),
      };
    case ActionTypes.SET_FILTERS_TABLE_FILTER:
      return { ...state, filtersTableFilter: action.payload };
    case ActionTypes.SET_FILTERS_TABLE_SORTING:
      return { ...state, filtersTableSorting: action.payload };
    default:
      return state;
  }
}
