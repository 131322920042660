import { takeEvery, takeLatest } from 'redux-saga/effects';
import { secureApiRequest } from './index';
import api from 'utils/api';
import * as documentActions from 'actions/Documents';
import history from 'history/browser';

const getDocuments = secureApiRequest.bind(null, documentActions.getDocuments, api.getDocuments);
const getDocument = secureApiRequest.bind(null, documentActions.getDocument, api.getDocument);
const getDocumentByVersion = secureApiRequest.bind(null, documentActions.getDocumentByVersion, api.getDocumentByVersion);
const createDocument = secureApiRequest.bind(null, documentActions.createDocument, api.createDocument);
const updateDocument = secureApiRequest.bind(null, documentActions.updateDocument, api.updateDocument);
const deleteDocument = secureApiRequest.bind(null, documentActions.deleteDocument, api.deleteDocument);
const createDocumentPermissions = secureApiRequest.bind(null, documentActions.createDocumentPermissions, api.createDocumentPermissions);
const deleteDocumentPermissions = secureApiRequest.bind(null, documentActions.deleteDocumentPermissions, api.deleteDocumentPermissions);
const getDocumentPermissions = secureApiRequest.bind(null, documentActions.getDocumentPermissions, api.getDocumentPermissions);

function* handleChangeDocument() {
  yield history.push('/documents');
}

/******************************************************************************/
/******************************* WATCHERS *************************************/
/******************************************************************************/

export function* watchGetDocumentsRequest() {
  yield takeLatest(documentActions.GET_DOCUMENTS.REQUEST, getDocuments);
}

export function* watchGetDocumentRequest() {
  yield takeLatest(documentActions.GET_DOCUMENT.REQUEST, getDocument);
}

export function* watchGetDocumentByVersionRequest() {
  yield takeLatest(documentActions.GET_DOCUMENT_BY_VERSION.REQUEST, getDocumentByVersion);
}

export function* watchCreateDocumentRequest() {
  yield takeEvery(documentActions.CREATE_DOCUMENT.REQUEST, createDocument);
}

export function* watchUpdateDocumentRequest() {
  yield takeEvery(documentActions.UPDATE_DOCUMENT.REQUEST, updateDocument);
}

export function* watchDeleteDocumentRequest() {
  yield takeEvery(documentActions.DELETE_DOCUMENT.REQUEST, deleteDocument);
}

export function* watchCreateDocumentSuccess() {
  yield takeEvery(documentActions.CREATE_DOCUMENT.SUCCESS, handleChangeDocument);
}

export function* watchUpdateDocumentSuccess() {
  yield takeEvery(documentActions.UPDATE_DOCUMENT.SUCCESS, handleChangeDocument);
}

export function* watchGetDocumentPermissionsRequest() {
  yield takeLatest(documentActions.GET_DOCUMENT_PERMISSIONS.REQUEST, getDocumentPermissions);
}

export function* watchCreateDocumentPermissionsRequest() {
  yield takeEvery(documentActions.CREATE_DOCUMENT_PERMISSIONS.REQUEST, createDocumentPermissions);
}

export function* watchDeleteDocumentPermissionsRequest() {
  yield takeEvery(documentActions.DELETE_DOCUMENT_PERMISSIONS.REQUEST, deleteDocumentPermissions);
}
