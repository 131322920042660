export const CUBE_STATUS_ACTIVE = 'ACTIVE';
export const CUBE_STATUS_INACTIVE = 'INACTIVE';
export const CUBE_STATUS_READY = 'READY';

export const HEALTH_ASSISTANT_STATUS_ACTIVE = 'ACTIVE';
export const HEALTH_ASSISTANT_STATUS_INACTIVE = 'INACTIVE';
export const HEALTH_ASSISTANT_STATUS_FINISHED = 'FINISHED';
export const HEALTH_ASSISTANT_STATUS_STARTED = 'STARTED';

export const USER_DETAILS_TAB_COCKPIT = 'COCKPIT';
export const USER_DETAILS_TAB_BASIC_INFO = 'BASIC_INFO';
export const USER_DETAILS_TAB_CALENDAR = 'CALENDAR';
export const USER_DETAILS_TAB_CALENDAR_ICAL = 'CALENDAR ICAL';
export const USER_DETAILS_TAB_NEWS = 'NEWS';
export const USER_DETAILS_TAB_PRODUCTS = 'PRODUCTS';
export const USER_DETAILS_TAB_TAGS = 'TAGS';
export const USER_DETAILS_TAB_ATTRIBUTES = 'ATTRIBUTES';
export const USER_DETAILS_TAB_ACTIVE_PROCESSES = 'ACTIVE_PROCESSES';
export const USER_DETAILS_TAB_ARCHIVE_PROCESSES = 'ARCHIVE_PROCESSES';
export const USER_DETAILS_TAB_ACTIVE_CUBES = 'ACTIVE_CUBES';
export const USER_DETAILS_TAB_ARCHIVE_CUBES = 'ARCHIVE_CUBES';
export const USER_DETAILS_HEALTH_ASSISTANTS = 'HEALTH_ASSISTANTS';

export const COUNTRY_OPTION_AUSTRIA = 'Austria';
export const COUNTRY_OPTION_GERMANY = 'Germany';
export const COUNTRY_OPTION_SWITZERLAND = 'Switzerland';

export const GENDER_OPTIONS_FEMALE = 'Weiblich';
export const GENDER_OPTIONS_MALE = 'Männlich';
export const GENDER_OPTIONS_DIVERS = 'Divers';

export const COUNTRY_OPTIONS = [
  { value: COUNTRY_OPTION_AUSTRIA, label: 'Österreich' },
  { value: COUNTRY_OPTION_GERMANY, label: 'Deutschland' },
  { value: COUNTRY_OPTION_SWITZERLAND, label: 'Schweiz' },
];

export const SALUTATION_OPTIONS = [
  { label: 'Frau', value: 'Frau' },
  { label: 'Herr', value: 'Herr' },
  { label: 'Liebe*r', value: 'Liebe*r' },
];

export const GENDER_OPTIONS = [
  { label: GENDER_OPTIONS_FEMALE, value: 'female' },
  { label: GENDER_OPTIONS_MALE, value: 'male' },
  { label: GENDER_OPTIONS_DIVERS, value: 'non-binary' },
];
