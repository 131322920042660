import { takeEvery, all, put, takeLatest } from 'redux-saga/effects';
import { apiRequest } from './index';
import FiltersApi from '../api/Filters';
import * as filtersActions from 'actions/Filters';
import { toast } from 'react-toastify';
import history from 'history/browser';

const getFilters = apiRequest.bind(null, filtersActions.getFilters, FiltersApi.getFilters);
const getAllFilters = apiRequest.bind(null, filtersActions.getAllFilters, FiltersApi.getAllFilters);
const getFilter = apiRequest.bind(null, filtersActions.getFilter, FiltersApi.getFilter);

function* createFilter({ data, options }) {
  const { response, error } = yield FiltersApi.createFilter(data);
  if (error) {
    yield put({
      type: filtersActions.CREATE_FILTER.FAILURE,
      payload: error,
    });
    return;
  }
  yield put({
    type: filtersActions.CREATE_FILTER.SUCCESS,
    payload: response,
  });
}

function* deleteFilter({ id }) {
  const { error } = yield FiltersApi.deleteFilter(id);
  if (error) {
    yield put({ type: filtersActions.DELETE_FILTER.FAILURE, payload: error });
  } else {
    yield put({ type: filtersActions.DELETE_FILTER.SUCCESS, payload: id });
  }
}

function* updateFilter({ id, data, options }) {
  const { response, error } = yield FiltersApi.updateFilter(data, id);
  if (error) {
    yield put({
      type: filtersActions.UPDATE_FILTER.FAILURE,
      payload: error,
    });
    return;
  }
  yield put({
    type: filtersActions.UPDATE_FILTER.SUCCESS,
    payload: response,
  });

  if (options === 'redirect') {
    yield history.push('/filters');
  }
}

const successSaveHandler = () => {
  toast.success('Gespeichert');
};

/******************************************************************************/
/******************************* WATCHERS *************************************/

/******************************************************************************/
export function* rootWatcherFilter() {
  yield all([
    takeLatest(filtersActions.GET_FILTERS.REQUEST, getFilters),
    takeLatest(filtersActions.GET_ALL_FILTERS.REQUEST, getAllFilters),
    takeLatest(filtersActions.GET_FILTER.REQUEST, getFilter),
    takeEvery(filtersActions.UPDATE_FILTER.REQUEST, updateFilter),
    takeEvery(filtersActions.CREATE_FILTER.REQUEST, createFilter),
    takeEvery(filtersActions.DELETE_FILTER.REQUEST, deleteFilter),
    takeEvery(filtersActions.UPDATE_FILTER.SUCCESS, successSaveHandler),
    takeEvery(filtersActions.CREATE_FILTER.SUCCESS, successSaveHandler),
  ]);
}
