export const SET_USER_ROLE = 'SET_USER_ROLE';
export default function reducer(state = {
  userRole: ''
}, action) {
  switch(action.type) {
    case SET_USER_ROLE:
      return {...state, userRole: action.payload}
    default:
      return state;
  }
}
