import * as ActionTypes from "actions/Calendar";

const initialState = {
  entries: [],
  entry: {},
  userGroups: [],
  generatedEntries: []
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.GET_CALENDAR_ENTRIES.SUCCESS:
      return {
        ...state,
        entries: action.payload,
      };
    case ActionTypes.SET_ENTRIES:
      return {
        ...state,
        generatedEntries: action.payload,
      };
    case ActionTypes.SET_CALENDAR_DATE_RANGE:
      return {
        ...state,
        calendarDateRange: action.payload,
      }
    default:
      return state;
  }
}
