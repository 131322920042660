import { createRequestTypes, action } from '../utils/actions';
import { REQUEST, SUCCESS, FAILURE } from '../utils/constants';

export const GET_CALENDAR_ICAL_ENTRIES = createRequestTypes('GET_CALENDAR_ICAL_ENTRIES');
export const SET_CALENDAR_ICAL_DATE_RANGE = 'SET_CALENDAR_ICAL_DATE_RANGE';
export const SET_ICAL_ENTRIES = 'SET_ICAL_ENTRIES';

export const getCalendarICalEntries = {
  request: (data) => action(GET_CALENDAR_ICAL_ENTRIES[REQUEST], { data }),
  success: (payload) => action(GET_CALENDAR_ICAL_ENTRIES[SUCCESS], { payload }),
  failure: (payload) => action(GET_CALENDAR_ICAL_ENTRIES[FAILURE], { payload }),
};

export const setICalEntries = (payload) => ({
  type: SET_ICAL_ENTRIES,
  payload,
});

export const setCalendarICalDateRange = (payload) => {
  return {
    type: SET_CALENDAR_ICAL_DATE_RANGE,
    payload,
  };
};
