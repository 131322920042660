import axios from 'axios';
import { get, omit } from 'lodash';
import { userServiceUrl } from 'config/environment';

const getHealthProfiles = () => {
  return axios
    .get(`${userServiceUrl}/api/healthProfile/v1.0/`)
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message:
          get(err, 'response.data.message', 'Die Anfrage ist fehlgeschlagen') ||
          'Die Anfrage ist fehlgeschlagen',
      },
    }));
}

const getHealthProfile = (id) => {
  return axios
    .get(`${userServiceUrl}/api/healthProfile/${id}/v1.0/`)
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message:
          get(err, 'response.data.message', 'Die Anfrage ist fehlgeschlagen') ||
          'Die Anfrage ist fehlgeschlagen',
      },
    }));
}

const updateHealthProfile = (data) => {
  return axios
    .put(`${userServiceUrl}/api/healthProfile/${data.id}/v1.0/`, omit(data, 'id'))
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message:
          get(err, 'response.data.message', 'Die Anfrage ist fehlgeschlagen') ||
          'Die Anfrage ist fehlgeschlagen',
      },
    }));
}

const uploadFile = (data) => {
  const payload = new FormData();
  payload.append('file', data);
  return axios({
    method: 'post',
    headers: {
      'X-File-Length': data.size,
    },
    url: `${userServiceUrl}/api/files/temp/upload/v1.0/`,
    data: payload,
  })
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

export default {
  getHealthProfiles,
  getHealthProfile,
  updateHealthProfile,
  uploadFile
}
