import { createRequestTypes, action } from '../utils/actions';
import { REQUEST, SUCCESS, FAILURE } from '../utils/constants';

export const GET_CUBE_GROUPS = createRequestTypes('GET_CUBE_GROUPS');
export const GET_ALL_CUBE_GROUPS = createRequestTypes('GET_ALL_CUBE_GROUPS');
export const GET_CUBE_GROUP = createRequestTypes('GET_CUBE_GROUP');
export const UPDATE_CUBE_GROUP = createRequestTypes('UPDATE_CUBE_GROUP');
export const CREATE_CUBE_GROUP = createRequestTypes('CREATE_CUBE_GROUP');
export const DELETE_CUBE_GROUP = createRequestTypes('DELETE_CUBE_GROUP');

export const SET_CUBE_GROUPS_TABLE_FILTER = 'SET_CUBE_GROUPS_TABLE_FILTER';
export const SET_CUBE_GROUPS_TABLE_SORTING = 'SET_CUBE_GROUPS_TABLE_SORTING';

export const RESET_CUBE_GROUP = 'RESET_CUBE_GROUP';

export const getCubeGroups = {
  request: (data) => action(GET_CUBE_GROUPS[REQUEST], { data }),
  success: (payload) => action(GET_CUBE_GROUPS[SUCCESS], { payload }),
  failure: (payload) => action(GET_CUBE_GROUPS[FAILURE], { payload }),
};

export const getAllCubeGroups = {
  request: () => action(GET_ALL_CUBE_GROUPS[REQUEST]),
  success: (payload) => action(GET_ALL_CUBE_GROUPS[SUCCESS], { payload }),
  failure: (payload) => action(GET_ALL_CUBE_GROUPS[FAILURE], { payload }),
};

export const getCubeGroup = {
  request: (id) => action(GET_CUBE_GROUP[REQUEST], { id }),
  success: (payload) => action(GET_CUBE_GROUP[SUCCESS], { payload }),
  failure: (payload) => action(GET_CUBE_GROUP[FAILURE], { payload }),
};

export const updateCubeGroup = {
  request: (id, data, options) => action(UPDATE_CUBE_GROUP[REQUEST], { data, id, options }),
  success: (payload) => action(UPDATE_CUBE_GROUP[SUCCESS], { payload }),
  failure: (payload) => action(UPDATE_CUBE_GROUP[FAILURE], { payload }),
};

export const deleteCubeGroup = {
  request: (id) => action(DELETE_CUBE_GROUP[REQUEST], { id }),
  success: (payload) => action(DELETE_CUBE_GROUP[SUCCESS], { payload }),
  failure: (payload) => action(DELETE_CUBE_GROUP[FAILURE], { payload }),
};

export const createCubeGroup = {
  request: (data, options) => action(CREATE_CUBE_GROUP[REQUEST], { data, options }),
  success: (payload) => action(CREATE_CUBE_GROUP[SUCCESS], { payload }),
  failure: (payload) => action(CREATE_CUBE_GROUP[FAILURE], { payload }),
};

export const setCubeGroupsTableFilter = (payload) => ({
  type: SET_CUBE_GROUPS_TABLE_FILTER,
  payload,
});

export const setCubeGroupsTableSorting = (payload) => ({
  type: SET_CUBE_GROUPS_TABLE_SORTING,
  payload,
});

export const resetCubeGroup = () => ({
  type: RESET_CUBE_GROUP,
});
