import * as loadingActions from 'actions/Loading';

export default function reducer(state = {
  loading: false
}, action) {
    if (action.type === loadingActions.SET_LOADING) return { loading: action.payload }
    if (action.type.includes('TOKEN')) return state;
    if (action.type.includes('REQUEST') && !(action.meta?.skipLoading)) return { loading: true }
    if (action.type.includes('SUCCESS')) return { loading: false }
    if (action.type.includes('FAILURE')) return { loading: false }
    return state
}
