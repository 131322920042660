import { createRequestTypes, action } from '../utils/actions';
import { REQUEST, SUCCESS, FAILURE } from '../utils/constants';

export const GET_HEALTH_PROFILES = createRequestTypes('GET_HEALTH_PROFILES');
export const GET_HEALTH_PROFILE = createRequestTypes('GET_HEALTH_PROFILE');

export const RESET_HEALTH_PROFILE = 'RESET_HEALTH_PROFILE';

export const getHealthProfiles = {
  request: () => action(GET_HEALTH_PROFILES[REQUEST]),
  success: (payload) => action(GET_HEALTH_PROFILES[SUCCESS], { payload }),
  failure: (payload) => action(GET_HEALTH_PROFILES[FAILURE], { payload }),
};

export const getHealthProfile = {
  request: (id) => action(GET_HEALTH_PROFILE[REQUEST], { id }),
  success: (payload) => action(GET_HEALTH_PROFILE[SUCCESS], { payload }),
  failure: (payload) => action(GET_HEALTH_PROFILE[FAILURE], { payload }),
};

export const resetHealthProfile = () => ({
  type: RESET_HEALTH_PROFILE,
});
