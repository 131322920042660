import { createRequestTypes, action } from '../utils/actions';
import { REQUEST, SUCCESS, FAILURE } from '../utils/constants';

export const GET_CUBES = createRequestTypes('GET_CUBES');
export const GET_CUBE = createRequestTypes('GET_CUBE');
export const UPDATE_CUBE = createRequestTypes('UPDATE_CUBE');
export const GET_HEALTH_ASSISTANTS = createRequestTypes('GET_HEALTH_ASSISTANTS');
export const CREATE_CUBE = createRequestTypes('CREATE_CUBE');
export const DELETE_CUBE = createRequestTypes('DELETE_CUBE');
export const GET_USER_CUBES = createRequestTypes('GET_USER_CUBES');
export const START_CUBE = createRequestTypes('START_CUBE');
export const STOP_CUBE = createRequestTypes('STOP_CUBE');
export const START_USER_CUBE = createRequestTypes('START_USER_CUBE');

export const RESET_CUBE_PROPS = 'RESET_CUBE_PROPS';

export const getCubes = {
  request: () => action(GET_CUBES[REQUEST]),
  success: (payload) => action(GET_CUBES[SUCCESS], { payload }),
  failure: (payload) => action(GET_CUBES[FAILURE], { payload }),
};

export const getCube = {
  request: (id) => action(GET_CUBE[REQUEST], { id }),
  success: (payload) => action(GET_CUBE[SUCCESS], { payload }),
  failure: (payload) => action(GET_CUBE[FAILURE], { payload }),
};

export const updateCube = {
  request: (id, data, meta) => action(UPDATE_CUBE[REQUEST], { data, id, meta }),
  success: (payload) => action(UPDATE_CUBE[SUCCESS], { payload }),
  failure: (payload) => action(UPDATE_CUBE[FAILURE], { payload }),
};

export const deleteCube = {
  request: (id) => action(DELETE_CUBE[REQUEST], { id }),
  success: (payload) => action(DELETE_CUBE[SUCCESS], { payload }),
  failure: (payload) => action(DELETE_CUBE[FAILURE], { payload }),
};

export const createCube = {
  request: (data, meta) => action(CREATE_CUBE[REQUEST], { data, meta }),
  success: (payload) => action(CREATE_CUBE[SUCCESS], { payload }),
  failure: (payload) => action(CREATE_CUBE[FAILURE], { payload }),
};

export const getUserCubes = {
  request: (id) => action(GET_USER_CUBES[REQUEST], { id }),
  success: (payload) => action(GET_USER_CUBES[SUCCESS], { payload }),
  failure: (payload) => action(GET_USER_CUBES[FAILURE], { payload }),
};

export const startCube = {
  request: (data) => action(START_CUBE[REQUEST], { data }),
  success: (payload) => action(START_CUBE[SUCCESS], { payload }),
  failure: (payload) => action(START_CUBE[FAILURE], { payload }),
};

export const stopUserCube = {
  request: (id) => action(STOP_CUBE[REQUEST], { id }),
  success: (payload) => action(STOP_CUBE[SUCCESS], { payload }),
  failure: (payload) => action(STOP_CUBE[FAILURE], { payload }),
};

export const startUserCube = {
  request: (data) => action(START_USER_CUBE[REQUEST], { data }),
  success: (payload) => action(START_USER_CUBE[SUCCESS], { payload }),
  failure: (payload) => action(START_USER_CUBE[FAILURE], { payload }),
};

export const resetCubeProps = () => ({
  type: RESET_CUBE_PROPS,
});
