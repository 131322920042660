import { createRequestTypes, action } from "../utils/actions";
import { REQUEST, SUCCESS, FAILURE } from "../utils/constants";

export const GET_GROUPS = createRequestTypes("GET_GROUPS");
export const GET_GROUP = createRequestTypes("GET_GROUP");
export const CREATE_GROUP = createRequestTypes("CREATE_GROUP");
export const UPDATE_GROUP = createRequestTypes("UPDATE_GROUP");
export const DELETE_GROUP = createRequestTypes("DELETE_GROUP");
export const ADD_USERS_TO_GROUP = createRequestTypes("ADD_USERS_TO_GROUP");
export const DELETE_USERS_FROM_GROUP = createRequestTypes("DELETE_USERS_FROM_GROUP");
export const GET_GROUP_USERS = createRequestTypes("GET_GROUP_USERS");
export const GET_USER_GROUPS = createRequestTypes("GET_USER_GROUPS");
export const SET_GROUPS_TABLE_SORTING = 'SET_GROUPS_TABLE_SORTING';
export const SET_GROUPS_TABLE_FILTER = 'SET_GROUPS_TABLE_FILTER';

export const getGroups = {
  request: () => action(GET_GROUPS[REQUEST]),
  success: (payload) => action(GET_GROUPS[SUCCESS], { payload }),
  failure: (payload) => action(GET_GROUPS[FAILURE], { payload }),
};

export const getGroup = {
  request: (id) => action(GET_GROUP[REQUEST], { id }),
  success: (payload) => action(GET_GROUP[SUCCESS], { payload }),
  failure: (payload) => action(GET_GROUP[FAILURE], { payload }),
};

export const createGroup = {
  request: (data) => action(CREATE_GROUP[REQUEST], { data }),
  success: (payload) => action(CREATE_GROUP[SUCCESS], { payload }),
  failure: (payload) => action(CREATE_GROUP[FAILURE], { payload }),
};

export const updateGroup = {
  request: (id, data) => action(UPDATE_GROUP[REQUEST], { id, data }),
  success: (payload) => action(UPDATE_GROUP[SUCCESS], { payload }),
  failure: (payload) => action(UPDATE_GROUP[FAILURE], { payload }),
};

export const deleteGroup = {
  request: (id) => action(DELETE_GROUP[REQUEST], { id }),
  success: (payload) => action(DELETE_GROUP[SUCCESS], { payload }),
  failure: (payload) => action(DELETE_GROUP[FAILURE], { payload }),
};

export const getGroupUsers = {
  request: (id) => action(GET_GROUP_USERS[REQUEST], {id}),
  success: (payload) => action(GET_GROUP_USERS[SUCCESS], {payload}),
  failure: (payload) => action(GET_GROUP_USERS[FAILURE], {payload}),
};

export const getUserGroups = {
  request: (id) => action(GET_USER_GROUPS[REQUEST], {id}),
  success: (payload) => action(GET_USER_GROUPS[SUCCESS], {payload}),
  failure: (payload) => action(GET_USER_GROUPS[FAILURE], {payload}),
};

export const addUsersToGroup = {
  request: (data) => action(ADD_USERS_TO_GROUP[REQUEST], {data}),
  success: (payload) => action(ADD_USERS_TO_GROUP[SUCCESS], {payload}),
  failure: (payload) => action(ADD_USERS_TO_GROUP[FAILURE], {payload}),
}

export const deleteUsersFromGroup = {
  request: (data) => action(DELETE_USERS_FROM_GROUP[REQUEST], {data}),
  success: (payload) => action(DELETE_USERS_FROM_GROUP[SUCCESS], {payload}),
  failure: (payload) => action(DELETE_USERS_FROM_GROUP[FAILURE], {payload}),
}

export const setGroupsTableFilter = (payload) => ({
  type: SET_GROUPS_TABLE_FILTER,
  payload
})

export const setGroupsTableSorting = (payload) => ({
  type: SET_GROUPS_TABLE_SORTING,
  payload
})