import { all, takeEvery, put, takeLatest } from 'redux-saga/effects';
import { apiRequest } from './index';
import AuthorsApi from '../api/Authors';
import * as authorsActions from 'actions/Authors';
import { toast } from 'react-toastify';

const getAuthors = apiRequest.bind(null, authorsActions.getAuthors, AuthorsApi.getAuthors);
const getAuthorsNews = apiRequest.bind(null, authorsActions.getAuthorsNews, AuthorsApi.getAuthorsNews);
const getAuthor = apiRequest.bind(null, authorsActions.getAuthor, AuthorsApi.getAuthor);

function* updateAuthor({ id, data }) {
  const { response, error } = yield AuthorsApi.updateAuthor(id, data);
  if (error) {
    yield put({
      type: authorsActions.UPDATE_AUTHOR.FAILURE,
      payload: error,
    });
  } else {
    yield put({
      type: authorsActions.UPDATE_AUTHOR.SUCCESS,
      payload: response,
    });
  }
}

function* deleteAuthor({ id }) {
  const { error } = yield AuthorsApi.deleteAuthor(id);
  if (error) {
    yield put({ type: authorsActions.DELETE_AUTHOR.FAILURE, payload: error });
  } else {
    yield put({ type: authorsActions.DELETE_AUTHOR.SUCCESS, payload: id });
  }
}

function* createAuthor({ data }) {
  const { response, error } = yield AuthorsApi.createAuthor(data);
  if (error) {
    yield put({
      type: authorsActions.CREATE_AUTHOR.FAILURE,
      payload: error,
    });
    return;
  }
  yield put({
    type: authorsActions.CREATE_AUTHOR.SUCCESS,
    payload: response,
  });
}

const authorSaveHandler = () => {
  toast.success('Gespeichert');
};

/******************************************************************************/
/******************************* WATCHERS *************************************/
/******************************************************************************/

export function* rootWatcherAuthors() {
  yield all([
    takeLatest(authorsActions.GET_AUTHORS.REQUEST, getAuthors),
    takeEvery(authorsActions.CREATE_AUTHOR.REQUEST, createAuthor),
    takeEvery(authorsActions.CREATE_AUTHOR.SUCCESS, authorSaveHandler),
    takeLatest(authorsActions.GET_AUTHOR.REQUEST, getAuthor),
    takeEvery(authorsActions.UPDATE_AUTHOR.REQUEST, updateAuthor),
    takeEvery(authorsActions.UPDATE_AUTHOR.SUCCESS, authorSaveHandler),
    takeEvery(authorsActions.DELETE_AUTHOR.REQUEST, deleteAuthor),
    takeLatest(authorsActions.GET_AUTHORS_NEWS.REQUEST, getAuthorsNews),
  ]);
}
