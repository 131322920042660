import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { navItemsByRole } from './NavItems';
import NavItem from './NavItem';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { Sidebar, Menu, MenuItem, SubMenu, sidebarClasses, menuClasses } from 'react-pro-sidebar';

const VerticalNavWrapper = (props) => {
  const location = useLocation();
  const [subMenuOpen, setSubMenuOpen] = useState(location.pathname.includes('categor') || location.pathname.includes('news'));
  const navigate = useNavigate();

  useEffect(() => {
    setSubMenuOpen(location.pathname.includes('categor') || location.pathname.includes('news'));
  }, [location.pathname]);

  const navigateAndRefresh = (link) => {
    if (!link) return;
    navigate(link);
  };

  const navigateTo = (path) => {
    navigateAndRefresh(`/${path}`);
  };

  const getNav = (userRole) => {
    return navItemsByRole[userRole] || [];
  };

  const { userRole } = props;

  const nav = getNav(userRole);

  const isActive = (navItem) => {
    return location.pathname === navItem.to || (!location.pathname.includes('cube-group') && location.pathname.includes(navItem.type)) || (location.pathname.includes('cube-group') && navItem.type === 'cube-group');
  };

  const itemInSubMenuActive = (subMenuItem) => {
    return subMenuItem.content.filter((item) => location.pathname.includes(item.to)).length > 0;
  };

  if (!nav?.length) return null;
  return (
    <>
      {userRole === 'admin' || userRole === 'tenant-admin' || userRole === 'reviewer' || userRole === 'editor' || userRole === 'approver' || userRole === 'cube-manager-viewer' ? (
        <Sidebar
          breakPoint="xl"
          toggled={props.toggled}
          onBackdropClick={() => props.setToggled(false)}
          style={{ border: 'none', width: 'unset' }}
          rootStyles={{
            [`.${sidebarClasses.container}`]: {
              backgroundColor: '#43425d',
            },
          }}
        >
          <Menu
            menuItemStyles={{
              button: ({ level, active, disabled }) => {
                return {
                  height: '41px',
                  '&:hover': {
                    backgroundColor: 'rgba(255,255,255,0.2)',
                  },
                };
              },
              label: ({ level, active, disabled }) => {
                return {
                  textAlign: 'left',
                  fontSize: '14px',
                  color: '#fff',
                  lineHeight: '24px',
                  borderBottom: active ? 'solid orange' : 'none',
                };
              },
              icon: ({ level, active, disabled }) => {
                return {
                  color: '#fff',
                  opacity: 0.3,
                  fontSize: '24px',
                };
              },
              subMenuContent: ({ level, active, disabled }) => {
                return {
                  color: '#fff',
                  backgroundColor: 'transparent',
                };
              },
              SubMenuExpandIcon: ({ level, active, disabled }) => {
                return {
                  opacity: 0.3,
                  color: '#fff',
                };
              },
            }}
          >
            {nav &&
              nav.map((navItem, index) =>
                !navItem.disableForRoles?.includes(userRole) && navItem.content ? (
                  <SubMenu
                    key={subMenuOpen ? navItem.label + '_open' : navItem.label}
                    label={navItem.label}
                    icon={<i className={navItem.icon}></i>}
                    onOpenChange={(open) => {
                      setSubMenuOpen(open);
                    }}
                    open={subMenuOpen}
                  >
                    {navItem.content.map(
                      (subMenuItem, index) =>
                        !subMenuItem.disableForRoles?.includes(userRole) && (
                          <MenuItem key={index} component={<Link to={subMenuItem.to} />} icon={<i className={subMenuItem.icon}></i>} active={isActive(subMenuItem)}>
                            {subMenuItem.label}
                          </MenuItem>
                        )
                    )}
                  </SubMenu>
                ) : (
                  <MenuItem key={index} component={<Link to={navItem.to} />} icon={<i className={navItem.icon}></i>} active={isActive(navItem)}>
                    {navItem.label}
                  </MenuItem>
                )
              )}
          </Menu>
        </Sidebar>
      ) : (
        <>
          <NavItem currentPath={location.pathname} path="dashboard" handlePress={navigateTo} title="VITAL Monitor" icon="nav_1" />
          <NavItem currentPath={location.pathname} path="simulation" handlePress={navigateTo} title="Neue SIM starten" icon="nav_2" />
          <NavItem currentPath={location.pathname} path="challenge" handlePress={navigateTo} title="Challenges" icon="nav_3" />
          <NavItem currentPath={location.pathname} link="https://shop.iqest.com" title="Shop" icon="nav_4" />
        </>
      )}
    </>
  );
};

const mapStateToProps = ({ UserRoles }) => ({
  userRole: UserRoles.userRole,
});

export default connect(mapStateToProps, null)(VerticalNavWrapper);
