import { takeLatest, takeEvery } from 'redux-saga/effects';
import { apiRequest } from './index';
import api from 'utils/api';
import * as codeActions from 'actions/Codes';

const getAllCodes = apiRequest.bind(null, codeActions.getAllCodes, api.getCodes);
const generateCodes = apiRequest.bind(null, codeActions.generateCodes, api.generateCodes);
const deleteCode = apiRequest.bind(null, codeActions.deleteCode, api.deleteCode);
const getCode = apiRequest.bind(null, codeActions.getCode, api.getCode);
const editCode = apiRequest.bind(null, codeActions.editCode, api.updateCode);

/******************************************************************************/
/******************************* WATCHERS *************************************/
/******************************************************************************/

export function* watchGetAllCodesRequest() {
  yield takeLatest(codeActions.GET_ALL_CODES.REQUEST, getAllCodes);
}

export function* watchGenerateCodesRequest() {
  yield takeEvery(codeActions.GENERATE_CODES.REQUEST, generateCodes);
}

export function* watchDeleteCodeRequest() {
  yield takeEvery(codeActions.DELETE_CODE.REQUEST, deleteCode);
}

export function* watchGetCodeRequest() {
  yield takeLatest(codeActions.GET_CODE.REQUEST, getCode);
}

export function* watchEditCodeRequest() {
  yield takeLatest(codeActions.EDIT_CODE.REQUEST, editCode);
}