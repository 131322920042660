import { createRequestTypes, action } from '../utils/actions';
import { REQUEST, SUCCESS, FAILURE } from '../utils/constants';

export const GET_SURVEYS = createRequestTypes('GET_SURVEYS');
export const GET_SURVEY = createRequestTypes('GET_SURVEY');
export const CREATE_SURVEY = createRequestTypes('CREATE_SURVEY');
export const UPDATE_SURVEY = createRequestTypes('UPDATE_SURVEY');
export const DELETE_SURVEY = createRequestTypes('DELETE_SURVEY');
export const SEND_DIRECT_SURVEY = createRequestTypes('SEND_DIRECT_SURVEY');
export const SELECT_SURVEYS = 'SELECT_SURVEYS';
export const SET_SURVEYS_TABLE_FILTER = 'SET_SURVEYS_TABLE_FILTER';

export const RESET_SURVEY = 'RESET_SURVEY';

export const getSurveys = {
  request: (meta) => action(GET_SURVEYS[REQUEST], { meta }),
  success: (payload) => action(GET_SURVEYS[SUCCESS], { payload }),
  failure: (payload) => action(GET_SURVEYS[FAILURE], { payload }),
};

export const getSurvey = {
  request: (id, meta) => action(GET_SURVEY[REQUEST], { id, meta }),
  success: (payload) => action(GET_SURVEY[SUCCESS], { payload }),
  failure: (payload) => action(GET_SURVEY[FAILURE], { payload }),
};

export const createSurvey = {
  request: (data) => action(CREATE_SURVEY[REQUEST], { data }),
  success: (payload) => action(CREATE_SURVEY[SUCCESS], { payload }),
  failure: (payload) => action(CREATE_SURVEY[FAILURE], { payload }),
};

export const updateSurvey = {
  request: (data) => action(UPDATE_SURVEY[REQUEST], { data }),
  success: (payload) => action(UPDATE_SURVEY[SUCCESS], { payload }),
  failure: (payload) => action(UPDATE_SURVEY[FAILURE], { payload }),
};

export const deleteSurvey = {
  request: (id) => action(DELETE_SURVEY[REQUEST], { id }),
  success: (payload) => action(DELETE_SURVEY[SUCCESS], { payload }),
  failure: (payload) => action(DELETE_SURVEY[FAILURE], { payload }),
};

export const sendDirectSurvey = {
  request: (data) => action(SEND_DIRECT_SURVEY[REQUEST], { data }),
  success: (payload) => action(SEND_DIRECT_SURVEY[SUCCESS], { payload }),
  failure: (payload) => action(SEND_DIRECT_SURVEY[FAILURE], { payload }),
};

export const selectSurveys = (payload) => ({
  type: 'SELECT_SURVEYS',
  payload,
});

export const setSurveysTableFilter = (payload) => ({
  type: SET_SURVEYS_TABLE_FILTER,
  payload,
});

export const resetSurvey = () => ({
  type: RESET_SURVEY,
});
