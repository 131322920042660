export default function reducer(state = {
    darkTheme: false
}, action) {
    switch (action.type) {
        case 'SET_THEME':
            //window.localStorage && window.localStorage.setItem('BC_DARK_THEME', `${action.payload}`);
            return {
              ...state,
              darkTheme: false
            }
        default:
          return state;
    }

}
