import { takeEvery, all, put, takeLatest } from 'redux-saga/effects';
import { apiRequest } from './index';
import CubeApi from '../api/Cube';
import * as cubeActions from 'actions/Cube';
import { toast } from 'react-toastify';
import history from 'history/browser';

const getCubes = apiRequest.bind(null, cubeActions.getCubes, CubeApi.getCubes);
const getCube = apiRequest.bind(null, cubeActions.getCube, CubeApi.getCube);
const getUserCubes = apiRequest.bind(null, cubeActions.getUserCubes, CubeApi.getUserCubes);
const startUserCube = apiRequest.bind(null, cubeActions.startUserCube, CubeApi.startUserCube);

function* createCube({ data, options }) {
  const { response, error } = yield CubeApi.createCube(data);
  if (error) {
    yield put({
      type: cubeActions.CREATE_CUBE.FAILURE,
      payload: error,
    });
    return;
  }
  yield put({
    type: cubeActions.CREATE_CUBE.SUCCESS,
    payload: response,
  });

  if (options === 'redirect') {
    yield history.push('/cubes');
  } else {
    yield history.replace(`/edit-cube/${response.id}`);
  }
}

function* deleteCube({ id }) {
  const { error } = yield CubeApi.deleteCube(id);
  if (error) {
    yield put({ type: cubeActions.DELETE_CUBE.FAILURE, payload: error });
  } else {
    yield put({ type: cubeActions.DELETE_CUBE.SUCCESS, payload: id });
  }
}

function* startCube({ data }) {
  const { response, error } = yield CubeApi.startCube(data);
  if (error) {
    yield put({
      type: cubeActions.START_CUBE.FAILURE,
      payload: error,
    });
    return;
  }
  yield put({
    type: cubeActions.START_CUBE.SUCCESS,
    id: response.userUuid,
    payload: response,
  });
}

function* stopCube({ id }) {
  const { error } = yield CubeApi.stopCube(id);
  if (error) {
    yield put({
      type: cubeActions.STOP_CUBE.FAILURE,
      payload: error,
    });
    return;
  }
  yield put({
    type: cubeActions.STOP_CUBE.SUCCESS,
    payload: id,
  });
}

function* updateCube({ id, data, meta }) {
  const { response, error } = yield CubeApi.updateCube(data, id);
  if (error) {
    yield put({
      type: cubeActions.UPDATE_CUBE.FAILURE,
      payload: error,
    });
    return;
  }
  yield put({
    type: cubeActions.UPDATE_CUBE.SUCCESS,
    payload: response,
  });

  if (meta === 'redirect') {
    yield history.push('/cubes');
  }
}

const successSaveHandler = () => {
  toast.success('Gespeichert');
};

/******************************************************************************/
/******************************* WATCHERS *************************************/

/******************************************************************************/
export function* rootWatcherCube() {
  yield all([
    takeLatest(cubeActions.GET_CUBES.REQUEST, getCubes),
    takeLatest(cubeActions.GET_CUBE.REQUEST, getCube),
    takeEvery(cubeActions.UPDATE_CUBE.REQUEST, updateCube),
    takeEvery(cubeActions.CREATE_CUBE.REQUEST, createCube),
    takeEvery(cubeActions.DELETE_CUBE.REQUEST, deleteCube),
    takeLatest(cubeActions.GET_USER_CUBES.REQUEST, getUserCubes),
    takeEvery(cubeActions.START_CUBE.REQUEST, startCube),
    takeEvery(cubeActions.UPDATE_CUBE.SUCCESS, successSaveHandler),
    takeEvery(cubeActions.CREATE_CUBE.SUCCESS, successSaveHandler),
    takeEvery(cubeActions.START_CUBE.SUCCESS, successSaveHandler),
    takeLatest(cubeActions.START_CUBE.SUCCESS, getUserCubes),
    takeEvery(cubeActions.STOP_CUBE.REQUEST, stopCube),
    takeEvery(cubeActions.STOP_CUBE.SUCCESS, successSaveHandler),
    takeEvery(cubeActions.START_USER_CUBE.REQUEST, startUserCube),
  ]);
}
