import * as ActionTypes from 'actions/HealthAssistant';

const initialState = {
  assistants: [],
  assistant: null,
  userAssistants: [],
  tableFilter: { status: [] },
  tableSorting: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.RESET_HEALTH_ASSISTANT:
      return { ...initialState };
    case ActionTypes.GET_HEALTH_ASSISTANTS.SUCCESS:
      return {
        ...state,
        assistants: action.payload,
      };
    case ActionTypes.GET_HEALTH_ASSISTANT.SUCCESS:
      return {
        ...state,
        assistant: action.payload,
      };
    case ActionTypes.GET_ALL_USER_HEALTH_ASSISTANTS.SUCCESS:
      return {
        ...state,
        userAssistants: action.payload,
      };
    case ActionTypes.SET_USER_HEALTH_ASSISTANTS_TABLE_FILTER:
      return {
        ...state,
        tableFilter: action.payload,
      };
    case ActionTypes.SET_USER_HEALTH_ASSISTANTS_TABLE_SORTING:
      return {
        ...state,
        tableSorting: action.payload,
      };
    default:
      return state;
  }
}
