import * as ActionTypes from 'actions/HealthProfile';

const initialState = {
  profiles: [],
  profile: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.RESET_HEALTH_PROFILE:
      return { ...initialState };
    case ActionTypes.GET_HEALTH_PROFILES.SUCCESS:
      return {
        ...state,
        profiles: action.payload,
      };
    case ActionTypes.GET_HEALTH_PROFILE.SUCCESS:
      return {
        ...state,
        profile: action.payload,
      };
    default:
      return state;
  }
}
