import * as ActionTypes from 'actions/Charts';
import { get } from 'lodash';

const initialState = {
  charts: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.RESET_CHART:
      return { ...initialState };
    case ActionTypes.GET_CHARTS.SUCCESS:
      return {
        ...state,
        charts: action.payload,
      };
    case ActionTypes.GET_CHART.SUCCESS:
      return {
        ...state,
        chart: action.payload,
      };
    case ActionTypes.GET_CHART_BY_ATTRIBUTE_ID.SUCCESS:
      return {
        ...state,
        chartByAttributeId: action.payload,
      };
    case ActionTypes.UPDATE_CHART.SUCCESS:
      return {
        ...state,
        chart: action.payload,
      };
    case ActionTypes.DELETE_CHART.SUCCESS:
      return {
        ...state,
        charts: state.charts.filter((el) => el.id !== action.payload),
      };
    default:
      return state;
  }
}
