import { createRequestTypes, action } from '../utils/actions';
import { REQUEST, SUCCESS, FAILURE } from '../utils/constants';

export const GET_CATEGORIES = createRequestTypes('GET_CATEGORIES');
export const CREATE_CATEGORY = createRequestTypes('CREATE_CATEGORY');
export const DELETE_CATEGORY = createRequestTypes('DELETE_CATEGORY');
export const UPDATE_CATEGORY = createRequestTypes('UPDATE_CATEGORY');
export const GET_CATEGORY = createRequestTypes('GET_CATEGORY');
export const SET_CATEGORIES_TABLE_FILTER = 'SET_CATEGORIES_TABLE_FILTER';

export const RESET_CATEGORY = 'RESET_CATEGORY';

export const getCategories = {
  request: () => action(GET_CATEGORIES[REQUEST]),
  success: (payload) => action(GET_CATEGORIES[SUCCESS], { payload }),
  failure: (payload) => action(GET_CATEGORIES[FAILURE], { payload }),
};

export const getCategory = {
  request: (id) => action(GET_CATEGORY[REQUEST], { id }),
  success: (payload) => action(GET_CATEGORY[SUCCESS], { payload }),
  failure: (payload) => action(GET_CATEGORY[FAILURE], { payload }),
};

export const updateCategory = {
  request: (id, data) => action(UPDATE_CATEGORY[REQUEST], { id, data }),
  success: (payload) => action(UPDATE_CATEGORY[SUCCESS], { payload }),
  failure: (payload) => action(UPDATE_CATEGORY[FAILURE], { payload }),
};

export const createCategory = {
  request: (data) => action(CREATE_CATEGORY[REQUEST], { data }),
  success: (payload) => action(CREATE_CATEGORY[SUCCESS], { payload }),
  failure: (payload) => action(CREATE_CATEGORY[FAILURE], { payload }),
};

export const deleteCategory = {
  request: (id) => action(DELETE_CATEGORY[REQUEST], { id }),
  success: (payload) => action(DELETE_CATEGORY[SUCCESS], { payload }),
  failure: (payload) => action(DELETE_CATEGORY[FAILURE], { payload }),
};

export const setCategoriesTableFilter = (payload) => ({
  type: SET_CATEGORIES_TABLE_FILTER,
  payload,
});

export const resetCategory = () => ({
  type: RESET_CATEGORY,
});
