import Auth from './Auth';
import User from './User';
import Theme from './Theme';
import Loading from './Loading';
import Attributes from './Attributes';
import Charts from './Charts';
import Appointments from './Appointments';
import UserRoles from './UserRoles';
import Notifications from './Notifications';
import Documents from './Documents';
import Survey from './Survey';
import News from './News';
import Product from './Product';
import Groups from './Groups';
import Calendar from './Calendar';
import CalendarICal from './CalendarICal';
import Categories from './Categories';
import Campaigns from './Campaigns';
import Tenants from './Tenants';
import Tags from './Tags';
import Cube from './Cube';
import HealthProfile from './HealthProfile';
import HealthAssistant from './HealthAssistant';
import Authors from './Authors';
import Filters from './Filters';
import CubeGroups from './CubeGroups';
import Activity from './Activity';
import Codes from './Codes';

export default {
  Auth,
  User,
  Theme,
  Loading,
  UserRoles,
  Documents,
  Campaigns,
  Notifications,
  Calendar,
  CalendarICal,
  Survey,
  News,
  Product,
  Groups,
  Categories,
  Tenants,
  Attributes,
  Charts,
  Tags,
  Appointments,
  Cube,
  HealthProfile,
  HealthAssistant,
  Authors,
  Filters,
  CubeGroups,
  Activity,
  Codes,
};
