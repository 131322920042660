import { Navigate, Outlet, useLocation } from 'react-router-dom';
import React, { Suspense, useEffect, useState } from 'react';
import Loader from 'react-loaders';
import { connect } from 'react-redux';

import Notification from 'components/Notification';

import { checkAuth } from 'actions/Auth';
import { getDocuments } from 'actions/Documents';
import { getProductConstants } from 'actions/Product';

import AppHeader from '../AppHeader/';
import AppSidebar from '../AppSidebar/';
import AppFooter from '../AppFooter/';

import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

import { ToastContainer } from 'react-toastify';

import Loading from 'components/Loading';
import { setLoading } from '../../actions/Loading';
import { Col, Container, Row, Stack } from 'react-bootstrap';

const unauthorizedPaths = {
  '/public-wiki': true,
  '/public-challenges': true,
  '/public-challenge-detail': true,
  '/public-challenge-full-content': true,
  '/simulation-info': true,
  '/simulation-info-detail': true,
  '/simulation-info-full-content': true,
  '/vital-monitor-info': true,
  '/vital-monitor-info-detail': true,
  '/vital-monitor-info-full-content': true,
  '/public-page': true,
};

const checkUnauthorizedPath = (path, pathsToCheck) => {
  return Object.keys(pathsToCheck).reduce((acc, el) => {
    if (path.includes(el)) return true;
    return acc;
  }, false);
};

const AppMain = (props) => {
  const location = useLocation();
  const [menuOpen, toogleMenuVisibility] = useState(false);

  const toggleMenu = (state) => {
    const body = document.querySelector('body');
    if (state === true) {
      disableBodyScroll(body);
    } else {
      enableBodyScroll(body);
    }
    toogleMenuVisibility(state);
  };

  window.scrollTo(0, 0);
  useEffect(() => {
    const matchesUnauthPath = checkUnauthorizedPath(location.pathname, unauthorizedPaths);
    if (matchesUnauthPath) {
      window.noLoginRedirect = true;
    }
    props.checkAuth();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (props.userRole) {
      props.getProductConstants();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.userRole]);

  return (
    <>
      {props.userRole && (location.pathname === '/' || location.pathname === '/login') && <Navigate to="/workflow"></Navigate>}
      <Container fluid style={{ backgroundColor: '#43425d' }}>
        <Stack className="min-vh-100">
          {props.userRole && (
            <Row>
              <Col xl="auto" className="p-0 m-0">
                <AppSidebar darkTheme={props.darkTheme} toggled={menuOpen} setToggled={toggleMenu} />
              </Col>
              <Col style={{ backgroundColor: '#fff' }}>
                <AppHeader noTabs menuOpen={menuOpen} toggleMenu={toggleMenu} />
                <Suspense
                  fallback={
                    <div className="loader-container">
                      <div className="loader-container-inner">
                        <div className="text-center">
                          <Loader type="ball-grid-beat" />
                        </div>
                        <h6 className="mt-3">Loading...</h6>
                      </div>
                    </div>
                  }
                >
                  {props.userRole !== 'user' && <Outlet></Outlet>}
                  {props.userRole && props.userRole !== 'user' && props.userRole !== 'cube-manager-viewer' && location.pathname === '/' && <Navigate to="/workflow" />}
                  {props.userRole && props.userRole === 'cube-manager-viewer' && location.pathname === '/' && <Navigate to="/cubes" />}
                </Suspense>
                <ToastContainer position="bottom-center" autoClose={2000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="dark" />
                <Notification />
                {<Loading loading={props.loading} />}
              </Col>
            </Row>
          )}
          <div className="mt-auto">
            <AppFooter />
          </div>
        </Stack>
      </Container>
    </>
  );
};

const mapStateToProps = ({ UserRoles, Loading, Theme }) => ({
  userRole: UserRoles.userRole,
  loading: Loading.loading,
  darkTheme: Theme.darkTheme,
});

const mapDispatchToProps = (dispatch) => ({
  checkAuth: () => dispatch(checkAuth.request()),
  getDocuments: () => dispatch(getDocuments.request()),
  setDarkTheme: (payload) => dispatch({ type: 'SET_THEME', payload }),
  setLoading: (data) => dispatch(setLoading(data)),
  getProductConstants: () => dispatch(getProductConstants.request()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppMain);
