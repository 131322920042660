import { select, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { apiRequest } from './index';
import CalendarICalApi from '../api/CalendarICal';
import * as calendarICalActions from 'actions/CalendarICal';
import { get, set } from 'lodash';
import moment from 'moment-timezone';

const getCalendarICalEntries = apiRequest.bind(null, calendarICalActions.getCalendarICalEntries, CalendarICalApi.getCalendarICalEntries);

function* generateCalendarICalEvents(action) {
  const { payload } = action;
  let calendarEntries = [];

  payload.forEach((el) => {
    if (!get(el, 'location')) set(el, 'location', '');

    //generate start date
    let startDate = el.start?.dateTime ? moment(moment.tz(el.start.dateTime, 'YYYYMMDDTHHmmss', el.start.timeZone).toISOString()).toLocaleString() : moment(el.start.date, 'YYYYMMDD').toLocaleString();
    //generate end date
    let endDate = el.end?.dateTime ? moment(moment.tz(el.end.dateTime, 'YYYYMMDDTHHmmss', el.end.timeZone).toISOString()).toLocaleString() : moment(el.end.date, 'YYYYMMDD').toLocaleString();

    //set new calendar entry
    calendarEntries.push({
      id: get(el, 'uid'),
      label: get(el, 'summary'),
      current: { ...el, generatedStartDate: startDate, generatedEndDate: endDate },
      calendarType: 'event',
      isUserCreator: false,
    });
  });

  yield put({
    type: calendarICalActions.SET_ICAL_ENTRIES,
    payload: [...calendarEntries],
  });
}

export function* watchGetCalendarICalEntriesRequest() {
  yield takeLatest(calendarICalActions.GET_CALENDAR_ICAL_ENTRIES.REQUEST, getCalendarICalEntries);
}

export function* watchGetCalendarICalEntriesSuccess() {
  yield takeLatest(calendarICalActions.GET_CALENDAR_ICAL_ENTRIES.SUCCESS, generateCalendarICalEvents);
}
