import * as ActionTypes from 'actions/Codes';

const initialState = {
  codes: [],
  code: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.RESET_CODE_PROPS:
      return { ...initialState };
    case ActionTypes.GET_ALL_CODES.SUCCESS:
      return {
        ...state,
        codes: action.payload,
      };
    case ActionTypes.GENERATE_CODES.SUCCESS:
      navigator.clipboard.writeText(action.payload.map((element) => element.secret).join('\n'));
      return {
        ...state,
        codes: [...(state.codes), ...(action.payload)],
      };
    case ActionTypes.DELETE_CODE.SUCCESS:
      return {
        ...state,
        codes: state.codes.filter(code => action.payload.id !== code.id)
      };
    case ActionTypes.GET_CODE.SUCCESS:
      return {
        ...state,
        code: action.payload
      }
    default:
      return state;
  }
}
