import axios from 'axios';
import { get, omit } from 'lodash';

import { dmsUrl } from 'config/environment';

const getFilters = (data) => {
  const params = {
    size: data.size || 100,
    page: data.page || 0,
  };
  if (data.textContains) params.textContains = get(data, 'textContains', '');
  if (data.sortBy) {
    params[`sort.${data.sortBy}.direction`] = get(data, 'sortDirection', '');
    params[`sort.${data.sortBy}.order`] = 0;
  }
  return axios
    .get(`${dmsUrl}/api/labels/v1.0/`, { params: params })
    .then((response) => ({ response: response.data }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const getAllFilters = () => {
  return axios
    .get(`${dmsUrl}/api/labels/all/v1.0/`)
    .then((response) => ({ response: response.data }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const getFilter = (id) => {
  return axios
    .get(`${dmsUrl}/api/labels/${id}/v1.0/`)
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: get(err, 'response.data.message', 'Die Anfrage ist fehlgeschlagen') || 'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const createFilter = (data) => {
  return axios
    .post(`${dmsUrl}/api/labels/v1.0/`, omit(data, 'redirect'))
    .then((response) => {
      if (data.redirect) data.redirect(response.data.id);
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: get(err, 'response.data.message', 'Die Anfrage ist fehlgeschlagen') || 'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const updateFilter = (data, id) => {
  return axios
    .put(`${dmsUrl}/api/labels/${id}/v1.0/`, omit(data, 'redirect'))
    .then((response) => {
      if (data.redirect) data.redirect();
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: get(err, 'response.data.message', 'Die Anfrage ist fehlgeschlagen') || 'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const deleteFilter = (id) => {
  return axios
    .delete(`${dmsUrl}/api/labels/${id}/v1.0/`)
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: get(err, 'response.data.message', 'Die Anfrage ist fehlgeschlagen') || 'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

export default {
  getFilters,
  getFilter,
  createFilter,
  updateFilter,
  deleteFilter,
  getAllFilters,
};
