import * as healthAssistantActions from 'actions/HealthAssistant';
import { all, takeLatest } from 'redux-saga/effects';
import { apiRequest } from './index';
import HealthAssistantsApi from '../api/HealthAssistant';

const getHealthAssistants = apiRequest.bind(null, healthAssistantActions.getHealthAssistants, HealthAssistantsApi.getHealthAssistants);
const getHealthAssistant = apiRequest.bind(null, healthAssistantActions.getHealthAssistant, HealthAssistantsApi.getHealthAssistant);
const getAllUserHealthAssistantsForUserUuid = apiRequest.bind(null, healthAssistantActions.getAllUserHealthAssistantsForUserUuid, HealthAssistantsApi.getAllUserHealthAssistantsForUserUuid);

export function* rootWatcherHealthAssistant() {
  yield all([takeLatest(healthAssistantActions.GET_HEALTH_ASSISTANTS.REQUEST, getHealthAssistants), takeLatest(healthAssistantActions.GET_HEALTH_ASSISTANT.REQUEST, getHealthAssistant), takeLatest(healthAssistantActions.GET_ALL_USER_HEALTH_ASSISTANTS.REQUEST, getAllUserHealthAssistantsForUserUuid)]);
}
