import { put, takeEvery, select, takeLatest } from 'redux-saga/effects';
import { apiRequest } from './index';
import api from 'utils/api';
import * as userActions from 'actions/User';
import { get } from 'lodash';
import { toast } from 'react-toastify';

const getUsers = apiRequest.bind(null, userActions.getUsers, api.getUsers);
const getUser = apiRequest.bind(null, userActions.getUser, api.getUser);
const createUser = apiRequest.bind(null, userActions.createUser, api.createUser);
const getUserWithGroups = apiRequest.bind(null, userActions.getUserWithGroups, api.getUserWithGroups);
const updateUserPassword = apiRequest.bind(null, userActions.updateUserPassword, api.updateUserPassword);
const getUserProducts = apiRequest.bind(null, userActions.getUserProducts, api.getUserProducts);
const updateUser = apiRequest.bind(null, userActions.updateUser, api.updateUser);
const getUserResults = apiRequest.bind(null, userActions.getUserResults, api.getUsers);

function* deleteUser({ id }) {
  const { response, error } = yield api.deleteUser(id);
  if (error) {
    return yield put({
      type: userActions.DELETE_USER.FAILURE,
      payload: response,
    });
  }
  yield put({
    type: userActions.DELETE_USER.SUCCESS,
    payload: id,
  });
}

function* deleteUsers({ id }) {
  const { response, error } = yield api.deleteUsers(id);
  if (error) {
    return yield put({
      type: userActions.DELETE_USERS.FAILURE,
      payload: response,
    });
  }
  yield put({
    type: userActions.DELETE_USERS.SUCCESS,
    payload: id,
  });
  yield put({ type: userActions.SELECT_USERS, payload: {} });
}

function* refreshUsers() {
  const state = yield select();
  const arg = {
    ...get(arguments, '0', {}),
    data: {
      page: get(state, 'User.usersPage', 0),
    },
  };
  yield apiRequest.apply(null, [userActions.getUsers, api.getUsers, arg]);
}

function* handleUpdateSuccess() {
  yield toast.success('Erfolgreich geändert!');
}

function* handleDeleteMutlipleUsers() {
  yield refreshUsers();
  yield put({ type: userActions.SELECT_USERS, payload: {} });
}

/******************************************************************************/
/******************************* WATCHERS *************************************/
/******************************************************************************/

export function* watchGetUsersRequest() {
  yield takeLatest(userActions.GET_USERS.REQUEST, getUsers);
}

export function* watchGetUserRequest() {
  yield takeLatest(userActions.GET_USER.REQUEST, getUser);
}

export function* watchGetUserWithGroupsRequest() {
  yield takeLatest(userActions.GET_USER_WITH_GROUPS.REQUEST, getUserWithGroups);
}

export function* watchUpdateUserPasswordRequest() {
  yield takeEvery(userActions.UPDATE_USER_PASSWORD.REQUEST, updateUserPassword);
}

export function* watchUpdateUserPasswordSuccess() {
  yield takeEvery(userActions.UPDATE_USER_PASSWORD.SUCCESS, handleUpdateSuccess);
}

export function* watchUpdateUserRequest() {
  yield takeEvery(userActions.UPDATE_USER.REQUEST, updateUser);
}

export function* watchCreateUserRequest() {
  yield takeEvery(userActions.CREATE_USER.REQUEST, createUser);
}

export function* watchDeleteUserRequest() {
  yield takeEvery(userActions.DELETE_USER.REQUEST, deleteUser);
}

export function* watchDeleteUsersRequest() {
  yield takeEvery(userActions.DELETE_USERS.REQUEST, deleteUsers);
}

export function* watchGetUserResultsRequest() {
  yield takeLatest(userActions.GET_USER_RESULTS.REQUEST, getUserResults);
}

export function* watchGetUserProductsRequest() {
  yield takeLatest(userActions.GET_USER_PRODUCTS.REQUEST, getUserProducts);
}
