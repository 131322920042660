import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import React, { Suspense, lazy, useEffect } from 'react';
import Loader from 'react-loaders';
import { connect } from 'react-redux';

import Notification from 'components/Notification';

import { checkAuth } from 'actions/Auth';
import { getDocuments } from 'actions/Documents';
import { getProductConstants } from 'actions/Product';

import { ToastContainer } from 'react-toastify';

import { setLoading } from '../../actions/Loading';
import MainLayout from './MainLayout';

const UserListPage = lazy(() => import('pages/UserList/UserList'));
const NewsList = lazy(() => import('pages/NewsList/NewsList'));
const TagsList = lazy(() => import('pages/TagsList/TagsList'));
const TagDetails = lazy(() => import('pages/TagDetails'));
const CampaignsList = lazy(() => import('pages/CampaignsList/CampaignsList'));
const CampaignDetails = lazy(() => import('pages/CampaignDetails'));
const CampaignSimulate = lazy(() => import('pages/CampaignSimulate/SimulateCampaign'));
const WorkflowList = lazy(() => import('pages/WorkflowList/WorkflowList'));
const NewsDetailsPage = lazy(() => import('pages/NewsDetails'));
const SurveysList = lazy(() => import('pages/SurveysList/SurveysList'));
const SurveyDetails = lazy(() => import('pages/SurveyDetails'));
const AttributesList = lazy(() => import('pages/AttributesList/AttributesList'));
const AttributeDetails = lazy(() => import('pages/AttributeDetails'));
const LoginPage = lazy(() => import('pages/Login'));
const ForgotPasswordPage = lazy(() => import('pages/ForgotPassword'));
const ResetPasswordPage = lazy(() => import('pages/ResetPassword'));
const CreatePasswordPage = lazy(() => import('pages/CreatePassword'));
const UserDetailsPage = lazy(() => import('pages/UserDetails/UserDetails'));
const CategoryDetailsPage = lazy(() => import('pages/CategoryDetails'));
const TenantsList = lazy(() => import('pages/TenantsList/TenantsList'));
const TenantDetails = lazy(() => import('pages/TenantDetails'));

const HealthProfileList = lazy(() => import('pages/HealthProfileList/HealthProfileList'));
const HealthProfileDetails = lazy(() => import('pages/HealthProfileDetails'));

const HealthAssistantList = lazy(() => import('pages/HealthAssistantList/HealthAssistantList'));
const HealthAssistantDetails = lazy(() => import('pages/HealthAssistantDetails'));

const ProductDetailsPage = lazy(() => import('pages/ProductDetails'));
const ProductListPage = lazy(() => import('pages/ProductList/ProductList'));

const ChartsList = lazy(() => import('pages/ChartsList/ChartsList'));
const ChartDetails = lazy(() => import('pages/ChartDetails'));

const AppointmentsList = lazy(() => import('pages/AppointmentsList/AppointmentList'));
const AppointmentDetails = lazy(() => import('pages/AppointmentDetails'));

const CubesList = lazy(() => import('pages/CubesList/CubesList'));
const CubeDetails = lazy(() => import('pages/CubeDetails/CubeDetails'));

const CategoriesListPage = lazy(() => import('pages/CategoriesList/CategoriesList'));

const AuthorsListPage = lazy(() => import('pages/AuthorsList/AuthorsList'));
const AuthorDetails = lazy(() => import('pages/AuthorDetails'));

const FiltersListPage = lazy(() => import('pages/FiltersList/FiltersList'));
const FilterDetails = lazy(() => import('pages/FilterDetails'));

const CubeGroupsListPage = lazy(() => import('pages/CubeGroupsList/CubeGroupsList'));
const CubeGroupDetails = lazy(() => import('pages/CubeGroupDetails'));

const Activity = lazy(() => import('pages/Activity/Activity'));
const UserCubeStats = lazy(() => import('pages/Activity/UserCubeStats'));

const Codes = lazy(() => import('pages/Codes/Codes'));
const CodeDetails = lazy(() => import('pages/Codes/CodeDetails'));

const unauthorizedPaths = {
  '/public-wiki': true,
  '/public-challenges': true,
  '/public-challenge-detail': true,
  '/public-challenge-full-content': true,
  '/simulation-info': true,
  '/simulation-info-detail': true,
  '/simulation-info-full-content': true,
  '/vital-monitor-info': true,
  '/vital-monitor-info-detail': true,
  '/vital-monitor-info-full-content': true,
  '/public-page': true,
};

const checkUnauthorizedPath = (path, pathsToCheck) => {
  return Object.keys(pathsToCheck).reduce((acc, el) => {
    if (path.includes(el)) return true;
    return acc;
  }, false);
};

const AppMain = (props) => {
  const location = useLocation();

  window.scrollTo(0, 0);
  useEffect(() => {
    const matchesUnauthPath = checkUnauthorizedPath(location.pathname, unauthorizedPaths);
    if (matchesUnauthPath) {
      window.noLoginRedirect = true;
    }
    props.checkAuth();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (props.userRole) {
      props.getProductConstants();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.userRole]);

  useEffect(() => {}, [location.pathname]);

  return (
    <>
      {props.userRole && (location.pathname === '/' || location.pathname === '/login') && <Navigate to="/workflow"></Navigate>}
      {!props.userRole && (
        <Suspense
          fallback={
            <div className="loader-container">
              <div className="loader-container-inner">
                <div className="text-center">
                  <Loader type="ball-grid-beat" />
                </div>
                <h6 className="mt-3">Loading...</h6>
              </div>
            </div>
          }
        >
          <Routes>
            <Route path="*" element={<LoginPage />} />
            <Route exact path="/forgot-password" element={<ForgotPasswordPage />} />
            <Route exact path="/reset-password" element={<ResetPasswordPage />} />
            <Route exact path="/create-password" element={<CreatePasswordPage />} />
          </Routes>
          <ToastContainer position="bottom-center" autoClose={2000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="dark" />
          <Notification />
        </Suspense>
      )}
      {props.userRole && props.userRole !== 'user' && (
        <Routes>
          <Route exact path="/" element={<MainLayout />}>
            <Route path="create-news" element={<NewsDetailsPage create />}></Route>
            <Route path="workflow" element={<WorkflowList />}></Route>
            <Route path="tags" element={<TagsList />}></Route>
            <Route path="edit-tag/:id" element={<TagDetails />}></Route>
            <Route path="view-tag/:id" element={<TagDetails />}></Route>
            <Route path="create-tag" element={<TagDetails create />}></Route>
            <Route path="users" element={<UserListPage />}></Route>
            <Route path="news" element={<NewsList />}></Route>
            <Route path="products" element={<ProductListPage />}></Route>
            <Route path="create-product" element={<ProductDetailsPage create />}></Route>
            <Route path="surveys" element={<SurveysList />}></Route>
            <Route path="create-survey" element={<SurveyDetails create />}></Route>
            <Route path="view-survey/:id" element={<SurveyDetails />}></Route>
            <Route path="edit-survey/:id" element={<SurveyDetails />}></Route>
            <Route path="view-news/:id" element={<NewsDetailsPage />}></Route>
            <Route path="view-news/:id/:versionId" element={<NewsDetailsPage />}></Route>
            <Route path="edit-news/:id/:versionId" element={<NewsDetailsPage />}></Route>
            <Route path="edit-news/:id" element={<NewsDetailsPage />}></Route>
            <Route path="view-product/:id" element={<ProductDetailsPage />}></Route>
            <Route path="edit-product/:id" element={<ProductDetailsPage />}></Route>
            <Route path="attributes" element={<AttributesList />}></Route>
            <Route path="create-attribute" element={<AttributeDetails create />}></Route>
            <Route path="update-attribute/:id" element={<AttributeDetails />}></Route>
            <Route path="view-attribute/:id" element={<AttributeDetails />}></Route>
            <Route path="edit-user/:id" element={<UserDetailsPage />}></Route>
            <Route path="view-user/:id" element={<UserDetailsPage />}></Route>
            <Route path="create-user" element={<UserDetailsPage create />}></Route>
            <Route path="create-category" element={<CategoryDetailsPage create />}></Route>
            <Route path="categories" element={<CategoriesListPage />}></Route>
            <Route path="edit-category/:id" element={<CategoryDetailsPage />}></Route>
            <Route path="view-category/:id" element={<CategoryDetailsPage />}></Route>
            <Route path="tenants" element={<TenantsList />}></Route>
            <Route path="create-tenant" element={<TenantDetails create />}></Route>
            <Route path="edit-tenant/:id" element={<TenantDetails />}></Route>
            <Route path="view-tenant/:id" element={<TenantDetails />}></Route>
            <Route path="campaigns" element={<CampaignsList />}></Route>
            <Route path="edit-campaign/:id" element={<CampaignDetails />}></Route>
            <Route path="view-campaign/:id" element={<CampaignDetails />}></Route>
            <Route path="view-campaign/:id/:version" element={<CampaignDetails />}></Route>
            <Route path="edit-campaign/:id/:version" element={<CampaignDetails />}></Route>
            <Route path="simulate-campaign/:id" element={<CampaignSimulate />}></Route>
            <Route path="view-campaign" element={<CampaignDetails />}></Route>
            <Route path="create-campaign" element={<CampaignDetails create />}></Route>
            <Route path="charts" element={<ChartsList />}></Route>
            <Route path="create-chart" element={<ChartDetails create />}></Route>
            <Route path="edit-chart/:id" element={<ChartDetails />}></Route>
            <Route path="view-chart/:id" element={<ChartDetails />}></Route>
            <Route path="appointments" element={<AppointmentsList />}></Route>
            <Route path="create-appointment" element={<AppointmentDetails create />}></Route>
            <Route path="edit-appointment/:id" element={<AppointmentDetails />}></Route>
            <Route path="view-appointment/:id" element={<AppointmentDetails />}></Route>
            <Route path="cubes" element={<CubesList />}></Route>
            <Route path="create-cube" element={<CubeDetails create />}></Route>
            <Route path="edit-cube/:id" element={<CubeDetails />}></Route>
            <Route path="view-cube/:id" element={<CubeDetails />}></Route>
            <Route path="health-profiles" element={<HealthProfileList />}></Route>
            <Route path="edit-health-profiles/:id" element={<HealthProfileDetails />}></Route>
            <Route path="health-assistants" element={<HealthAssistantList />}></Route>
            <Route path="edit-health-assistants/:id" element={<HealthAssistantDetails />}></Route>
            <Route path="authors" element={<AuthorsListPage />}></Route>
            <Route path="create-author" element={<AuthorDetails create />}></Route>
            <Route path="edit-author/:id" element={<AuthorDetails />}></Route>
            <Route path="filters" element={<FiltersListPage />}></Route>
            <Route path="create-filter" element={<FilterDetails create />}></Route>
            <Route path="edit-filter/:id" element={<FilterDetails />}></Route>
            <Route path="cube-groups" element={<CubeGroupsListPage />}></Route>
            <Route path="create-cube-group" element={<CubeGroupDetails create />}></Route>
            <Route path="edit-cube-group/:id" element={<CubeGroupDetails />}></Route>
            <Route path="activity" element={<Activity />}></Route>
            <Route path="activity/:id" element={<UserCubeStats />}></Route>
            <Route path="codes" element={<Codes />}></Route>
            <Route path="edit-code/:id" element={<CodeDetails />}></Route>
          </Route>
        </Routes>
      )}
      {props.userRole && props.userRole !== 'user' && props.userRole !== 'cube-manager-viewer' && location.pathname === '/' && <Navigate to="/workflow" />}
      {props.userRole && props.userRole === 'cube-manager-viewer' && location.pathname === '/' && <Navigate to="/cubes" />}
    </>
  );
};

const mapStateToProps = ({ UserRoles, Loading, Theme }) => ({
  userRole: UserRoles.userRole,
  loading: Loading.loading,
  darkTheme: Theme.darkTheme,
});

const mapDispatchToProps = (dispatch) => ({
  checkAuth: () => dispatch(checkAuth.request()),
  getDocuments: () => dispatch(getDocuments.request()),
  setDarkTheme: (payload) => dispatch({ type: 'SET_THEME', payload }),
  setLoading: (data) => dispatch(setLoading(data)),
  getProductConstants: () => dispatch(getProductConstants.request()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppMain);
