import React from 'react';
import BlockUi from 'react-block-ui';
import { Loader } from 'react-loaders';

const Component = ({ loading }) => (
  <div className="d-loading" style={{ zIndex: loading ? 10000 : -1 }}>
    <BlockUi tag="div" blocking={loading} className="block-overlay-dark"
      loader={<Loader color="#ffffff" active type={"ball-triangle-path"}/>}>
    </BlockUi>
  </div>
)

export default Component;
