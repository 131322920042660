import axios from 'axios';
import { get, omit } from 'lodash';

import { calendarServiceUrl } from 'config/environment';

const dmsApiVersion = 'v1.0';

const getAppointments = () => {
  return axios
    .get(`${calendarServiceUrl}/api/appointmentTemplate/${dmsApiVersion}/`)
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: get(err, 'response.data.message', 'Die Anfrage ist fehlgeschlagen') || 'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const getAppointment = (id) => {
  return axios
    .get(`${calendarServiceUrl}/api/appointmentTemplate/${id}/${dmsApiVersion}/`)
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: get(err, 'response.data.message', 'Die Anfrage ist fehlgeschlagen') || 'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const createAppointment = (data) => {
  return axios
    .post(`${calendarServiceUrl}/api/appointmentTemplate/${dmsApiVersion}/`, omit(data, 'redirect'))
    .then((response) => {
      if (data.redirect) data.redirect(response.data.id);
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: get(err, 'response.data.message', 'Die Anfrage ist fehlgeschlagen') || 'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const updateAppointment = (id, data, options) => {
  return axios
    .put(`${calendarServiceUrl}/api/appointmentTemplate/${id}/${dmsApiVersion}/`, data)
    .then((response) => {
      if (options) {
        options();
      }
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: get(err, 'response.data.message', 'Die Anfrage ist fehlgeschlagen') || 'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const deleteAppointment = (id) => {
  return axios
    .delete(`${calendarServiceUrl}/api/appointmentTemplate/${id}/${dmsApiVersion}/`)
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: get(err, 'response.data.message', 'Die Anfrage ist fehlgeschlagen') || 'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

export default {
  getAppointments,
  createAppointment,
  getAppointment,
  updateAppointment,
  deleteAppointment,
};
