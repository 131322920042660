export const APPOINTMENT_TYPE_ANCHOR: string = 'anchorAppointment';
export const APPOINTMENT_TYPE_BOOKING: string = 'bookingAppointment';
export const APPOINTMENT_TYPES: {
  readonly value: string;
  readonly label: string;
}[] = [
  { value: APPOINTMENT_TYPE_ANCHOR, label: APPOINTMENT_TYPE_ANCHOR },
  { value: APPOINTMENT_TYPE_BOOKING, label: APPOINTMENT_TYPE_BOOKING },
];
export const APPOINTMENT_TAGS_TYPES: string[] = ['Cancellation', 'MeetingDone', 'Booking'];
