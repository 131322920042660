import { createRequestTypes, action } from '../utils/actions';
import { REQUEST, SUCCESS, FAILURE } from '../utils/constants';

export const LOGIN = createRequestTypes('LOGIN');
export const GET_TOKEN = createRequestTypes('GET_TOKEN');
export const GET_NEW_TOKEN = createRequestTypes('GET_NEW_TOKEN');
export const LOGOUT = createRequestTypes('LOGOUT');
export const REQUEST_PASSWORD_RESET = createRequestTypes('REQUEST_PASSWORD_RESET');
export const RESET_PASSWORD = createRequestTypes('RESET_PASSWORD');
export const GET_FILE_VIA_TOKEN = createRequestTypes('GET_FILE_VIA_TOKEN');

export const login = {
  request: (data) => action(LOGIN[REQUEST], {data}),
  success: (payload) => action(LOGIN[SUCCESS], {payload}),
  failure: (payload) => action(LOGIN[FAILURE], {payload}),
}

export const checkAuth = {
  request: (data) => action(GET_TOKEN[REQUEST], {data}),
  success: (payload) => action(GET_TOKEN[SUCCESS], {payload}),
  failure: (payload) => action(GET_TOKEN[FAILURE], {payload}),
}

export const logout = {
  request: (data) => action(LOGOUT[REQUEST], {data}),
  success: (payload) => action(LOGOUT[SUCCESS], {payload}),
  failure: (payload) => action(LOGOUT[FAILURE], {payload}),
}

export const getNewToken = {
  request: (data) => action(GET_NEW_TOKEN[REQUEST], {data}),
  success: (payload) => action(GET_NEW_TOKEN[SUCCESS], {payload}),
  failure: (payload) => action(GET_NEW_TOKEN[FAILURE], {payload}),
}

export const requestPasswordReset = {
  request: (data) => action(REQUEST_PASSWORD_RESET[REQUEST], {data}),
  success: (payload) => action(REQUEST_PASSWORD_RESET[SUCCESS], {payload}),
  failure: (payload) => action(REQUEST_PASSWORD_RESET[FAILURE], {payload}),
}

export const resetPassword = {
  request: (data) => action(RESET_PASSWORD[REQUEST], {data}),
  success: (payload) => action(RESET_PASSWORD[SUCCESS], {payload}),
  failure: (payload) => action(RESET_PASSWORD[FAILURE], {payload}),
}

export const getFileSecureViaToken = {
  request: (data, fileName) => action(GET_FILE_VIA_TOKEN[REQUEST], {data, fileName}),
  success: (payload) => action(GET_FILE_VIA_TOKEN[SUCCESS], {payload}),
  failure: (payload) => action(GET_FILE_VIA_TOKEN[FAILURE], {payload}),
}
