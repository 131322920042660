const fileTypes = {
  pdf: 'application/pdf',
};

export default function (file, type, filename) {
  const url = window.URL.createObjectURL(
    new Blob([file], { type: fileTypes[type] })
  );
  let a = document.createElement('a');
  a.href = url;
  a.download = filename || `iQest-download.${type}`;
  a.click();
}
