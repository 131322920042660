import { APPOINTMENT_TYPE_ANCHOR, APPOINTMENT_TYPE_BOOKING } from 'components/AppointmentForm/constants';

export const REQUEST = 'REQUEST';
export const SUCCESS = 'SUCCESS';
export const FAILURE = 'FAILURE';

export const defaultLocale = 'de';

export const ALLERGIES_OR_INTOLERANCES = {
  gluten: 'Gluten',
  crustaceans: 'Krebstiere',
  egg: 'Ei',
  fish: 'Fisch',
  peanut: 'Erdnuss',
  soy: 'Soja',
  milk: 'Milch',
  nuts: 'Schalenfrüchte',
  celery: 'Sellerie',
  mustard: 'Senf',
  sesame: 'Sesam',
  sulfites: 'Sulfite',
  lupine: 'Hülsenfrüchte',
  molluscs: 'Weichtiere',
};

export const productFrequencyMap = {
  daily: 'Täglich',
  MON: 'Montag',
  TUE: 'Dienstag',
  WED: 'Mittwoch',
  THU: 'Donnerstag',
  FRI: 'Freitag',
  SAT: 'Samstag',
  SUN: 'Sonntag',
};

export const productFormOptions = [
  {
    value: 'capsule',
    label: 'Kapseln',
  },
  {
    value: 'tablet',
    label: 'Tabletten',
  },
  {
    value: 'drops',
    label: 'Tropfen',
  },
  {
    value: 'powder',
    label: 'Pulver',
  },
];

export const totalAmountUnitValuesMap = {
  capsule: {
    de: 'Kapsel',
    en: 'Capsule',
  },
  tablet: {
    de: 'Tablette',
    en: 'Tablet',
  },
  drop: {
    de: 'Tropfen',
    en: 'Drop',
  },
  ml: {
    de: 'ml',
    en: 'ml',
  },
  mg: {
    de: 'mg',
    en: 'mg',
  },
  g: {
    de: 'gramm',
    en: 'gram',
  },
};

export const QUESTIONNAIRE_TEXT = {
  1: {
    title: 'i will.',
    text: 'Nimm deine Gesundheit jetzt in die Hand.',
  },
  2: {
    title: 'i check.',
    text: 'Deine Angaben werden wissenschaftlich ausgewertet.',
  },
  3: {
    title: 'i live.',
    text: 'Deine Gesundheitsthemen.',
  },
  4: {
    title: 'i try.',
    text: 'Deine Gewohnheiten und dein Lebensstil.',
  },
  5: {
    title: 'i eat.',
    text: 'Deine Ernährung',
  },
  6: {
    title: 'i work.',
    text: 'Zusatzfragen',
  },
  7: {
    title: 'i relax. ',
    text: 'Nimm deine Gesundheit jetzt in die Hand.',
  },
};

export const categories = ['Alles', 'Schlaf & Entspannung verbessern', 'Wohlbefinden & Energie', 'Gewichtsmanagement verbessern', 'Immunsystem unterstützen', 'Konzentration verbessern', 'Darmgesundheit optimieren'];

export const categoryOptions = categories.map((el) => ({
  value: el,
  label: el,
}));

export const entryTypes = {
  productIntake: 'user_product_intake_schedule',
  reminder: 'reminder',
  anchorAppointment: APPOINTMENT_TYPE_ANCHOR,
  bookingAppointment: APPOINTMENT_TYPE_BOOKING,
};

export const workflowStatuses = [
  { value: 'progress', label: 'In Arbeit' },
  { value: 'editorial_review', label: 'Inhaltprüfung' },
  { value: 'content_review', label: 'Zur Freigabe' },
  { value: 'released', label: 'Freigegeben' },
];

export const workflowStatusesWithoutReleased = [
  { value: 'progress', label: 'In Arbeit' },
  { value: 'editorial_review', label: 'Inhaltprüfung' },
  { value: 'content_review', label: 'Zur Freigabe' },
];

// alphanum, number, floating, bool, date

export const ATTRIBUTE_TYPE_ALPANUM = 'alphanum';
export const ATTRIBUTE_TYPE_NUMBER = 'number';
export const ATTRIBUTE_TYPE_FLOATING = 'floating';
export const ATTRIBUTE_TYPE_BOOL = 'bool';
export const ATTRIBUTE_TYPE_DATETIME = 'datetime';
export const ATTRIBUTE_TYPE_DATE = 'date';

export const ATTRIBUTE_TYPES = [ATTRIBUTE_TYPE_ALPANUM, ATTRIBUTE_TYPE_NUMBER, ATTRIBUTE_TYPE_FLOATING, ATTRIBUTE_TYPE_BOOL, ATTRIBUTE_TYPE_DATETIME, ATTRIBUTE_TYPE_DATE];

export const BOOL_ATTRIBUTE_OPTIONS = [
  { label: 'Yes', value: 'true' },
  { label: 'No', value: 'false' },
];

export const SESSION_STORAGE_WORKFLOW_ROW = 'workflowListRowIndex';
export const SESSION_STORAGE_WORKFLOW_PAGE = 'workflowListPage';
export const SESSION_STORAGE_NEWS_ROW = 'newsListRowIndex';
export const SESSION_STORAGE_NEWS_PAGE = 'newsListPage';
export const SESSION_STORAGE_CAMPAIGNS_ROW = 'campaignsListRowIndex';
export const SESSION_STORAGE_CAMPAIGNS_PAGE = 'campaignsListPage';
export const SESSION_STORAGE_TENANTS_ROW = 'tenantsListRowIndex';
export const SESSION_STORAGE_TENANTS_PAGE = 'tenantsListPage';
export const SESSION_STORAGE_USERS_ROW = 'usersListRowIndex';
export const SESSION_STORAGE_USERS_PAGE = 'usersListPage';
export const SESSION_STORAGE_CATEGORIES_ROW = 'categoriesListRowIndex';
export const SESSION_STORAGE_CATEGORIES_PAGE = 'categoriesListPage';
export const SESSION_STORAGE_PRODUCTS_ROW = 'productsListRowIndex';
export const SESSION_STORAGE_PRODUCTS_PAGE = 'productsListPage';
export const SESSION_STORAGE_SURVEYS_ROW = 'surveysListRowIndex';
export const SESSION_STORAGE_SURVEYS_PAGE = 'surveysListPage';
export const SESSION_STORAGE_TAGS_ROW = 'tagsListRowIndex';
export const SESSION_STORAGE_TAGS_PAGE = 'tagsListPage';
export const SESSION_STORAGE_ATTRIBUTES_ROW = 'attributesListRowIndex';
export const SESSION_STORAGE_ATTRIBUTES_PAGE = 'attributesListPage';
export const SESSION_STORAGE_CUBES_ROW = 'cubesListRowIndex';
export const SESSION_STORAGE_CUBES_PAGE = 'cubesListPage';
export const SESSION_STORAGE_CHARTS_ROW = 'chartsListRowIndex';
export const SESSION_STORAGE_CHARTS_PAGE = 'chartsListPage';
export const SESSION_STORAGE_APPOINTMENTS_ROW = 'appointmentsListRowIndex';
export const SESSION_STORAGE_APPOINTMENTS_PAGE = 'appointmentsListPage';
export const SESSION_STORAGE_HEALTH_ASSISTANTS_ROW = 'healthAssistantsListRowIndex';
export const SESSION_STORAGE_HEALTH_ASSISTANTS_PAGE = 'healthAssistantsListPage';
export const SESSION_STORAGE_HEALTH_PROFILES_ROW = 'healthProfilesListRowIndex';
export const SESSION_STORAGE_HEALTH_PROFILES_PAGE = 'healthProfilesListPage';
export const SESSION_STORAGE_AUTHORS_ROW = 'authorsListRowIndex';
export const SESSION_STORAGE_AUTHORS_PAGE = 'authorsListPage';
export const SESSION_STORAGE_AUTHORS_NEWS_PAGE = 'authorNewsListPage';
export const SESSION_STORAGE_AUTHORS_NEWS_ROW = 'authorsNewsRowIndex';
export const SESSION_STORAGE_FILTERS_PAGE = 'filtersListPage';
export const SESSION_STORAGE_FILTERS_ROW = 'filtersRowIndex';
export const SESSION_STORAGE_CUBE_GROUPS_PAGE = 'cubeGroupsListPage';
export const SESSION_STORAGE_CUBE_GROUPS_ROW = 'cubeGroupsRowIndex';
export const SESSION_STORAGE_ACTIVITY_PAGE = 'activitiesListPage';
export const SESSION_STORAGE_ACTIVITY_USERS_ROW = 'activitiesUsersRowIndex';
export const SESSION_STORAGE_ACTIVITY_USER_CUBES_ROW = 'activitiesUserCubesRowIndex';
export const SESSION_STORAGE_ACTIVITY_USER_HEALTH_ASSISTANTS_ROW = 'activitiesUserHealthAssistantsRowIndex';
export const SESSION_STORAGE_USER_CUBES_STATS_ROW = 'userCubesStatsRowIndex';


export const LOCAL_STORAGE_WORKFLOW_TABLE_SEARCH = 'workflowSearch';
export const LOCAL_STORAGE_USERS_TABLE_SEARCH = 'usersSearch';
export const LOCAL_STORAGE_PRODUCTS_TABLE_SEARCH = 'productsSearch';
export const LOCAL_STORAGE_NEWS_TABLE_SEARCH = 'newsSearch';
export const LOCAL_STORAGE_FILTERS_TABLE_SEARCH = 'filtersSearch';
export const LOCAL_STORAGE_CUBEGROUPS_TABLE_SEARCH = 'cubeGroupsSearch';
export const LOCAL_STORAGE_AUTHORS_TABLE_SEARCH = 'authorsSearch';
export const LOCAL_STORAGE_AUTHORS_NEWS_TABLE_SEARCH = 'authorsNewsSearch';
export const LOCAL_STORAGE_USER_NEWS_TABLE_SEARCH = 'userNewsSearch';
export const LOCAL_STORAGE_USER_PRODUCTS_TABLE_SEARCH = 'userProductsSearch';
export const LOCAL_STORAGE_USER_TAGS_TABLE_SEARCH = 'userTagsSearch';
export const LOCAL_STORAGE_USER_ATTRIBUTES_TABLE_SEARCH = 'userAttributesSearch';
export const LOCAL_STORAGE_USER_HEALTH_ASSISTANTS_TABLE_SEARCH = 'userHealthAssistansSearch';
export const LOCAL_STORAGE_USER_CAMPAIGNS_ACTIVE_TABLE_SEARCH = 'userCampaignsActiveSearch';
export const LOCAL_STORAGE_USER_CAMPAIGNS_ARCHIVED_TABLE_SEARCH = 'userCampaignsArchivedSearch';
export const LOCAL_STORAGE_USER_CUBES_ACITVE_TABLE_SEARCH = 'userCubesActiveSearch';
export const LOCAL_STORAGE_USER_CUBES_COMPLETED_TABLE_SEARCH = 'userCubesCompletedSearch';
export const LOCAL_STORAGE_USER_ATTRIBUTES_HISTORY_TABLE_SEARCH = 'userAttributesHistorySearch';
export const LOCAL_STORAGE_CUBE_NEWS_TABLE_SEARCH = 'cubeNewsSearch';
export const LOCAL_STORAGE_CUBE_PRODUCTS_TABLE_SEARCH = 'cubeProductsSearch';
export const LOCAL_STORAGE_TENANT_CODES_TABLE_SEARCH = 'tenantCodesSearch';
export const LOCAL_STORAGE_ACTIVITY_USERS_TABLE_SEARCH = 'activityUsersSearch';
export const LOCAL_STORAGE_ACTIVITY_USER_CUBES_TABLE_SEARCH = 'activityUserCubesSearch';
export const LOCAL_STORAGE_ACTIVITY_USER_HEALTH_ASSISTANTS_TABLE_SEARCH = 'activityUserHealthAssistantsSearch';
export const LOCAL_STORAGE_USER_CUBES_STATS_TABLE_SEARCH = 'userCubesStatsSearch';


export const USER_STATUS_OPTIONS = [
  { label: 'Neu', value: 'NEW' },
  { label: 'Opt-in OK', value: 'OPT_IN_OK' },
  { label: 'DISABLED', value: 'DISABLED' },
];

export const USERS_TABLE_ROUTE = '/users';
export const CREATE_NEWS_ROUTE = '/create-news';
export const NEWS_TABLE_ROUTE = '/news';
export const WORKFLOW_TABLE_ROUTE = '/workflow';
export const CATEGORY_TABLE_ROUTE = '/categories';
export const CAMPAIGN_TABLE_ROUTE = '/campaigns';
export const PRODUCT_TABLE_ROUTE = '/products';
export const SURVEY_TABLE_ROUTE = '/surveys';
export const TAG_TABLE_ROUTE = '/tags';
export const ATTRIBUTE_TABLE_ROUTE = '/attributes';
export const CHART_TABLE_ROUTE = '/charts';
export const APPOINTMENT_TABLE_ROUTE = '/appointments';
export const CUBE_TABLE_ROUTE = '/cubes';
export const HEALTHPROFILE_TABLE_ROUTE = '/health-profiles';
export const HEALTHASSISTANT_TABLE_ROUTE = '/health-assistants';
export const AUTHOR_TABLE_ROUTE = '/authors';
export const TENANT_TABLE_ROUTE = '/tenants';
export const FILTER_TABLE_ROUTE = '/filters';
export const CUBEGROUP_TABLE_ROUTE = '/cube-groups';
export const CODES_TABLE_ROUTE = '/codes';

export const INITIAL_TABLE_ROWS_MAX_COUNT = 100;
