import { takeLatest } from 'redux-saga/effects';
import { apiRequest } from './index';
import api from 'utils/api';
import * as userActions from 'actions/Activity';
import CubeApi from '../api/Cube';
import HealthAssistantsApi from '../api/HealthAssistant';

const getKeycloakUserEvents = apiRequest.bind(null, userActions.getKeycloakUserEvents, api.getKeycloakEvents);
const getAllAndUnreadFeedItemsCount = apiRequest.bind(null, userActions.getAllAndUnreadFeedItemsCount, api.getAllAndUnreadFeedItemsCount);
const getAllUsers = apiRequest.bind(null, userActions.getAllUsers, api.getAllUsers);
const getAllUserCubes = apiRequest.bind(null, userActions.getAllUserCubes, CubeApi.getAllUserCubes);
const getAllHealthAssistants = apiRequest.bind(null, userActions.getAllHealthAssistants, HealthAssistantsApi.getAllUserHealthAssistants);
const getAllUserCubesForId = apiRequest.bind(null, userActions.getAllUserCubesForId, CubeApi.getAllUserCubesForId);
const getActivityCsv = apiRequest.bind(null, userActions.getActivityCsv, api.getActivityCsv)
const getActivityCsvCube = apiRequest.bind(null, userActions.getActivityCsv, api.getActivityCsvCube)
const getActivityCsvUser = apiRequest.bind(null, userActions.getActivityCsv, api.getActivityCsvUser)

/******************************************************************************/
/******************************* WATCHERS *************************************/
/******************************************************************************/

export function* watchGetKeycloakUserEventsRequest() {
  yield takeLatest(userActions.GET_KEYCLOAK_USER_EVENTS.REQUEST, getKeycloakUserEvents);
}

export function* watchGetAllAndUnreadFeedItemsCountRequest() {
  yield takeLatest(userActions.GET_ALL_AND_UNREAD_FEED_ITEMS_COUNT.REQUEST, getAllAndUnreadFeedItemsCount);
}

export function* watchGetAllUsersRequest() {
  yield takeLatest(userActions.GET_ALL_USERS.REQUEST, getAllUsers);
}

export function* watchGetAllUserCubesRequest() {
  yield takeLatest(userActions.GET_ALL_USER_CUBES.REQUEST, getAllUserCubes);
}

export function* watchGetAllHealthAssistantsRequest() {
  yield takeLatest(userActions.GET_ALL_HEALTH_ASSISTANTS.REQUEST, getAllHealthAssistants);
}

export function* watchGetAllUserCubesForIdRequest() {
  yield takeLatest(userActions.GET_ALL_USER_CUBES_FOR_ID.REQUEST, getAllUserCubesForId);
}

export function* watchGetActivityCsvRequest() {
  yield takeLatest(userActions.GET_ACTIVITY_CSV.REQUEST, getActivityCsv);
  yield takeLatest(userActions.GET_ACTIVITY_CSV.REQUEST, getActivityCsvCube);
  yield takeLatest(userActions.GET_ACTIVITY_CSV.REQUEST, getActivityCsvUser);
}
