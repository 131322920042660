import { createRequestTypes, action } from '../utils/actions';
import { REQUEST, SUCCESS, FAILURE } from '../utils/constants';

export const GET_TAGS = createRequestTypes('GET_TAGS');
export const CREATE_TAG = createRequestTypes('CREATE_TAG');
export const DELETE_TAG = createRequestTypes('DELETE_TAG');
export const UPDATE_TAG = createRequestTypes('UPDATE_TAG');
export const GET_TAG = createRequestTypes('GET_TAG');
export const ADD_TAG_TO_USER = createRequestTypes('ADD_TAG_TO_USER');
export const DELETE_TAG_FROM_USER = createRequestTypes('DELETE_TAG_FROM_USER');
export const SET_TAGS_TABLE_FILTER = 'SET_TAGS_TABLE_FILTER';

export const RESET_TAG = 'RESET_TAG';

export const getTags = {
  request: (meta) => action(GET_TAGS[REQUEST], { meta }),
  success: (payload) => action(GET_TAGS[SUCCESS], { payload }),
  failure: (payload) => action(GET_TAGS[FAILURE], { payload }),
};

export const getTag = {
  request: (id) => action(GET_TAG[REQUEST], { id }),
  success: (payload) => action(GET_TAG[SUCCESS], { payload }),
  failure: (payload) => action(GET_TAG[FAILURE], { payload }),
};

export const updateTag = {
  request: (data) => action(UPDATE_TAG[REQUEST], { data }),
  success: (payload) => action(UPDATE_TAG[SUCCESS], { payload }),
  failure: (payload) => action(UPDATE_TAG[FAILURE], { payload }),
};

export const createTag = {
  request: (data) => action(CREATE_TAG[REQUEST], { data }),
  success: (payload) => action(CREATE_TAG[SUCCESS], { payload }),
  failure: (payload) => action(CREATE_TAG[FAILURE], { payload }),
};

export const deleteTag = {
  request: (id) => action(DELETE_TAG[REQUEST], { id }),
  success: (payload) => action(DELETE_TAG[SUCCESS], { payload }),
  failure: (payload) => action(DELETE_TAG[FAILURE], { payload }),
};

export const addTagToUser = {
  request: (id, data) => action(ADD_TAG_TO_USER[REQUEST], { id, data }),
  success: (payload) => action(ADD_TAG_TO_USER[SUCCESS], { payload }),
  failure: (payload) => action(ADD_TAG_TO_USER[FAILURE], { payload }),
};

export const deleteTagFromUser = {
  request: (id, data) => action(DELETE_TAG_FROM_USER[REQUEST], { id, data }),
  success: (payload) => action(DELETE_TAG_FROM_USER[SUCCESS], { payload }),
  failure: (payload) => action(DELETE_TAG_FROM_USER[FAILURE], { payload }),
};

export const setTagsTableFilter = (payload) => ({
  type: SET_TAGS_TABLE_FILTER,
  payload,
});

export const resetTag = () => ({
  type: RESET_TAG,
});
