import moment from 'moment';
import React from 'react';
import { Col, Container, Navbar, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const AppFooter = () => {
  return (
    <Row className="p-2" md={{ cols: 2 }} xs={{ cols: 1 }} style={{ textAlign: 'center', backgroundColor: '#43425d', color: '#fff' }}>
      <Col>
        <Link to="https://iqest.com/data-protection" rel="noopener noreferrer" target="_blank" style={{ color: '#fff', marginRight: '10px' }}>
          Datenschutz
        </Link>
        |
        <Link to="https://iqest.com/impressum" rel="noopener noreferrer" target="_blank" style={{ color: '#fff', margin: '0 10px' }}>
          Impressum
        </Link>
        |
        <Link to="https://iqest.com/cookies-policy" rel="noopener noreferrer" target="_blank" style={{ color: '#fff', margin: '0 10px' }}>
          Verwendung von Cookies
        </Link>
      </Col>
      <Col>Copyright © {moment().year()} IQ Digital Health GmbH</Col>
    </Row>
  );
};

export default AppFooter;
