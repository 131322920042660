import * as ActionTypes from 'actions/Authors';
import { get } from 'lodash';

const initialState = {
  authors: [],
  author: {},
  authorsTableFilter: '',
  authorsTableSorting: [],
  listPage: 0,
  listPages: 0,
  authorsNews: [],
  authorNewsPages: 0,
  authorsNewsTableFilter: '',
  authorsNewsTableSorting: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.RESET_AUTHOR:
      return { ...initialState };
    case ActionTypes.GET_AUTHORS.SUCCESS:
      return {
        ...state,
        authors: get(action, 'payload.content', []),
        listPage: get(action, 'payload.currentPage', 0),
        listPages: get(action, 'payload.totalPages', 1),
      };
    case ActionTypes.GET_AUTHOR.SUCCESS:
      return {
        ...state,
        author: action.payload,
      };
    case ActionTypes.UPDATE_AUTHOR.SUCCESS:
      return {
        ...state,
        author: action.payload,
      };
    case ActionTypes.DELETE_AUTHOR.SUCCESS:
      return {
        ...state,
        authors: state.authors.filter((el) => el.id !== action.payload),
      };
    case ActionTypes.GET_AUTHORS_NEWS.SUCCESS:
      return {
        ...state,
        authorsNews: action.payload?.content,
        authorNewsPages: action.payload?.totalPages,
      };
    case ActionTypes.SET_AUTHORS_TABLE_FILTER:
      return { ...state, authorsTableFilter: action.payload };
    case ActionTypes.SET_AUTHORS_TABLE_SORTING:
      return { ...state, authorsTableSorting: action.payload };
    case ActionTypes.SET_AUTHORS_NEWS_TABLE_FILTER:
      return { ...state, authorsNewsTableFilter: action.payload };
    case ActionTypes.SET_AUTHORS_NEWS_TABLE_SORTING:
      return { ...state, authorsNewsTableSorting: action.payload };
    default:
      return state;
  }
}
