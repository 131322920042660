import * as ActionTypes from '../actions/Campaigns';
import * as AuthActionTypes from '../actions/Auth';

const initialState = {
  campaigns: [],
  userCampaigns: [],
  archivedCampaign: {},
  activeCampaign: {},
  campaign: null,
  consistencyStatus: {},
  allUserActiveCampaigns: [],
  tableFilter: { search: '' },
  tableSorting: [],
  userArchivedCampaigns: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.RESET_CAMPAIGN:
    case AuthActionTypes.LOGOUT.SUCCESS:
      return { ...initialState };
    case ActionTypes.GET_CAMPAIGNS.SUCCESS:
      return { ...state, campaigns: Object.values(action.payload) };
    case ActionTypes.GET_CAMPAIGN_BY_VERSION.SUCCESS:
      return { ...state, campaign: action.payload };
    case ActionTypes.GET_CAMPAIGN.SUCCESS:
      return { ...state, campaign: action.payload };
    case ActionTypes.GET_USER_CAMPAIGNS.SUCCESS:
      return {
        ...state,
        userCampaigns: action.payload.content || [],
        userCampaignPages: action.payload.totalPages,
      };
    case ActionTypes.GET_USER_ARCHIVED_CAMPAIGNS.SUCCESS:
      return {
        ...state,
        userArchivedCampaigns: action.payload.content || [],
        userArchivedCampaignsPages: action.payload.totalPages,
      };
    case ActionTypes.SET_CAMPAIGNS_TABLE_FILTER:
      return { ...state, tableFilter: action.payload };
    case ActionTypes.SET_CAMPAIGNS_TABLE_SORTING:
      return { ...state, tableSorting: action.payload };
    case ActionTypes.STOP_USER_CAMPAIGN.SUCCESS:
      return { ...state, userCampaigns: state.userCampaigns.filter(({ id }) => id !== action.payload), allUserActiveCampaigns: state.userCampaigns.filter(({ id }) => id !== action.payload) };
    case ActionTypes.GET_ARCHIVED_CAMPAIGN.SUCCESS:
      return { ...state, archivedCampaign: action.payload };
    case ActionTypes.GET_ACTIVE_CAMPAIGN.SUCCESS:
      return { ...state, activeCampaign: action.payload };
    case ActionTypes.CHECK_CONSISTENCY.SUCCESS:
      return { ...state, consistencyStatus: action.payload };
    case ActionTypes.GET_All_USER_ACTIVE_CAMPAIGNS.SUCCESS:
      return { ...state, allUserActiveCampaigns: action.payload.content || [] };
    default:
      return state;
  }
}
