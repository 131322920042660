import * as ActionTypes from "actions/Groups";
import {get} from "lodash";

const initialState = {
  groups: [],
  group: {},
  userGroups: [],
  groupUsers: [],
  tableSorting: {},
  tableFilter: ''
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.GET_GROUPS.SUCCESS:
      return {
        ...state,
        groups: action.payload,
      };
    case ActionTypes.GET_GROUP.SUCCESS:
      return {
        ...state,
        group: action.payload,
      };
    case ActionTypes.GET_GROUP_USERS.SUCCESS:
      return {
        ...state,
        groupUsers: get(action.payload, 'content', []),
        usersPage: get(action, 'payload.currentPage', 0),
        usersPages: get(action, 'payload.totalPages', 0),
      };
    case ActionTypes.SET_GROUPS_TABLE_FILTER:
      return { ...state, tableFilter: action.payload }
    case ActionTypes.SET_GROUPS_TABLE_SORTING:
      return { ...state, tableSorting: action.payload }
    default:
      return state;
  }
}
