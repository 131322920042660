import { createRequestTypes, action } from '../utils/actions';
import { REQUEST, SUCCESS, FAILURE } from '../utils/constants';

export const GET_TENANTS = createRequestTypes('GET_TENANTS');
export const GET_TENANT = createRequestTypes('GET_TENANT');
export const CREATE_TENANT = createRequestTypes('CREATE_TENANT');
export const UPDATE_TENANT = createRequestTypes('UPDATE_TENANT');
export const DELETE_TENANT = createRequestTypes('DELETE_TENANT');
export const SET_TENANTS_TABLE_FILTER = 'SET_TENANTS_TABLE_FILTER';
export const SET_TENANTS_TABLE_SORTING = 'SET_TENANTS_TABLE_SORTING';

export const RESET_TENANT = 'RESET_TENANT';

export const getTenants = {
  request: () => action(GET_TENANTS[REQUEST]),
  success: (payload) => action(GET_TENANTS[SUCCESS], { payload }),
  failure: (payload) => action(GET_TENANTS[FAILURE], { payload }),
};

export const getTenant = {
  request: (id) => action(GET_TENANT[REQUEST], { id }),
  success: (payload) => action(GET_TENANT[SUCCESS], { payload }),
  failure: (payload) => action(GET_TENANT[FAILURE], { payload }),
};

export const createTenant = {
  request: (data) => action(CREATE_TENANT[REQUEST], { data }),
  success: (payload) => action(CREATE_TENANT[SUCCESS], { payload }),
  failure: (payload) => action(CREATE_TENANT[FAILURE], { payload }),
};

export const updateTenant = {
  request: (data) => action(UPDATE_TENANT[REQUEST], { data }),
  success: (payload) => action(UPDATE_TENANT[SUCCESS], { payload }),
  failure: (payload) => action(UPDATE_TENANT[FAILURE], { payload }),
};

export const deleteTenant = {
  request: (id) => action(DELETE_TENANT[REQUEST], { id }),
  success: (payload) => action(DELETE_TENANT[SUCCESS], { payload }),
  failure: (payload) => action(DELETE_TENANT[FAILURE], { payload }),
};

export const setTenantsTableFilter = (payload) => ({
  type: SET_TENANTS_TABLE_FILTER,
  payload,
});

export const setTenantsTableSorting = (payload) => ({
  type: SET_TENANTS_TABLE_SORTING,
  payload,
});

export const resetTenant = () => ({
  type: RESET_TENANT,
});
