import axios from 'axios';
import { get, omit } from 'lodash';
import { healthAssistantsUrl, healthAssistantsVersion } from 'config/environment';

const getHealthAssistants = () => {
  return axios
    .get(`${healthAssistantsUrl}/api/healthAssistants/`, { headers: { 'X-API-VERSION': healthAssistantsVersion } })
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: get(err, 'response.data.message', 'Die Anfrage ist fehlgeschlagen') || 'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const getHealthAssistant = (id) => {
  return axios
    .get(`${healthAssistantsUrl}/api/healthAssistants/${id}/`, { headers: { 'X-API-VERSION': healthAssistantsVersion } })
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: get(err, 'response.data.message', 'Die Anfrage ist fehlgeschlagen') || 'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const updateHealthAssistant = (data) => {
  return axios
    .put(`${healthAssistantsUrl}/api/healthAssistants/${data.id}/`, omit(data, 'id'), { headers: { 'X-API-VERSION': healthAssistantsVersion } })
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: get(err, 'response.data.message', 'Die Anfrage ist fehlgeschlagen') || 'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const getAllUserHealthAssistantsForUserUuid = (userUuid) => {
  return axios
    .get(`${healthAssistantsUrl}/api/userhealthassistants/${userUuid}`, { headers: { 'X-API-VERSION': healthAssistantsVersion } })
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: get(err, 'response.data.message', 'Die Anfrage ist fehlgeschlagen') || 'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const getAllUserHealthAssistants = () => {
  return axios
    .get(`${healthAssistantsUrl}/api/userhealthassistants`, { headers: { 'X-API-VERSION': healthAssistantsVersion } })
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: get(err, 'response.data.message', 'Die Anfrage ist fehlgeschlagen') || 'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

export default {
  getHealthAssistants,
  getHealthAssistant,
  updateHealthAssistant,
  getAllUserHealthAssistantsForUserUuid,
  getAllUserHealthAssistants,
};
