import { createRequestTypes, action } from '../utils/actions';
import { REQUEST, SUCCESS, FAILURE } from '../utils/constants';

export const GET_HEALTH_ASSISTANTS = createRequestTypes('GET_HEALTH_ASSISTANTS');
export const GET_HEALTH_ASSISTANT = createRequestTypes('GET_HEALTH_ASSISTANT');
export const GET_ALL_USER_HEALTH_ASSISTANTS = createRequestTypes('GET_ALL_USER_HEALTH_ASSISTANTS');
export const SET_USER_HEALTH_ASSISTANTS_TABLE_FILTER = 'SET_USER_HEALTH_ASSISTANTS_TABLE_FILTER';
export const SET_USER_HEALTH_ASSISTANTS_TABLE_SORTING = 'SET_USER_HEALTH_ASSISTANTS_TABLE_SORTING';

export const RESET_HEALTH_ASSISTANT = 'RESET_HEALTH_ASSISTANT';

export const getHealthAssistants = {
  request: () => action(GET_HEALTH_ASSISTANTS[REQUEST]),
  success: (payload) => action(GET_HEALTH_ASSISTANTS[SUCCESS], { payload }),
  failure: (payload) => action(GET_HEALTH_ASSISTANTS[FAILURE], { payload }),
};

export const getHealthAssistant = {
  request: (id) => action(GET_HEALTH_ASSISTANT[REQUEST], { id }),
  success: (payload) => action(GET_HEALTH_ASSISTANT[SUCCESS], { payload }),
  failure: (payload) => action(GET_HEALTH_ASSISTANT[FAILURE], { payload }),
};

export const getAllUserHealthAssistantsForUserUuid = {
  request: (id) => action(GET_ALL_USER_HEALTH_ASSISTANTS[REQUEST], { id }),
  success: (payload) => action(GET_ALL_USER_HEALTH_ASSISTANTS[SUCCESS], { payload }),
  failure: (payload) => action(GET_ALL_USER_HEALTH_ASSISTANTS[FAILURE], { payload }),
};

export const setUserHealthAssistantsTableFilter = (payload) => ({
  type: SET_USER_HEALTH_ASSISTANTS_TABLE_FILTER,
  payload,
});

export const setUserHealthAssistantsTableSorting = (payload) => ({
  type: SET_USER_HEALTH_ASSISTANTS_TABLE_SORTING,
  payload,
});

export const resetHealthAssistant = () => ({
  type: RESET_HEALTH_ASSISTANT,
});
