import { createRequestTypes, action } from '../utils/actions';
import { REQUEST, SUCCESS, FAILURE } from '../utils/constants';

export const GET_FILTERS = createRequestTypes('GET_FILTERS');
export const GET_ALL_FILTERS = createRequestTypes('GET__ALL_FILTERS');
export const GET_FILTER = createRequestTypes('GET_FILTER');
export const UPDATE_FILTER = createRequestTypes('UPDATE_FILTER');
export const CREATE_FILTER = createRequestTypes('CREATE_FILTER');
export const DELETE_FILTER = createRequestTypes('DELETE_FILTER');

export const SET_FILTERS_TABLE_FILTER = 'SET_FILTERS_TABLE_FILTER';
export const SET_FILTERS_TABLE_SORTING = 'SET_FILTERS_TABLE_SORTING';

export const RESET_FILTER = 'RESET_FILTER';

export const getFilters = {
  request: (data) => action(GET_FILTERS[REQUEST], { data }),
  success: (payload) => action(GET_FILTERS[SUCCESS], { payload }),
  failure: (payload) => action(GET_FILTERS[FAILURE], { payload }),
};

export const getAllFilters = {
  request: () => action(GET_ALL_FILTERS[REQUEST]),
  success: (payload) => action(GET_ALL_FILTERS[SUCCESS], { payload }),
  failure: (payload) => action(GET_ALL_FILTERS[FAILURE], { payload }),
};

export const getFilter = {
  request: (id) => action(GET_FILTER[REQUEST], { id }),
  success: (payload) => action(GET_FILTER[SUCCESS], { payload }),
  failure: (payload) => action(GET_FILTER[FAILURE], { payload }),
};

export const updateFilter = {
  request: (id, data, options) => action(UPDATE_FILTER[REQUEST], { data, id, options }),
  success: (payload) => action(UPDATE_FILTER[SUCCESS], { payload }),
  failure: (payload) => action(UPDATE_FILTER[FAILURE], { payload }),
};

export const deleteFilter = {
  request: (id) => action(DELETE_FILTER[REQUEST], { id }),
  success: (payload) => action(DELETE_FILTER[SUCCESS], { payload }),
  failure: (payload) => action(DELETE_FILTER[FAILURE], { payload }),
};

export const createFilter = {
  request: (data, options) => action(CREATE_FILTER[REQUEST], { data, options }),
  success: (payload) => action(CREATE_FILTER[SUCCESS], { payload }),
  failure: (payload) => action(CREATE_FILTER[FAILURE], { payload }),
};

export const setFiltersTableFilter = (payload) => ({
  type: SET_FILTERS_TABLE_FILTER,
  payload,
});

export const setFiltersTableSorting = (payload) => ({
  type: SET_FILTERS_TABLE_SORTING,
  payload,
});

export const resetFilter = () => ({
  type: RESET_FILTER,
});
