import { takeEvery, put, takeLatest } from 'redux-saga/effects';
import { secureApiRequest } from './index';
import api from 'utils/api';
import * as categoriesActions from 'actions/Categories';
import { toast } from 'react-toastify';
import { get } from 'lodash';

const getCategories = secureApiRequest.bind(null, categoriesActions.getCategories, api.getCategories);
const getCategory = secureApiRequest.bind(null, categoriesActions.getCategory, api.getCategory);
const createCategory = secureApiRequest.bind(null, categoriesActions.createCategory, api.createCategory);
const deleteCategory = secureApiRequest.bind(null, categoriesActions.deleteCategory, api.deleteCategory);
const updateCategory = secureApiRequest.bind(null, categoriesActions.updateCategory, api.updateCategory);

function* refreshCategories() {
  yield put({ type: categoriesActions.GET_CATEGORIES.REQUEST });
}

function* handleCreateSuccess(action) {
  if (!get(action.payload, 'options') === 'redirect') {
    yield toast.success('Gespeichert');
  }
}

export function* watchGetCategoryRequest() {
  yield takeLatest(categoriesActions.GET_CATEGORY.REQUEST, getCategory);
}

export function* watchGetCategoriesRequest() {
  yield takeLatest(categoriesActions.GET_CATEGORIES.REQUEST, getCategories);
}

export function* watchCreateCategoryRequest() {
  yield takeEvery(categoriesActions.CREATE_CATEGORY.REQUEST, createCategory);
}

export function* watchCreateCategorySuccess() {
  yield takeEvery(categoriesActions.CREATE_CATEGORY.SUCCESS, handleCreateSuccess);
}

export function* watchUpdateCategorySuccess() {
  yield takeEvery(categoriesActions.UPDATE_CATEGORY.SUCCESS, handleCreateSuccess);
}

export function* watchUpdateCategoryRequest() {
  yield takeEvery(categoriesActions.UPDATE_CATEGORY.REQUEST, updateCategory);
}

export function* watchDeleteCategoryRequest() {
  yield takeEvery(categoriesActions.DELETE_CATEGORY.REQUEST, deleteCategory);
}

export function* watchDeleteCategorySuccess() {
  yield takeEvery(categoriesActions.DELETE_CATEGORY.SUCCESS, refreshCategories);
}
