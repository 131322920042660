import { createRequestTypes, action } from '../utils/actions';
import { REQUEST, SUCCESS, FAILURE } from '../utils/constants';

export const GET_DOCUMENTS = createRequestTypes('GET_DOCUMENTS');
export const GET_DOCUMENT = createRequestTypes('GET_DOCUMENT');
export const CREATE_DOCUMENT = createRequestTypes('CREATE_DOCUMENT');
export const UPDATE_DOCUMENT = createRequestTypes('UPDATE_DOCUMENT');
export const DELETE_DOCUMENT = createRequestTypes('DELETE_DOCUMENT');
export const GET_DOCUMENT_BY_VERSION = createRequestTypes('GET_DOCUMENT_BY_VERSION');
export const GET_DOCUMENT_PERMISSIONS = createRequestTypes('GET_DOCUMENT_PERMISSIONS');
export const CREATE_DOCUMENT_PERMISSIONS = createRequestTypes('CREATE_DOCUMENT_PERMISSIONS');
export const DELETE_DOCUMENT_PERMISSIONS = createRequestTypes('DELETE_DOCUMENT_PERMISSIONS');

export const getDocuments = {
  request: () => action(GET_DOCUMENTS[REQUEST]),
  success: (payload) => action(GET_DOCUMENTS[SUCCESS], {payload}),
  failure: (payload) => action(GET_DOCUMENTS[FAILURE], {payload}),
}

export const getDocument = {
  request: (id) => action(GET_DOCUMENT[REQUEST], {id}),
  success: (payload) => action(GET_DOCUMENT[SUCCESS], {payload}),
  failure: (payload) => action(GET_DOCUMENT[FAILURE], {payload}),
}

export const getDocumentByVersion = {
  request: (data) => action(GET_DOCUMENT_BY_VERSION[REQUEST], {data}),
  success: (payload) => action(GET_DOCUMENT_BY_VERSION[SUCCESS], {payload}),
  failure: (payload) => action(GET_DOCUMENT_BY_VERSION[FAILURE], {payload}),
}

export const createDocument = {
  request: (data) => action(CREATE_DOCUMENT[REQUEST], {data}),
  success: (payload) => action(CREATE_DOCUMENT[SUCCESS], {payload}),
  failure: (payload) => action(CREATE_DOCUMENT[FAILURE], {payload}),
}

export const updateDocument = {
  request: (id, data) => action(UPDATE_DOCUMENT[REQUEST], {id, data}),
  success: (payload) => action(UPDATE_DOCUMENT[SUCCESS], {payload}),
  failure: (payload) => action(UPDATE_DOCUMENT[FAILURE], {payload}),
}

export const deleteDocument = {
  request: (id) => action(DELETE_DOCUMENT[REQUEST], {id}),
  success: (payload) => action(DELETE_DOCUMENT[SUCCESS], {payload}),
  failure: (payload) => action(DELETE_DOCUMENT[FAILURE], {payload}),
}

export const createDocumentPermissions = {
  request: (id, data) => action(CREATE_DOCUMENT_PERMISSIONS[REQUEST], {id, data}),
  success: (payload) => action(CREATE_DOCUMENT_PERMISSIONS[SUCCESS], {payload}),
  failure: (payload) => action(CREATE_DOCUMENT_PERMISSIONS[FAILURE], {payload}),
}

export const getDocumentPermissions = {
  request: (id) => action(GET_DOCUMENT_PERMISSIONS[REQUEST], {id}),
  success: (payload) => action(GET_DOCUMENT_PERMISSIONS[SUCCESS], {payload}),
  failure: (payload) => action(GET_DOCUMENT_PERMISSIONS[FAILURE], {payload}),
}

export const deleteDocumentPermissions = {
  request: (id) => action(DELETE_DOCUMENT_PERMISSIONS[REQUEST], {id}),
  success: (payload) => action(DELETE_DOCUMENT_PERMISSIONS[SUCCESS], {payload}),
  failure: (payload) => action(DELETE_DOCUMENT_PERMISSIONS[FAILURE], {payload}),
}