import { all, takeEvery, put, takeLatest } from 'redux-saga/effects';
import { apiRequest } from './index';
import AppointmentsApi from '../api/Appointments';
import * as appointmentsActions from 'actions/Appointments';
import { toast } from 'react-toastify';

const getAppointments = apiRequest.bind(null, appointmentsActions.getAppointments, AppointmentsApi.getAppointments);
const getAppointment = apiRequest.bind(null, appointmentsActions.getAppointment, AppointmentsApi.getAppointment);

function* updateAppointment({ id, data, options }) {
  const { response } = yield AppointmentsApi.updateAppointment(id, data, options);
  yield put({
    type: appointmentsActions.UPDATE_APPOINTMENT.SUCCESS,
    payload: response,
  });
}

function* deleteAppointment({ id }) {
  const { error } = yield AppointmentsApi.deleteAppointment(id);
  if (error) {
    yield put({ type: appointmentsActions.DELETE_APPOINTMENT.FAILURE, payload: error });
  } else {
    yield put({ type: appointmentsActions.DELETE_APPOINTMENT.SUCCESS, payload: id });
  }
}

function* createAppointment({ data, options }) {
  const { response } = yield AppointmentsApi.createAppointment(data);
  yield put({
    type: appointmentsActions.CREATE_APPOINTMENT.SUCCESS,
    payload: response,
  });
}

const appointmentSaveHandler = () => {
  toast.success('Gespeichert');
};

/******************************************************************************/
/******************************* WATCHERS *************************************/
/******************************************************************************/

export function* rootWatcherAppointments() {
  yield all([
    takeLatest(appointmentsActions.GET_APPOINTMENTS.REQUEST, getAppointments),
    takeEvery(appointmentsActions.CREATE_APPOINTMENT.REQUEST, createAppointment),
    takeEvery(appointmentsActions.CREATE_APPOINTMENT.SUCCESS, appointmentSaveHandler),
    takeLatest(appointmentsActions.GET_APPOINTMENT.REQUEST, getAppointment),
    takeEvery(appointmentsActions.UPDATE_APPOINTMENT.REQUEST, updateAppointment),
    takeEvery(appointmentsActions.UPDATE_APPOINTMENT.SUCCESS, appointmentSaveHandler),
    takeEvery(appointmentsActions.DELETE_APPOINTMENT.REQUEST, deleteAppointment),
  ]);
}
