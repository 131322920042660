import { createRequestTypes, action } from '../utils/actions';
import { REQUEST, SUCCESS, FAILURE } from '../utils/constants';

export const GET_ALL_CODES = createRequestTypes('GET_ALL_CODES');
export const GENERATE_CODES = createRequestTypes('GENERATE_CODES');
export const DELETE_CODE = createRequestTypes('DELETE_CODE');
export const GET_CODE = createRequestTypes('GET_CODE');
export const EDIT_CODE = createRequestTypes('EDIT_CODE');

export const RESET_CODE_PROPS = 'RESET_CODE_PROPS';

export const getAllCodes = {
  request: () => action(GET_ALL_CODES[REQUEST], {}),
  success: (payload) => action(GET_ALL_CODES[SUCCESS], { payload }),
  failure: (payload) => action(GET_ALL_CODES[FAILURE], { payload }),
};

export const generateCodes = {
  request: (data) => action(GENERATE_CODES[REQUEST], { data }),
  success: (payload) => action(GENERATE_CODES[SUCCESS], { payload }),
  failure: (payload) => action(GENERATE_CODES[FAILURE], { payload }),
};

export const deleteCode = {
  request: (data) => action(DELETE_CODE[REQUEST], { data }),
  success: (payload) => action(DELETE_CODE[SUCCESS], { payload }),
  failure: (payload) => action(DELETE_CODE[FAILURE], { payload }),
};

export const getCode = {
  request: (data) => action(GET_CODE[REQUEST], { data }),
  success: (payload) => action(GET_CODE[SUCCESS], { payload }),
  failure: (payload) => action(GET_CODE[FAILURE], { payload }),
};

export const editCode = {
  request: (data) => action(EDIT_CODE[REQUEST], { data }),
  success: (payload) => action(EDIT_CODE[SUCCESS], { payload }),
  failure: (payload) => action(EDIT_CODE[FAILURE], { payload }),
};

export const resetCodeProps = () => ({
  type: RESET_CODE_PROPS,
});


