import { useEffect } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import ReactGA from 'react-ga';

import AppMain from '../AppMain/AppMain';

const Main = (props) => {
  useEffect(() => {
    ReactGA.initialize('UA-130716105-2');
  }, []);

  let { darkTheme } = props;

  return (
    <div
      className={cx('app-container app-theme-gray', {
        'dark-theme': darkTheme,
      })}
    >
      <AppMain />
    </div>
  );
};

const mapStateToProp = ({ Theme }) => ({
  darkTheme: Theme.darkTheme,
});

export default connect(mapStateToProp)(Main);
