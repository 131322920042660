import axios from 'axios';
import { get, omit } from 'lodash';
import { dmsUrl } from 'config/environment';

const dmsApiVersion = 'v1.0';

const getAuthors = (data = {}) => {
  const params = {
    size: data.size || 100,
    page: data.page || 0,
  };
  if (data.titleContains) params.titleContains = get(data, 'titleContains', '');
  if (data.sortBy) {
    params[`sort.${data.sortBy}.direction`] = get(data, 'sortDirection', '');
    params[`sort.${data.sortBy}.order`] = 0;
  }
  return axios
    .get(`${dmsUrl}/api/author/${dmsApiVersion}/`, { params: params })
    .then((response) => ({ response: response.data }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const getAuthorsNews = (data = {}) => {
  const params = {
    size: data.size || 100,
    page: data.page || 0,
  };
  params.authorId = data.authorId;
  if (data.titleContains) params.titleContains = get(data, 'titleContains', '');
  if (data.category) params.category = get(data, 'category', '');
  if (data.sortBy) {
    params[`sort.${data.sortBy}.direction`] = get(data, 'sortDirection', '');
    params[`sort.${data.sortBy}.order`] = 0;
  }
  return axios
    .get(`${dmsUrl}/api/news/filter/${dmsApiVersion}/`, { params: params })
    .then((response) => ({ response: response.data }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const getAuthor = (id) => {
  return axios
    .get(`${dmsUrl}/api/author/${id}/${dmsApiVersion}/`)
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: get(err, 'response.data.message', 'Die Anfrage ist fehlgeschlagen') || 'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const createAuthor = (data) => {
  return axios
    .post(`${dmsUrl}/api/author/${dmsApiVersion}/`, omit(data, 'redirect'))
    .then((response) => {
      if (data.redirect) data.redirect(response.data.id);
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: get(err, 'response.data.message', 'Die Anfrage ist fehlgeschlagen') || 'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const updateAuthor = (id, data) => {
  return axios
    .put(`${dmsUrl}/api/author/${id}/${dmsApiVersion}/`, omit(data, 'redirect'))
    .then((response) => {
      if (data.redirect) data.redirect();
      return { response: response.data };
    })
    .catch((err) => {
      return {
        error: {
          ...get(err, 'response.data', {}),
          message: get(err, 'response.data.message', 'Die Anfrage ist fehlgeschlagen') || 'Die Anfrage ist fehlgeschlagen',
        },
      };
    });
};

const deleteAuthor = (id) => {
  return axios
    .delete(`${dmsUrl}/api/author/${id}/${dmsApiVersion}/`)
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: get(err, 'response.data.message', 'Die Anfrage ist fehlgeschlagen') || 'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const uploadDocumentFile = (data) => {
  const payload = new FormData();
  payload.append('file', data);
  return axios({
    method: 'post',
    headers: {
      'X-File-Length': data.size,
    },
    url: `${dmsUrl}/api/files/temp/upload/${dmsApiVersion}/`,
    data: payload,
  })
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

export default {
  getAuthors,
  createAuthor,
  getAuthor,
  updateAuthor,
  deleteAuthor,
  uploadDocumentFile,
  getAuthorsNews,
};
