import * as ActionTypes from 'actions/Activity';
import * as AuthActionTypes from 'actions/Auth';
import { get } from 'lodash';
import { CUBE_STATUS_ACTIVE, CUBE_STATUS_INACTIVE, CUBE_STATUS_READY, HEALTH_ASSISTANT_STATUS_ACTIVE, HEALTH_ASSISTANT_STATUS_FINISHED, HEALTH_ASSISTANT_STATUS_INACTIVE, HEALTH_ASSISTANT_STATUS_STARTED } from 'pages/UserDetails/constants';

const initialState = {
  users: [],
  userCubes: [],
  userHealthAssistants: [],
  usersLoaded: false,
  userCubesForId: [],
  userActivity: [],
  cubeActivity: [],
  userCubeActivity: [],
  fastingActivity: [],
  phValueActivity: [],
  weighInActivity: [],
  userAttributesActivity: []
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case AuthActionTypes.LOGOUT.SUCCESS:
      return { ...initialState };

    case ActionTypes.GET_ALL_USERS.SUCCESS:
      const newUsers1 = action.payload.map((item, i) => {
        const idx = state.users.findIndex((user) => user.keycloakUuid === item.keycloakUuid);
        return Object.assign({}, item, idx !== -1 ? state.users[idx] : {});
      });
      const ids1 = new Set(newUsers1.map((user) => user.keycloakUuid));
      return {
        ...state,
        users: [...newUsers1, ...state.users.filter((d) => !ids1.has(d.keycloakUuid))],
        usersLoaded: true,
      };

    case ActionTypes.GET_KEYCLOAK_USER_EVENTS.SUCCESS:
      const eventsByUserUuidObj = action.payload.reduce((acc, event) => {
        (acc[event['userId']] = acc[event['userId']] || []).push(event);
        return acc;
      }, {});
      const eventsByUserUuid = Object.keys(eventsByUserUuidObj).map((key) => ({
        keycloakUuid: key,
        events: eventsByUserUuidObj[key],
      }));
      const newUsers = eventsByUserUuid
        .map((userEvents) => {
          const latestLogin = userEvents.events.reduce((acc, event) => {
            return (acc = acc > event.time ? acc : event.time);
          }, 0);
          const userLoginActivity = { keycloakUuid: userEvents.keycloakUuid, loginsCount: userEvents.events.length, latestLogin: userEvents.events.length > 0 ? latestLogin : null };
          return userLoginActivity;
        })
        .map((item, i) => {
          const idx = state.users.findIndex((user) => user.keycloakUuid === item.keycloakUuid);
          return Object.assign({}, item, idx !== -1 ? state.users[idx] : {});
        });
      const ids = new Set(newUsers.map((user) => user.keycloakUuid));
      return {
        ...state,
        users: [...newUsers.filter((d) => d.userDetail?.email), ...state.users.filter((d) => !ids.has(d.keycloakUuid))],
      };

    case ActionTypes.GET_ALL_AND_UNREAD_FEED_ITEMS_COUNT.SUCCESS:
      const newUsers2 = action.payload.map((item, i) => {
        const idx = state.users.findIndex((user) => user.keycloakUuid === item.userUuid);
        return Object.assign({}, { ...item, keycloakUuid: item.userUuid }, idx !== -1 ? state.users[idx] : {});
      });
      const ids2 = new Set(newUsers2.map((user) => user.keycloakUuid));
      return {
        ...state,
        users: [...newUsers2, ...state.users.filter((d) => !ids2.has(d.keycloakUuid))],
      };

    case ActionTypes.GET_ALL_USER_CUBES.SUCCESS:
      const groupedCubes = action.payload.reduce(function (rv, x) {
        let current = get(x, 'cubeInfo.id', null);
        if (current) (rv[current] = rv[current] || []).push(x);
        return rv;
      }, {});
      const userCubesById = Object.keys(groupedCubes).map((key) => ({
        id: key,
        name: groupedCubes[key][0].cubeInfo.name,
        active: groupedCubes[key].filter((cube) => cube.status === CUBE_STATUS_ACTIVE).length,
        ready: groupedCubes[key].filter((cube) => cube.status === CUBE_STATUS_READY).length,
        inactive: groupedCubes[key].filter((cube) => cube.status === CUBE_STATUS_INACTIVE).length,
      }));
      return { ...state, userCubes: userCubesById };

    case ActionTypes.GET_ALL_HEALTH_ASSISTANTS.SUCCESS:
      const groupedUserHealthAssistants = action.payload.reduce(function (rv, x) {
        let current = get(x, 'type', null);
        if (current) (rv[current] = rv[current] || []).push(x);
        return rv;
      }, {});
      const userHealthAssistantsByType = Object.keys(groupedUserHealthAssistants).map((key) => ({
        type: key,
        active: groupedUserHealthAssistants[key].filter((userHealthAssistant) => userHealthAssistant.status === HEALTH_ASSISTANT_STATUS_ACTIVE).length,
        inactive: groupedUserHealthAssistants[key].filter((userHealthAssistant) => userHealthAssistant.status === HEALTH_ASSISTANT_STATUS_INACTIVE).length,
        started: groupedUserHealthAssistants[key].filter((userHealthAssistant) => userHealthAssistant.status === HEALTH_ASSISTANT_STATUS_STARTED).length,
        finished: groupedUserHealthAssistants[key].filter((userHealthAssistant) => userHealthAssistant.status === HEALTH_ASSISTANT_STATUS_FINISHED).length,
      }));
      return { ...state, userHealthAssistants: userHealthAssistantsByType };
    case ActionTypes.GET_ALL_USER_CUBES_FOR_ID.SUCCESS:
      return { ...state, userCubesForId: action.payload };
    case ActionTypes.GET_ACTIVITY_CSV.SUCCESS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
