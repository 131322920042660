import jwtDecode from 'jwt-decode';

export const checkTokenExpiration = () => {
  try {
    const token = localStorage.getItem("IQ_TOKEN");
    if (jwtDecode(token).exp < Date.now() / 1000) {
      return 'expired';
    }
    return token;
  } catch(err) {
    return null;
  }

};

export const checkRefreshTokenExpiration = () => {
  try {
    const token = localStorage.getItem("IQ_REFRESH_TOKEN");
    if (jwtDecode(token).exp < Date.now() / 1000) {
      return 'expired';
    }
    return token;
  } catch(err) {
    return null;
  }
}

export const saveToken = (token) => {
  window.localStorage && window.localStorage.setItem('IQ_TOKEN', token);
}

export const saveRefreshToken = (token) => {
  window.localStorage && window.localStorage.setItem('IQ_REFRESH_TOKEN', token);
}

export const clearTokens = () => {
  window.localStorage && window.localStorage.removeItem('IQ_TOKEN');
  window.localStorage && window.localStorage.removeItem('IQ_REFRESH_TOKEN');
}
