import { get, set } from "lodash";

const setNodeById = (tree, parts, node, branchIndex) => {
  let options = tree;
  let pathWithIndex = [];
  return parts.reduce((acc, id, i) => {
    if (i < parts.length - 1) {
      const index = options.findIndex(el => id === el.id);
      options = get(options, `${index}.children`, []);
    } else {
      const index = options.findIndex(el => id === el.id);
      if (index !== -1) {
        let parentPath = options[index].path;
        if (parentPath) pathWithIndex.push(parentPath);
        pathWithIndex.push(`${id}:${options[index].children.length}`);
        options[index].children.push({
          ...node,
          pathWithIndex: pathWithIndex.join(".")
        });
      }
    }
    return tree;
  }, tree);
};

const setNodeByIdNew = (tree, parts, node, index) => {
  let options = tree;
  return parts.reduce((acc, id, i) => {
    const pathComponents = id.split(":");
    if (i < parts.length - 1) {
      const index = options.findIndex(el => {
        if (el) {
          return id === el.id;
        }
      });
      options = get(options, `${index}.children`, []);
    } else {
      const index = options.findIndex(el => {
        if (el) {
          return pathComponents[0] === el.id;
        }
      });
      if (index !== -1) {
        const nodeIndex = parseInt(pathComponents[1]);
        set(options[index].children, nodeIndex, node);
      }
    }
    return tree;
  }, tree);
};

export const flatDataToTreeNew = flatData => {
  // console.time('tree');
  window.docIdsMap = {};
  const tree = flatData.reduce((acc, el, i) => {
    const elName = get(el, `currentVersion.name`);
    const newNode = {
      ...el,
      title: elName,
      name: elName,
      isDirectory: el.type === "dir",
      children: [],
      expanded: get(window, `expNodes.${el.id}`, false),
      canNodeHaveChildren: el.type === "dir"
    };
    window.docIdsMap[el.id] = newNode;

    if (!el.path) return acc;
    const pathComponents = el.path.split(":");
    if (pathComponents.length < 2) return acc;
    if (pathComponents.length === 2 && get(pathComponents, "0") === "") {
      set(acc, parseInt(get(pathComponents, "1")), newNode);
      return acc;
    } else {
      const parts = el.path.split(".");
      return setNodeByIdNew(acc, parts, newNode, i);
    }
  }, []);
  // console.log('treeNEW', flatData, tree)
  // console.timeEnd('tree');
  return tree;
};

export const flatDataToTree = flatData => {
  // console.time('tree');
  window.docIdsMap = {};
  const tree = flatData.reduce((acc, el, i) => {
    const elName = get(el, `currentVersion.name`);
    window.docIdsMap[el.id] = elName;
    const newNode = {
      ...el,
      title: elName,
      name: elName,
      isDirectory: el.type === "dir",
      children: [],
      expanded: get(window, `expNodes.${el.id}`, false)
    };
    if (!el.path) {
      return [...acc, { ...newNode, pathWithIndex: `${el.path}:${i}` }];
    } else {
      const parts = el.path.split(".");
      return setNodeById(acc, parts, newNode, i);
    }
  }, []);
  // console.log('treeOLD', flatData, tree)
  // console.timeEnd('tree');
  return tree;
};
