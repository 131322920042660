import { select, takeEvery, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import * as campaignsActions from 'actions/Campaigns';
import api from '../utils/api';
import { apiRequest } from './index';
import { get } from 'lodash';

const getCampaigns = apiRequest.bind(null, campaignsActions.getCampaigns, api.getCampaigns);
const getCampaign = apiRequest.bind(null, campaignsActions.getCampaign, api.getCampaign);
const startCampaign = apiRequest.bind(null, campaignsActions.startCampaign, api.startCampaign);
const startCampaignAccelerate = apiRequest.bind(null, campaignsActions.startCampaignAccelerate, api.startCampaignAccelerate);
const getUserCampaigns = apiRequest.bind(null, campaignsActions.getUserCampaigns, api.getUserCampaigns);
const getUserArchivedCampaigns = apiRequest.bind(null, campaignsActions.getUserArchivedCampaigns, api.getUserArchivedCampaigns);
const getCampaignByVersion = apiRequest.bind(null, campaignsActions.getCampaignByVersion, api.getCampaignByVersion);
const createCampaign = apiRequest.bind(null, campaignsActions.createCampaign, api.createCampaign);
const updateCampaign = apiRequest.bind(null, campaignsActions.updateCampaign, api.updateCampaign);
const getArchivedCampaign = apiRequest.bind(null, campaignsActions.getArchivedCampaign, api.getArchivedCampaign);
const getActiveCampaign = apiRequest.bind(null, campaignsActions.getActiveCampaign, api.getActiveCampaign);
const checkConsistency = apiRequest.bind(null, campaignsActions.checkConsistency, api.checkConsistency);
const getAllUserActiveCampaigns = apiRequest.bind(null, campaignsActions.getAllUserActiveCampaigns, api.getAllUserActiveCampaigns);

function* refreshCampaigns() {
  // const state = yield select();
  // const commentsUserId = get(state, 'Campaigns.commentsUserId');
  // const arg = {
  //   id: commentsUserId
  // }
  // yield apiRequest.apply(null, [campaignsActions.getCampaigns, api.getCampaigns, arg])
}

function* stopUserCampaign({ id }) {
  const { error } = yield api.stopUserCampaign(id);
  if (error) {
    yield put({
      type: campaignsActions.STOP_USER_CAMPAIGN.FAILURE,
      payload: error,
    });
    return;
  }
  yield put({
    type: campaignsActions.STOP_USER_CAMPAIGN.SUCCESS,
    payload: id,
  });
}

function* handleCreateCampaignSuccess(action) {
  yield toast.success('Gespeichert');
}

function* handleStartCampaignSuccess(action) {
  const state = yield select();

  const arg = {
    data: {
      userUuid: get(state, 'User.user.keycloakUuid', 0),
    },
  };

  yield apiRequest.apply(null, [campaignsActions.getUserCampaigns, api.getUserCampaigns, arg]);
  yield apiRequest.apply(null, [campaignsActions.getAllUserActiveCampaigns, api.getAllUserActiveCampaigns, { id: get(state, 'User.user.keycloakUuid', 0) }]);

  yield toast.success('Gespeichert');
}
/******************************************************************************/
/******************************* WATCHERS *************************************/
/******************************************************************************/

export function* watchGetCampaignsRequest() {
  yield takeLatest(campaignsActions.GET_CAMPAIGNS.REQUEST, getCampaigns);
}

export function* watchGetCampaignRequest() {
  yield takeLatest(campaignsActions.GET_CAMPAIGN.REQUEST, getCampaign);
}

export function* watchStartCampaignRequest() {
  yield takeEvery(campaignsActions.START_CAMPAIGN.REQUEST, startCampaign);
}

export function* watchStartCampaignAcceletateRequest() {
  yield takeEvery(campaignsActions.START_CAMPAIGN_ACCELERATE.REQUEST, startCampaignAccelerate);
}

export function* watchStartCampaignSuccess() {
  yield takeEvery(campaignsActions.START_CAMPAIGN.SUCCESS, handleStartCampaignSuccess);
}

export function* watchStartCampaignAcceletateSuccess() {
  yield takeEvery(campaignsActions.START_CAMPAIGN_ACCELERATE.SUCCESS, handleStartCampaignSuccess);
}

export function* watchGetCampaignByVersionRequest() {
  yield takeEvery(campaignsActions.GET_CAMPAIGN_BY_VERSION.REQUEST, getCampaignByVersion);
}

export function* watchCreateCampaignRequest() {
  yield takeEvery(campaignsActions.CREATE_CAMPAIGN.REQUEST, createCampaign);
}

export function* watchCreateCampaignSuccess() {
  yield takeEvery(campaignsActions.CREATE_CAMPAIGN.SUCCESS, handleCreateCampaignSuccess);
}

export function* watchUpdateCampaign() {
  yield takeEvery(campaignsActions.UPDATE_CAMPAIGN.REQUEST, updateCampaign);
}

export function* watchAddCampaignSuccess() {
  yield takeEvery(campaignsActions.CREATE_CAMPAIGN.SUCCESS, refreshCampaigns);
}

export function* watchUpdateCampaignSuccess() {
  yield takeEvery(campaignsActions.UPDATE_CAMPAIGN.SUCCESS, handleCreateCampaignSuccess);
}

export function* watchGetUserCampaignsRequest() {
  yield takeLatest(campaignsActions.GET_USER_CAMPAIGNS.REQUEST, getUserCampaigns);
}

export function* watchGetUserArchivedCampaignsRequest() {
  yield takeLatest(campaignsActions.GET_USER_ARCHIVED_CAMPAIGNS.REQUEST, getUserArchivedCampaigns);
}

export function* watchGetArchivedCampaignRequest() {
  yield takeLatest(campaignsActions.GET_ARCHIVED_CAMPAIGN.REQUEST, getArchivedCampaign);
}

export function* watchGetActiveCampaignRequest() {
  yield takeLatest(campaignsActions.GET_ACTIVE_CAMPAIGN.REQUEST, getActiveCampaign);
}

export function* watchStopUserCampaignRequest() {
  yield takeEvery(campaignsActions.STOP_USER_CAMPAIGN.REQUEST, stopUserCampaign);
}

export function* watchCheckConsistencyRequest() {
  yield takeEvery(campaignsActions.CHECK_CONSISTENCY.REQUEST, checkConsistency);
}

export function* watchGetAllUserActiveCampaignsRequest() {
  yield takeLatest(campaignsActions.GET_All_USER_ACTIVE_CAMPAIGNS.REQUEST, getAllUserActiveCampaigns);
}
