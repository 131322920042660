import * as ActionTypes from 'actions/Cube';
import { CUBE_STATUS_ACTIVE } from 'pages/UserDetails/constants';

const initialState = {
  cubes: [],
  cube: {},
  healthAssistants: [],
  userCubes: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.RESET_CUBE_PROPS:
      return { ...initialState };
    case ActionTypes.GET_CUBES.SUCCESS:
      return {
        ...state,
        cubes: action.payload,
      };
    case ActionTypes.GET_CUBE.SUCCESS:
      return {
        ...state,
        cube: action.payload,
      };
    case ActionTypes.UPDATE_CUBE.SUCCESS:
      return {
        ...state,
        cube: action.payload,
      };
    case ActionTypes.GET_USER_CUBES.SUCCESS:
      return {
        ...state,
        userCubes: action.payload,
      };
    case ActionTypes.GET_HEALTH_ASSISTANTS.SUCCESS:
      return {
        ...state,
        healthAssistants: action.payload,
      };
    case ActionTypes.DELETE_CUBE.SUCCESS:
      return {
        ...state,
        cubes: state.cubes.filter((el) => el.id !== action.payload),
      };
    case ActionTypes.STOP_CUBE.SUCCESS:
      return {
        ...state,
        userCubes: state.userCubes.filter((el) => el.userCubeId !== action.payload),
      };
    case ActionTypes.START_USER_CUBE.SUCCESS:
      return {
        ...state,
        userCubes: state.userCubes.map((el) => (el.userCubeId !== action.payload.id ? el : { ...el, status: action.payload.status })),
      };
    default:
      return state;
  }
}
