import { createRequestTypes, action } from 'utils/actions';
import { REQUEST, SUCCESS, FAILURE } from 'utils/constants';

export const GET_AUTHORS = createRequestTypes('GET_AUTHORS');
export const CREATE_AUTHOR = createRequestTypes('CREATE_AUTHOR');
export const GET_AUTHOR = createRequestTypes('GET_AUTHOR');
export const UPDATE_AUTHOR = createRequestTypes('UPDATE_AUTHOR');
export const DELETE_AUTHOR = createRequestTypes('DELETE_AUTHOR');
export const GET_AUTHORS_NEWS = createRequestTypes('GET_AUTHORS_NEWS');

export const SET_AUTHORS_TABLE_FILTER = 'SET_AUTHORS_TABLE_FILTER';
export const SET_AUTHORS_TABLE_SORTING = 'SET_AUTHORS_TABLE_SORTING';
export const SET_AUTHORS_NEWS_TABLE_FILTER = 'SET_AUTHORS_NEWS_TABLE_FILTER';
export const SET_AUTHORS_NEWS_TABLE_SORTING = 'SET_AUTHORS_NEWS_TABLE_SORTING';

export const RESET_AUTHOR = 'RESET_AUTHOR';

export const getAuthors = {
  request: (data) => action(GET_AUTHORS[REQUEST], { data }),
  success: (payload) => action(GET_AUTHORS[SUCCESS], { payload }),
  failure: (payload) => action(GET_AUTHORS[FAILURE], { payload }),
};

export const createAuthor = {
  request: (data, options) => action(CREATE_AUTHOR[REQUEST], { data, options }),
  success: (payload) => action(CREATE_AUTHOR[SUCCESS], { payload }),
  failure: (payload) => action(CREATE_AUTHOR[FAILURE], { payload }),
};

export const getAuthor = {
  request: (id) => action(GET_AUTHOR[REQUEST], { id }),
  success: (payload) => action(GET_AUTHOR[SUCCESS], { payload }),
  failure: (payload) => action(GET_AUTHOR[FAILURE], { payload }),
};

export const updateAuthor = {
  request: (id, data, options) => action(UPDATE_AUTHOR[REQUEST], { id, data, options }),
  success: (payload) => action(UPDATE_AUTHOR[SUCCESS], { payload }),
  failure: (payload) => action(UPDATE_AUTHOR[FAILURE], { payload }),
};

export const deleteAuthor = {
  request: (id) => action(DELETE_AUTHOR[REQUEST], { id }),
  success: (payload) => action(DELETE_AUTHOR[SUCCESS], { payload }),
  failure: (payload) => action(DELETE_AUTHOR[FAILURE], { payload }),
};

export const getAuthorsNews = {
  request: (data) => action(GET_AUTHORS_NEWS[REQUEST], { data }),
  success: (payload) => action(GET_AUTHORS_NEWS[SUCCESS], { payload }),
  failure: (payload) => action(GET_AUTHORS_NEWS[FAILURE], { payload }),
};

export const setAuthorsTableFilter = (payload) => ({
  type: SET_AUTHORS_TABLE_FILTER,
  payload,
});

export const setAuthorsTableSorting = (payload) => ({
  type: SET_AUTHORS_TABLE_SORTING,
  payload,
});

export const setAuthorsNewsTableSorting = (payload) => ({
  type: SET_AUTHORS_NEWS_TABLE_SORTING,
  payload,
});

export const setAuthorsNewsTableFilter = (payload) => ({
  type: SET_AUTHORS_NEWS_TABLE_FILTER,
  payload,
});

export const resetAuthor = () => ({
  type: RESET_AUTHOR,
});
