export const NavItems = [
  {
    label: 'Arbeitskorb',
    to: '/workflow',
    icon: 'pe-7s-news-paper',
    disableForRoles: ['cube-manager-viewer'],
  },
  {
    label: 'Nachrichten',
    disableForRoles: ['reviewer', 'cube-manager-viewer'],
    icon: 'pe-7s-albums',
    to: '/news',
    type: 'news',
    content: [
      {
        icon: 'pe-7s-news-paper',
        label: 'Nachrichten',
        to: '/news',
        type: 'news',
      },
      {
        icon: 'pe-7s-ribbon',
        label: 'Kategorien',
        to: '/categories',
        type: 'category',
        disableForRoles: ['editor', 'reviewer', 'approver', 'cube-manager-viewer'],
      },
    ],
  },
  {
    label: 'Prozesse',
    to: '/campaigns',
    type: 'campaign',
    icon: 'pe-7s-news-paper',
    disableForRoles: ['editor', 'reviewer', 'approver', 'cube-manager-viewer'],
  },
  {
    label: 'Kundenmanagement',
    to: '/users',
    type: 'user',
    icon: 'pe-7s-user',
    disableForRoles: ['editor', 'reviewer', 'approver', 'cube-manager-viewer'],
  },
  {
    label: 'Produkte',
    to: '/products',
    type: 'product',
    icon: 'pe-7s-shopbag',
    disableForRoles: ['editor', 'reviewer', 'approver', 'cube-manager-viewer'],
  },
  {
    label: 'Surveys',
    to: '/surveys',
    type: 'survey',
    icon: 'pe-7s-phone',
    disableForRoles: ['editor', 'reviewer', 'approver', 'cube-manager-viewer'],
  },
  {
    label: 'Tags',
    to: '/tags',
    type: 'tag',
    icon: 'pe-7s-ribbon',
    disableForRoles: ['editor', 'reviewer', 'approver', 'cube-manager-viewer'],
  },
  {
    label: 'Attribute',
    to: '/attributes',
    type: 'attribute',
    icon: 'pe-7s-users',
    disableForRoles: ['editor', 'reviewer', 'approver', 'cube-manager-viewer'],
  },
  {
    label: 'Charts',
    to: '/charts',
    type: 'chart',
    icon: 'pe-7s-graph2',
    disableForRoles: ['editor', 'reviewer', 'approver', 'cube-manager-viewer'],
  },
  {
    label: 'Termin Template',
    to: '/appointments',
    type: 'appointment',
    icon: 'pe-7s-date',
    disableForRoles: ['editor', 'reviewer', 'approver', 'tenant-admin', 'cube-manager-viewer'],
  },
  {
    label: 'Cubes',
    to: '/cubes',
    type: 'cube',
    icon: 'pe-7s-box2',
    disableForRoles: ['editor', 'reviewer', 'approver', 'tenant-admin'],
  },
  {
    label: '8 Elements Health Profile',
    to: '/health-profiles',
    type: 'health-profiles',
    icon: 'pe-7s-id',
    disableForRoles: ['editor', 'reviewer', 'approver', 'tenant-admin', 'cube-manager-viewer'],
  },
  {
    label: 'Health Assistants',
    to: '/health-assistants',
    type: 'health-assistants',
    icon: 'pe-7s-bandaid',
    disableForRoles: ['editor', 'reviewer', 'approver', 'tenant-admin', 'cube-manager-viewer'],
  },
  {
    label: 'Autor',
    to: '/authors',
    type: 'author',
    icon: 'pe-7s-pen',
    disableForRoles: ['cube-manager-viewer'],
  },
  {
    label: 'Mandanten',
    to: '/tenants',
    type: 'tenant',
    icon: 'pe-7s-users',
    disableForRoles: ['editor', 'reviewer', 'approver', 'tenant-admin', 'cube-manager-viewer'],
  },
  {
    label: 'Filter',
    to: '/filters',
    type: 'filter',
    icon: 'pe-7s-filter',
    disableForRoles: ['cube-manager-viewer'],
  },
  {
    label: 'Cube-Gruppen',
    to: '/cube-groups',
    type: 'cube-group',
    icon: 'pe-7s-albums',
    disableForRoles: ['cube-manager-viewer'],
  },
  {
    label: 'Activity',
    to: '/activity',
    type: 'activity',
    icon: 'pe-7s-monitor',
    disableForRoles: ['cube-manager-viewer'],
  },
  {
    label: 'Codes',
    to: '/codes',
    type: 'codes',
    icon: 'pe-7s-ticket',
    disableForRoles: [],
  },
];

const getNav = (userRole) => {
  return NavItems.filter((el) => {
    return !(el.disableForRoles && el.disableForRoles.indexOf(userRole) !== -1);
  });
};

export const navItemsByRole = {
  admin: getNav('admin'),
  'tenant-admin': getNav('tenant-admin'),
  reviewer: getNav('reviewer'),
  editor: getNav('editor'),
  approver: getNav('approver'),
  'cube-manager-viewer': getNav('cube-manager-viewer'),
};
