import { takeEvery, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import * as tenantsActions from 'actions/Tenants';
import api from '../utils/api';
import { apiRequest } from './index';
import { get } from 'lodash';
import history from 'history/browser';

const getTenants = apiRequest.bind(null, tenantsActions.getTenants, api.getTenants);
const getTenant = apiRequest.bind(null, tenantsActions.getTenant, api.getTenant);
const createTenant = apiRequest.bind(null, tenantsActions.createTenant, api.createTenant);
const updateTenant = apiRequest.bind(null, tenantsActions.updateTenant, api.updateTenant);
const deleteTenant = apiRequest.bind(null, tenantsActions.deleteTenant, api.deleteTenant);

function* refreshTenants() {
  // const state = yield select();
  // const commentsUserId = get(state, 'Tenants.commentsUserId');
  // const arg = {
  //   id: commentsUserId
  // }
  // yield apiRequest.apply(null, [tenantsActions.getTenants, api.getTenants, arg])
}

function* handleCreateTenantSuccess(action) {
  if (get(action.payload, 'options') === 'redirect') {
    return yield history.push('/tenants');
  } else {
    yield history.push(`/edit-tenant/${action.payload.key}`);
    const arg = { id: action.payload.key };
    yield apiRequest.apply(null, [tenantsActions.getTenant, api.getTenant, arg]);

    yield toast.success('Gespeichert');
  }
}
/******************************************************************************/
/******************************* WATCHERS *************************************/
/******************************************************************************/

export function* watchGetTenantsRequest() {
  yield takeLatest(tenantsActions.GET_TENANTS.REQUEST, getTenants);
}

export function* watchGetTenantRequest() {
  yield takeLatest(tenantsActions.GET_TENANT.REQUEST, getTenant);
}

export function* watchCreateTenantRequest() {
  yield takeEvery(tenantsActions.CREATE_TENANT.REQUEST, createTenant);
}

export function* watchCreateTenantSuccess() {
  yield takeEvery(tenantsActions.CREATE_TENANT.SUCCESS, handleCreateTenantSuccess);
}

export function* watchUpdateTenant() {
  yield takeEvery(tenantsActions.UPDATE_TENANT.REQUEST, updateTenant);
}

export function* watchDeleteTenant() {
  yield takeEvery(tenantsActions.CREATE_TENANT.REQUEST, deleteTenant);
}

export function* watchAddTenantSuccess() {
  yield takeEvery(tenantsActions.CREATE_TENANT.SUCCESS, refreshTenants);
}

export function* watchUpdateTenantSuccess() {
  yield takeEvery(tenantsActions.UPDATE_TENANT.SUCCESS, handleCreateTenantSuccess);
}

export function* watchDeleteTenantSuccess() {
  yield takeEvery(tenantsActions.DELETE_TENANT.SUCCESS, refreshTenants);
}
