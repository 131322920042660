export const baseUrl = process.env.REACT_APP_SURVEY_SERVICE_URL;
export const userServiceUrl = process.env.REACT_APP_USER_SERVICE_URL;
export const surveyServiceUrl = process.env.REACT_APP_SURVEY_SERVICE_URL;
export const calendarServiceUrl = process.env.REACT_APP_CALENDAR_SERVICE_URL;
export const calendarICalServiceUrl = process.env.REACT_APP_CALENDAR_ICAL_SERVICE_URL;
export const medicineServiceUrl = process.env.REACT_APP_MEDICINE_SERVICE_URL;
export const campaignServiceUrl = process.env.REACT_APP_CAMPAIGN_SERVICE_URL;
export const medicineServiceVersion = process.env.REACT_APP_MEDICINE_SERVICE_VERSION;
export const keycloakUrl = process.env.REACT_APP_KEYCLOAK_URL;
export const dmsUrl = process.env.REACT_APP_DMS_SERVICE_URL;
export const realm = process.env.REACT_APP_REALM;
export const clientId = process.env.REACT_APP_CLIENT_ID;
export const dmsApiVersion = process.env.REACT_APP_DMS_VERSION;
export const cubeServiceUrl = process.env.REACT_APP_CUBE_SERVICE_URL;
export const healthAssistantsUrl = process.env.REACT_APP_HEALTH_ASSISTANTS_URL;
export const healthAssistantsVersion = process.env.REACT_APP_HEALTH_ASSISTANTS_VERSION;
export const paymentServiceURL = process.env.REACT_APP_PAYMENT_SERVICE_URL;
