import { createRequestTypes, action } from '../utils/actions';
import { REQUEST, SUCCESS, FAILURE } from '../utils/constants';

export const GET_COMMENTS = createRequestTypes('GET_COMMENTS');
export const CREATE_COMMENT = createRequestTypes('CREATE_COMMENT');
export const UPDATE_COMMENT = createRequestTypes('UPDATE_COMMENT');
export const DELETE_COMMENT = createRequestTypes('DELETE_COMMENT');
export const SET_COMMENT_USER_ID = 'SET_COMMENT_USER_ID';

export const getComments = {
  request: (id) => action(GET_COMMENTS[REQUEST], {id}),
  success: (payload) => action(GET_COMMENTS[SUCCESS], {payload}),
  failure: (payload) => action(GET_COMMENTS[FAILURE], {payload})
}

export const createComment = {
  request: (id, data) => action(CREATE_COMMENT[REQUEST], {id, data}),
  success: (payload) => action(CREATE_COMMENT[SUCCESS], {payload}),
  failure: (payload) => action(CREATE_COMMENT[FAILURE], {payload})
}

export const updateComment = {
  request: (id, data) => action(UPDATE_COMMENT[REQUEST], {id, data}),
  success: (payload) => action(UPDATE_COMMENT[SUCCESS], {payload}),
  failure: (payload) => action(UPDATE_COMMENT[FAILURE], {payload})
}

export const deleteComment = {
  request: (id) => action(DELETE_COMMENT[REQUEST], {id}),
  success: (payload) => action(DELETE_COMMENT[SUCCESS], {payload}),
  failure: (payload) => action(DELETE_COMMENT[FAILURE], {payload})
}

export const setCommentUserId = (payload) => action(SET_COMMENT_USER_ID, {payload})
