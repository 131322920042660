import * as ActionTypes from 'actions/CalendarICal';

const initialState = {
  entries: [],
  entry: {},
  userGroups: [],
  generatedEntries: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.GET_CALENDAR_ICAL_ENTRIES.SUCCESS: {
      console.log('get calendar ical entries', action.payload);
      return {
        ...state,
        entries: action.payload,
      };
    }
    case ActionTypes.SET_ICAL_ENTRIES:
      console.log('set Ical entries', action.payload);
      return {
        ...state,
        generatedEntries: action.payload,
      };
    case ActionTypes.SET_CALENDAR_ICAL_DATE_RANGE:
      return {
        ...state,
        calendarICalDateRange: action.payload,
      };
    default:
      return state;
  }
}
