import * as ActionTypes from 'actions/Categories';

const initialState = {
  categories: [],
  tableFilter: '',
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.RESET_CATEGORY:
      return { ...initialState };
    case ActionTypes.GET_CATEGORY.SUCCESS:
      return {
        ...state,
        category: action.payload,
      };
    case ActionTypes.GET_CATEGORIES.SUCCESS:
      return {
        ...state,
        categories: action.payload,
      };
    case ActionTypes.SET_CATEGORIES_TABLE_FILTER:
      return {
        ...state,
        tableFilter: action.payload,
      };
    default:
      return state;
  }
}
