import * as ActionTypes from 'actions/Appointments';

const initialState = {
  appointments: [],
  appointment: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.RESET_APPOINTMENT:
      return { ...initialState };
    case ActionTypes.GET_APPOINTMENTS.SUCCESS:
      return {
        ...state,
        appointments: action.payload,
      };
    case ActionTypes.GET_APPOINTMENT.SUCCESS:
      return {
        ...state,
        appointment: action.payload,
      };
    case ActionTypes.UPDATE_APPOINTMENT.SUCCESS:
      return {
        ...state,
        appointment: action.payload,
      };
    case ActionTypes.DELETE_APPOINTMENT.SUCCESS:
      return {
        ...state,
        appointments: state.appointments.filter((el) => el.id !== action.payload),
      };
    default:
      return state;
  }
}
