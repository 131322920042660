import * as ActionTypes from 'actions/CubeGroups';
import { get } from 'lodash';

const initialState = {
  cubeGroups: [],
  allCubeGroups: [],
  cubeGroup: {},
  cubeGroupsTableFilter: '',
  cubeGroupsTableSorting: [],
  listPage: 0,
  listPages: 0,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.RESET_CUBE_GROUP:
      return { ...initialState };
    case ActionTypes.GET_CUBE_GROUPS.SUCCESS:
      return {
        ...state,
        cubeGroups: get(action, 'payload.content', []),
        listPage: get(action, 'payload.currentPage', 0),
        listPages: get(action, 'payload.totalPages', 1),
      };
    case ActionTypes.GET_CUBE_GROUP.SUCCESS:
      return {
        ...state,
        cubeGroup: action.payload,
      };
    case ActionTypes.GET_ALL_CUBE_GROUPS.SUCCESS:
      return {
        ...state,
        allCubeGroups: action.payload,
      };
    case ActionTypes.UPDATE_CUBE_GROUP.SUCCESS:
      return {
        ...state,
        cubeGroup: action.payload,
      };
    case ActionTypes.DELETE_CUBE_GROUP.SUCCESS:
      return {
        ...state,
        cubeGroups: state.cubeGroups.filter((el) => el.id !== action.payload),
      };
    case ActionTypes.SET_CUBE_GROUPS_TABLE_FILTER:
      return { ...state, cubeGroupsTableFilter: action.payload };
    case ActionTypes.SET_CUBE_GROUPS_TABLE_SORTING:
      return { ...state, cubeGroupsTableSorting: action.payload };
    default:
      return state;
  }
}
