import * as ActionTypes from 'actions/Tags';

const initialState = {
  tags: [],
  tableFilter: '',
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.RESET_TAG:
      return { ...initialState };
    case ActionTypes.GET_TAG.SUCCESS:
      return {
        ...state,
        tag: action.payload,
      };
    case ActionTypes.GET_TAGS.SUCCESS:
      return {
        ...state,
        tags: action.payload,
      };
    case ActionTypes.SET_TAGS_TABLE_FILTER:
      return {
        ...state,
        tableFilter: action.payload,
      };
    default:
      return state;
  }
}
