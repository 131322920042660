import { select, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { apiRequest } from './index';
import api from 'utils/api';
import * as calendarActions from 'actions/Calendar';
import { get, set } from 'lodash';
import moment from 'moment';

const getCalendarEntries = apiRequest.bind(null, calendarActions.getCalendarEntries, api.getCalendarEntries);
const createCalendarEntry = apiRequest.bind(null, calendarActions.createCalendarEntry, api.createCalendarEntry);
const updateCalendarEntry = apiRequest.bind(null, calendarActions.updateCalendarEntry, api.updateCalendarEntry);
const deleteCalendarEntry = apiRequest.bind(null, calendarActions.deleteCalendarEntry, api.deleteCalendarEntry);

function* handleEntryChangeSuccess() {
  const getUser = (state) => state.User.user;
  const getDateRange = (state) => {
    return state.Calendar.calendarDateRange;
  };

  const user = yield select(getUser);
  const calendarDateRange = yield select(getDateRange);

  const arg = {
    data: {
      userUuid: user.keycloakUuid,
      start: calendarDateRange.start,
      end: calendarDateRange.end,
    },
  };

  yield apiRequest.apply(null, [calendarActions.getCalendarEntries, api.getCalendarEntries, arg]);
}

function* generateCalendarEvents(action) {
  const { payload } = action;
  let reminders = [];
  let productIntakes = [];
  const generatedPeriod = moment().add(8, 'months');
  payload.forEach((el) => {
    if (!get(el, 'current.place')) set(el, 'current.place', '');
    let startDate = moment.unix(el.current.startDateTime);
    let endDate = moment.unix(el.current.endDateTime);

    if (el.calendarType === 'reminder' || el.calendarType === 'anchorAppointment') {
      switch (get(el, 'current.repetition', 'none')) {
        case 'none':
          reminders.push({
            id: get(el, 'id'),
            label: get(el, 'label'),
            current: { ...el.current, generatedDate: moment(startDate).format() },
            calendarType: get(el, 'calendarType'),
            isUserCreator: get(el, 'isUserCreator'),
          });
          break;
        case 'everyday':
          while (new Date(startDate) <= new Date(endDate) && new Date(startDate) <= new Date(generatedPeriod)) {
            reminders.push({
              id: get(el, 'id'),
              label: get(el, 'label'),
              current: { ...el.current, generatedDate: moment(startDate).format() },
              calendarType: get(el, 'calendarType'),
              isUserCreator: get(el, 'isUserCreator'),
            });
            startDate = moment(startDate).add(1, 'days');
          }
          break;
        case 'weekly':
          while (new Date(startDate) <= new Date(endDate) && new Date(startDate) <= new Date(generatedPeriod)) {
            reminders.push({
              id: get(el, 'id'),
              label: get(el, 'label'),
              current: { ...el.current, generatedDate: moment(startDate).format() },
              calendarType: get(el, 'calendarType'),
              isUserCreator: get(el, 'isUserCreator'),
            });
            startDate = moment(startDate).add(1, 'weeks');
          }
          break;
        case 'every2weeks':
          while (new Date(startDate) <= new Date(endDate) && new Date(startDate) <= new Date(generatedPeriod)) {
            reminders.push({
              id: get(el, 'id'),
              label: get(el, 'label'),
              current: { ...el.current, generatedDate: moment(startDate).format() },
              calendarType: get(el, 'calendarType'),
              isUserCreator: get(el, 'isUserCreator'),
            });
            startDate = moment(startDate).add(2, 'weeks');
          }
          break;
        case 'monthly':
          while (new Date(startDate) <= new Date(endDate) && new Date(startDate) <= new Date(generatedPeriod)) {
            reminders.push({
              id: get(el, 'id'),
              label: get(el, 'label'),
              current: { ...el.current, generatedDate: moment(startDate).format() },
              calendarType: get(el, 'calendarType'),
              isUserCreator: get(el, 'isUserCreator'),
            });
            startDate = moment(startDate).add(1, 'months');
          }
          break;
        case 'yearly':
          while (new Date(startDate) <= new Date(endDate) && new Date(startDate) <= new Date(generatedPeriod)) {
            reminders.push({
              id: get(el, 'id'),
              label: get(el, 'label'),
              current: { ...el.current, generatedDate: moment(startDate).format() },
              calendarType: get(el, 'calendarType'),
              isUserCreator: get(el, 'isUserCreator'),
            });
            startDate = moment(startDate).add(1, 'years');
          }
          break;
        default:
          reminders.push({
            id: get(el, 'id'),
            label: get(el, 'label'),
            current: { ...el.current, generatedDate: moment(startDate).format() },
            calendarType: get(el, 'calendarType'),
            isUserCreator: get(el, 'isUserCreator'),
          });
      }
    } else {
      get(el, 'current.frequency', []).forEach((freqItem) => {
        let startDate = moment.unix(el.current.startDateTime).set({ hours: 0, minutes: 1 });
        let endDate = moment.unix(el.current.endDateTime).set({ hours: 23, minutes: 59 });

        switch (freqItem) {
          case 'daily':
            while (new Date(startDate) <= new Date(endDate) && new Date(startDate) <= new Date(generatedPeriod)) {
              productIntakes.push({
                id: get(el, 'id'),
                label: get(el, 'label'),
                current: { ...el.current, generatedDate: moment(startDate).format() },
                calendarType: get(el, 'calendarType'),
                isUserCreator: get(el, 'isUserCreator'),
              });

              startDate = moment(startDate).add(1, 'day');
            }
            break;
          case 'MON':
            while (new Date(startDate) <= new Date(endDate) && new Date(startDate) <= new Date(generatedPeriod)) {
              if (new Date(startDate) <= new Date(startDate.day(1))) {
                productIntakes.push({
                  id: get(el, 'id'),
                  label: get(el, 'label'),
                  current: { ...el.current, generatedDate: moment(startDate).day(1).format() },
                  calendarType: get(el, 'calendarType'),
                  isUserCreator: get(el, 'isUserCreator'),
                });
              }
              startDate = moment(startDate).add(1, 'weeks');
            }
            break;
          case 'TUE':
            while (new Date(startDate) <= new Date(endDate) && new Date(startDate) <= new Date(generatedPeriod)) {
              if (new Date(startDate) <= new Date(startDate.day(2))) {
                productIntakes.push({
                  id: get(el, 'id'),
                  label: get(el, 'label'),
                  current: { ...el.current, generatedDate: moment(startDate).day(2).format() },
                  calendarType: get(el, 'calendarType'),
                  isUserCreator: get(el, 'isUserCreator'),
                });
              }
              startDate = moment(startDate).add(1, 'weeks');
            }
            break;
          case 'WED':
            while (new Date(startDate) <= new Date(endDate) && new Date(startDate) <= new Date(generatedPeriod)) {
              if (new Date(startDate) <= new Date(startDate.day(3))) {
                productIntakes.push({
                  id: get(el, 'id'),
                  label: get(el, 'label'),
                  current: { ...el.current, generatedDate: moment(startDate).day(3).format() },
                  calendarType: get(el, 'calendarType'),
                  isUserCreator: get(el, 'isUserCreator'),
                });
              }
              startDate = moment(startDate).add(1, 'weeks');
            }
            break;
          case 'THU':
            while (new Date(startDate) <= new Date(endDate)) {
              if (new Date(startDate) <= new Date(startDate.day(4))) {
                productIntakes.push({
                  id: get(el, 'id'),
                  label: get(el, 'label'),
                  current: { ...el.current, generatedDate: moment(startDate).day(4).format() },
                  calendarType: get(el, 'calendarType'),
                  isUserCreator: get(el, 'isUserCreator'),
                });
              }
              startDate = moment(startDate).add(1, 'weeks');
            }
            break;
          case 'FRI':
            while (new Date(startDate) <= new Date(endDate)) {
              if (new Date(startDate) <= new Date(startDate.day(5))) {
                productIntakes.push({
                  id: get(el, 'id'),
                  label: get(el, 'label'),
                  current: { ...el.current, generatedDate: moment(startDate).day(5).format() },
                  calendarType: get(el, 'calendarType'),
                  isUserCreator: get(el, 'isUserCreator'),
                });
              }
              startDate = moment(startDate).add(1, 'weeks');
            }
            break;
          case 'SAT':
            while (new Date(startDate) <= new Date(endDate)) {
              if (new Date(startDate) <= new Date(startDate.day(6))) {
                productIntakes.push({
                  id: get(el, 'id'),
                  label: get(el, 'label'),
                  current: { ...el.current, generatedDate: moment(startDate).day(6).format() },
                  calendarType: get(el, 'calendarType'),
                  isUserCreator: get(el, 'isUserCreator'),
                });
              }
              startDate = moment(startDate).add(1, 'weeks');
            }
            break;
          case 'SUN':
            while (new Date(startDate) <= new Date(endDate)) {
              if (new Date(startDate) <= new Date(startDate.day(0))) {
                productIntakes.push({
                  id: get(el, 'id'),
                  label: get(el, 'label'),
                  current: { ...el.current, generatedDate: moment(startDate).day(0).format() },
                  calendarType: get(el, 'calendarType'),
                  isUserCreator: get(el, 'isUserCreator'),
                });
              }
              startDate = moment(startDate).add(1, 'weeks');
            }
            break;
          default:
            productIntakes.push({
              id: get(el, 'id'),
              label: get(el, 'label'),
              current: { ...el.current },
              calendarType: get(el, 'calendarType'),
              isUserCreator: get(el, 'isUserCreator'),
            });
        }
      });
    }
  });

  yield put({
    type: calendarActions.SET_ENTRIES,
    payload: [...reminders, ...productIntakes],
  });
}

export function* watchGetCalendarEntriesRequest() {
  yield takeLatest(calendarActions.GET_CALENDAR_ENTRIES.REQUEST, getCalendarEntries);
}

export function* watchCreateCalendarEntryRequest() {
  yield takeEvery(calendarActions.CREATE_CALENDAR_ENTRY.REQUEST, createCalendarEntry);
}

export function* watchUpdateCalendarEntryRequest() {
  yield takeEvery(calendarActions.UPDATE_CALENDAR_ENTRY.REQUEST, updateCalendarEntry);
}

export function* watchDeleteCalendarEntryRequest() {
  yield takeEvery(calendarActions.DELETE_CALENDAR_ENTRY.REQUEST, deleteCalendarEntry);
}

export function* watchDeleteCalendarEntrySuccess() {
  yield takeEvery(calendarActions.DELETE_CALENDAR_ENTRY.SUCCESS, handleEntryChangeSuccess);
}

export function* watchUpdateCalendarEntrySuccess() {
  yield takeEvery(calendarActions.UPDATE_CALENDAR_ENTRY.SUCCESS, handleEntryChangeSuccess);
}

export function* watchCreateCalendarEntrySuccess() {
  yield takeEvery(calendarActions.CREATE_CALENDAR_ENTRY.SUCCESS, handleEntryChangeSuccess);
}

export function* watchGetCalendarEntriesSuccess() {
  yield takeLatest(calendarActions.GET_CALENDAR_ENTRIES.SUCCESS, generateCalendarEvents);
}
