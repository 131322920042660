import { createRequestTypes, action } from '../utils/actions';
import { REQUEST, SUCCESS, FAILURE } from '../utils/constants';

export const GET_CAMPAIGNS = createRequestTypes('GET_CAMPAIGNS');
export const GET_CAMPAIGN = createRequestTypes('GET_CAMPAIGN');
export const GET_CAMPAIGN_BY_VERSION = createRequestTypes('GET_CAMPAIGN_BY_VERSION');
export const START_CAMPAIGN = createRequestTypes('START_CAMPAIGN');
export const START_CAMPAIGN_ACCELERATE = createRequestTypes('START_CAMPAIGN_ACCELERATE');
export const CREATE_CAMPAIGN = createRequestTypes('CREATE_CAMPAIGN');
export const UPDATE_CAMPAIGN = createRequestTypes('UPDATE_CAMPAIGN');
export const GET_USER_CAMPAIGNS = createRequestTypes('GET_USER_CAMPAIGNS');
export const GET_All_USER_ACTIVE_CAMPAIGNS = createRequestTypes('GET_All_USER_ACTIVE_CAMPAIGNS');
export const GET_USER_ARCHIVED_CAMPAIGNS = createRequestTypes('GET_USER_ARCHIVED_CAMPAIGNS');
export const STOP_USER_CAMPAIGN = createRequestTypes('STOP_USER_CAMPAIGN');
export const GET_ACTIVE_CAMPAIGN = createRequestTypes('GET_ACTIVE_CAMPAIGN');
export const GET_ARCHIVED_CAMPAIGN = createRequestTypes('GET_ARCHIVED_CAMPAIGN');
export const CHECK_CONSISTENCY = createRequestTypes('CHECK_CONSISTENCY');
export const SET_CAMPAIGNS_TABLE_FILTER = 'SET_CAMPAIGNS_TABLE_FILTER';
export const SET_CAMPAIGNS_TABLE_SORTING = 'SET_CAMPAIGNS_TABLE_SORTING';

export const RESET_CAMPAIGN = 'RESET_CAMPAIGN';

export const getCampaigns = {
  request: (meta) => action(GET_CAMPAIGNS[REQUEST], { meta }),
  success: (payload) => action(GET_CAMPAIGNS[SUCCESS], { payload }),
  failure: (payload) => action(GET_CAMPAIGNS[FAILURE], { payload }),
};

export const getCampaign = {
  request: (id, meta) => action(GET_CAMPAIGN[REQUEST], { id, meta }),
  success: (payload) => action(GET_CAMPAIGN[SUCCESS], { payload }),
  failure: (payload) => action(GET_CAMPAIGN[FAILURE], { payload }),
};

export const getCampaignByVersion = {
  request: (data) => action(GET_CAMPAIGN_BY_VERSION[REQUEST], { data }),
  success: (payload) => action(GET_CAMPAIGN_BY_VERSION[SUCCESS], { payload }),
  failure: (payload) => action(GET_CAMPAIGN_BY_VERSION[FAILURE], { payload }),
};

export const createCampaign = {
  request: (data) => action(CREATE_CAMPAIGN[REQUEST], { data }),
  success: (payload) => action(CREATE_CAMPAIGN[SUCCESS], { payload }),
  failure: (payload) => action(CREATE_CAMPAIGN[FAILURE], { payload }),
};

export const updateCampaign = {
  request: (data) => action(UPDATE_CAMPAIGN[REQUEST], { data }),
  success: (payload) => action(UPDATE_CAMPAIGN[SUCCESS], { payload }),
  failure: (payload) => action(UPDATE_CAMPAIGN[FAILURE], { payload }),
};

export const startCampaign = {
  request: (data) => action(START_CAMPAIGN[REQUEST], { data }),
  success: (payload) => action(START_CAMPAIGN[SUCCESS], { payload }),
  failure: (payload) => action(START_CAMPAIGN[FAILURE], { payload }),
};

export const startCampaignAccelerate = {
  request: (data) => action(START_CAMPAIGN_ACCELERATE[REQUEST], { data }),
  success: (payload) => action(START_CAMPAIGN_ACCELERATE[SUCCESS], { payload }),
  failure: (payload) => action(START_CAMPAIGN_ACCELERATE[FAILURE], { payload }),
};

export const getUserCampaigns = {
  request: (data) => action(GET_USER_CAMPAIGNS[REQUEST], { data }),
  success: (payload) => action(GET_USER_CAMPAIGNS[SUCCESS], { payload }),
  failure: (payload) => action(GET_USER_CAMPAIGNS[FAILURE], { payload }),
};

export const getAllUserActiveCampaigns = {
  request: (data) => action(GET_All_USER_ACTIVE_CAMPAIGNS[REQUEST], { data }),
  success: (payload) => action(GET_All_USER_ACTIVE_CAMPAIGNS[SUCCESS], { payload }),
  failure: (payload) => action(GET_All_USER_ACTIVE_CAMPAIGNS[FAILURE], { payload }),
};

export const getUserArchivedCampaigns = {
  request: (data) => action(GET_USER_ARCHIVED_CAMPAIGNS[REQUEST], { data }),
  success: (payload) => action(GET_USER_ARCHIVED_CAMPAIGNS[SUCCESS], { payload }),
  failure: (payload) => action(GET_USER_ARCHIVED_CAMPAIGNS[FAILURE], { payload }),
};

export const stopUserCampaign = {
  request: (id) => action(STOP_USER_CAMPAIGN[REQUEST], { id }),
  success: (payload) => action(GET_USER_ARCHIVED_CAMPAIGNS[SUCCESS], { payload }),
  failure: (payload) => action(GET_USER_ARCHIVED_CAMPAIGNS[FAILURE], { payload }),
};

export const getArchivedCampaign = {
  request: (id) => action(GET_ARCHIVED_CAMPAIGN[REQUEST], { id }),
  success: (payload) => action(GET_ARCHIVED_CAMPAIGN[SUCCESS], { payload }),
  failure: (payload) => action(GET_ARCHIVED_CAMPAIGN[FAILURE], { payload }),
};

export const getActiveCampaign = {
  request: (id) => action(GET_ACTIVE_CAMPAIGN[REQUEST], { id }),
  success: (payload) => action(GET_ACTIVE_CAMPAIGN[SUCCESS], { payload }),
  failure: (payload) => action(GET_ACTIVE_CAMPAIGN[FAILURE], { payload }),
};

export const checkConsistency = {
  request: (id) => action(CHECK_CONSISTENCY[REQUEST], { id }),
  success: (payload) => action(CHECK_CONSISTENCY[SUCCESS], { payload }),
  failure: (payload) => action(CHECK_CONSISTENCY[FAILURE], { payload }),
};

export const setCampaignsTableFilter = (payload) => ({
  type: SET_CAMPAIGNS_TABLE_FILTER,
  payload,
});

export const setCampaignsTableSorting = (payload) => ({
  type: SET_CAMPAIGNS_TABLE_SORTING,
  payload,
});

export const resetCampaign = () => ({
  type: RESET_CAMPAIGN,
});
