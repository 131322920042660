import axios from 'axios';
import { get } from 'lodash';

import { calendarICalServiceUrl } from 'config/environment';

const getCalendarICalEntries = (data) => {
  let requestUrl = `${calendarICalServiceUrl}/api/calendar/${data.userUuid}/?dateFilter.from=${data.from}&dateFilter.to=${data.to}&typeFilter=EVENT&expand=true`;
  return axios
    .get(requestUrl)
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: get(err, 'response.data.message', 'Die Kalendereinträge konnten nicht geladen werden.') || 'Die Kalendereinträge konnten nicht geladen werden.',
      },
    }));
};

export default {
  getCalendarICalEntries,
};
