import { takeEvery, put, select, takeLatest } from 'redux-saga/effects';
import { apiRequest, secureApiRequest } from './index';
import api from 'utils/api';
import * as newsActions from 'actions/News';
import history from 'history/browser';
import { toast } from 'react-toastify';
import { get } from 'lodash';
import downloadFile from '../utils/downloadFile';
import { workflowStatuses } from '../utils/constants';

const getNewsList = secureApiRequest.bind(null, newsActions.getNewsList, api.getNewsList);

const getNewsListAll = secureApiRequest.bind(null, newsActions.getNewsListAll, api.getNewsListAll);

const getNews = secureApiRequest.bind(null, newsActions.getNews, api.getNews);
const getNewsByVersion = secureApiRequest.bind(null, newsActions.getNewsByVersion, api.getNewsByVersion);
const getNewsTemplates = secureApiRequest.bind(null, newsActions.getNewsTemplates, api.getNewsTemplates);
const copyNews = secureApiRequest.bind(null, newsActions.copyNews, api.copyNews);
const sendDirectPush = secureApiRequest.bind(null, newsActions.sendDirectPush, api.sendDirectPush);
const sendDirectPushTemplate = secureApiRequest.bind(null, newsActions.sendDirectPushTemplate, api.sendDirectPushTemplate);
const getUserFeed = secureApiRequest.bind(null, newsActions.getUserFeed, api.getUserFeed);
const getUserWorkflow = secureApiRequest.bind(null, newsActions.getUserWorkflow, api.getUserWorkflow);
const approveNews = secureApiRequest.bind(null, newsActions.approveNews, api.approveNews);
const rejectNews = secureApiRequest.bind(null, newsActions.rejectNews, api.rejectNews);
const pickupNews = secureApiRequest.bind(null, newsActions.pickupNews, api.pickupNews);
const updateLatestVersion = secureApiRequest.bind(null, newsActions.updateLatestVersion, api.updateLatestVersion);
const createNewVersion = secureApiRequest.bind(null, newsActions.createNewVersion, api.createNewVersion);
const getWorkflowList = secureApiRequest.bind(null, newsActions.getWorkflowList, api.getWorkflowList);
const getWorkflowHistory = secureApiRequest.bind(null, newsActions.getWorkflowHistory, api.getWorkflowHistory);
const setNewsCategories = secureApiRequest.bind(null, newsActions.setNewsCategories, api.setNewsCategories);
const setNewsTags = secureApiRequest.bind(null, newsActions.setNewsTags, api.setNewsTags);
const addGroupsToNews = secureApiRequest.bind(null, newsActions.addGroupsToNews, api.addGroupsToNews);
const setPublicationDate = secureApiRequest.bind(null, newsActions.setPublicationDate, api.setPublicationDate);
const cancelPublication = secureApiRequest.bind(null, newsActions.cancelPublication, api.cancelPublication);
const updateNewsPartially = secureApiRequest.bind(null, newsActions.updateNewsPartially, api.updateNewsPartially);

const deleteNewsList = secureApiRequest.bind(null, newsActions.deleteNewsList, api.deleteNewsList);

const createNews = secureApiRequest.bind(null, newsActions.createNews, api.createNews);

const updateNews = secureApiRequest.bind(null, newsActions.updateNews, api.updateNews);

const downloadPdfRequest = apiRequest.bind(null, newsActions.downloadPdf, api.downloadPdfNews);

const deleteUserFeedItems = secureApiRequest.bind(null, newsActions.deleteUserFeedItems, api.deleteUserFeedItems)

function downloadPdf({ payload }) {
  downloadFile(payload, 'pdf', 'News PDF.pdf');
}

function* refreshNewsList() {
  // yield put({ type: newsActions.GET_WORKFLOW_LIST.REQUEST });
}

function* refreshWorkflowList() {
  const getNewsId = (state) => get(state, 'News.news.id');
  const newsId = yield select(getNewsId);

  //TODO: optimize
  if (newsId) {
    const arg = { id: newsId };

    yield apiRequest.apply(null, [newsActions.getNews, api.getNews, arg]);
    yield apiRequest.apply(null, [newsActions.getWorkflowHistory, api.getWorkflowHistory, arg]);
  }
  yield put({ type: newsActions.GET_WORKFLOW_LIST.REQUEST });
}

function* handleCopyNewsSuccess(action) {
  // if (history.location.pathname.includes('-news')) {
  //   history.push(`/view-news/${action.payload.id}`);
  //   const arg = { id: action.payload.id };
  //   yield apiRequest.apply(null, [newsActions.getNews, api.getNews, arg]);
  //   return;
  // }
  //after copy open in edit mode
  // history.push(`/edit-news/${action.payload.id}`);
  const arg = { id: action.payload.id };
  yield apiRequest.apply(null, [newsActions.getNews, api.getNews, arg]);
}

function* refreshNews() {
  const getNewsId = (state) => state.News.news.id;
  const newsId = yield select(getNewsId);

  const arg = { id: newsId };

  yield apiRequest.apply(null, [newsActions.getNews, api.getNews, arg]);
  yield apiRequest.apply(null, [newsActions.getWorkflowHistory, api.getWorkflowHistory, arg]);
}

function* deleteNews({ id }) {
  const { error } = yield api.deleteNews(id);
  if (error) {
    yield put({ type: newsActions.DELETE_NEWS.FAILURE, payload: error });
  } else {
    yield put({ type: newsActions.DELETE_NEWS.SUCCESS, payload: id });
  }
}

function* approveNewsAllSteps({ id, meta: { isPickUp, status }, callback }) {
  const statusToStepsDependencyObj = {
    [workflowStatuses[0].value]: 3,
    [workflowStatuses[1].value]: 2,
    [workflowStatuses[2].value]: 1,
  };

  function* oneApproveStepWithConditionalPickUp(isPickUp) {
    if (isPickUp) yield api.pickupNews({ newsId: id, forceAssign: true });
    return yield api.approveNews(id);
  }

  let isPickUpCheck = isPickUp;
  for (let i = statusToStepsDependencyObj[status]; i > 0; i--) {
    yield oneApproveStepWithConditionalPickUp(isPickUpCheck);
    isPickUpCheck = true;
  }

  // yield history.push('/news');
  yield callback();
}

function* createNewsSuccess({ payload }) {
  // if (get(payload, 'redirect')) {
  //   return yield history.push(payload.redirect);
  // } else {
  //   if (payload.callback) payload.callback();
  //   yield history.push(`/edit-news/${payload.id}`);

  yield toast.success('Gespeichert');
  //   const getNewsId = (state) => get(state, 'News.news.id');
  //   const newsId = yield select(getNewsId);

  //   if (newsId) {
  //     const arg = { id: newsId };

  //     yield apiRequest.apply(null, [newsActions.getNews, api.getNews, arg]);
  //   }
  // }
}

function* createNewsVersionSuccess(action) {
  if (get(action.payload, 'options') === 'redirect') {
    return yield history.push('/workflow');
  } else {
    const getNewsId = (state) => state.News.news.id;
    const newsId = yield select(getNewsId);

    const arg = { id: newsId };

    yield apiRequest.apply(null, [newsActions.getNews, api.getNews, arg]);
    yield apiRequest.apply(null, [newsActions.getWorkflowHistory, api.getWorkflowHistory, arg]);
  }
}

function* handleSendDirectPushSuccess() {
  yield toast.success('Die Nachricht wurde gesendet.');
}

function* handleUpdateSuccess() {
  yield toast.success('Erfolgleich geändert');
}

/******************************************************************************/
/******************************* WATCHERS *************************************/

/******************************************************************************/

export function* watchGetNewsListRequest() {
  yield takeLatest(newsActions.GET_NEWS_LIST.REQUEST, getNewsList);
}

export function* watchGetNewsListAllRequest() {
  yield takeLatest(newsActions.GET_NEWS_LIST_ALL.REQUEST, getNewsListAll);
}

export function* watchGetNewsTemplatesRequest() {
  yield takeLatest(newsActions.GET_NEWS_TEMPLATES.REQUEST, getNewsTemplates);
}

export function* watchGetNewsRequest() {
  yield takeLatest(newsActions.GET_NEWS.REQUEST, getNews);
}

export function* watchCreateNewsRequest() {
  yield takeEvery(newsActions.CREATE_NEWS.REQUEST, createNews);
}

export function* watchCreateNewsSuccess() {
  yield takeEvery(newsActions.CREATE_NEWS.SUCCESS, createNewsSuccess);
}

export function* watchUpdateNewsRequest() {
  yield takeEvery(newsActions.UPDATE_NEWS.REQUEST, updateNews);
}

export function* watchUpdateNewsSuccess() {
  yield takeEvery(newsActions.UPDATE_NEWS.SUCCESS, createNewsSuccess);
}

export function* watchDeleteNewsRequest() {
  yield takeEvery(newsActions.DELETE_NEWS.REQUEST, deleteNews);
}

export function* watchGetNewsByVersionRequest() {
  yield takeEvery(newsActions.GET_NEWS_BY_VERSION.REQUEST, getNewsByVersion);
}

export function* watchDeleteNewsSuccess() {
  yield takeLatest(newsActions.DELETE_NEWS.SUCCESS, refreshNewsList);
}

export function* watchDeleteNewsListRequest() {
  yield takeEvery(newsActions.DELETE_NEWS_LIST.REQUEST, deleteNewsList);
}

export function* watchDeleteNewsListSuccess() {
  yield takeLatest(newsActions.DELETE_NEWS_LIST.SUCCESS, refreshNewsList);
}

export function* watchCopyNewsRequest() {
  yield takeEvery(newsActions.COPY_NEWS.REQUEST, copyNews);
}

export function* watchSendDirectPushRequest() {
  yield takeEvery(newsActions.SEND_DIRECT_PUSH.REQUEST, sendDirectPush);
}

export function* watchSendDirectPushSuccess() {
  yield takeEvery(newsActions.SEND_DIRECT_PUSH.SUCCESS, handleSendDirectPushSuccess);
}

export function* watchCopyNewsSuccess() {
  yield takeEvery(newsActions.COPY_NEWS.SUCCESS, handleCopyNewsSuccess);
}

export function* watchGetUserFeedRequest() {
  yield takeEvery(newsActions.GET_USER_FEED.REQUEST, getUserFeed);
}

export function* watchGetUserWorkflowRequest() {
  yield takeEvery(newsActions.GET_USER_WORKFLOW.REQUEST, getUserWorkflow);
}

export function* watchApproveNewsRequest() {
  yield takeEvery(newsActions.APPROVE_NEWS.REQUEST, approveNews);
}

export function* watchApproveNewsAllStepsRequest() {
  yield takeEvery(newsActions.APPROVE_NEWS_ALL_STEPS.REQUEST, approveNewsAllSteps);
}

export function* watchApproveNewsSuccess() {
  yield takeLatest(newsActions.APPROVE_NEWS.SUCCESS, refreshNews);
}

export function* watchRejectNewsSuccess() {
  yield takeLatest(newsActions.REJECT_NEWS.SUCCESS, refreshNews);
}

export function* watchRejectNewsRequest() {
  yield takeLatest(newsActions.REJECT_NEWS.REQUEST, rejectNews);
}

export function* watchPickupNewsRequest() {
  yield takeEvery(newsActions.PICKUP_NEWS.REQUEST, pickupNews);
}

export function* watchPickupNewsSuccess() {
  yield takeEvery(newsActions.PICKUP_NEWS.SUCCESS, refreshWorkflowList);
}

export function* watchUpdateNewsPartiallyRequest() {
  yield takeEvery(newsActions.UPDATE_NEWS_PARTIALLY.REQUEST, updateNewsPartially);
}

export function* watchUpdateNewsPartiallySuccess() {
  yield takeEvery(newsActions.UPDATE_NEWS_PARTIALLY.SUCCESS, handleUpdateSuccess);
}

export function* watchUpdateLatestVersionRequest() {
  yield takeEvery(newsActions.UPDATE_LATEST_VERSION.REQUEST, updateLatestVersion);
}

export function* watchUpdateLatestVersionSuccess() {
  yield takeEvery(newsActions.UPDATE_LATEST_VERSION.SUCCESS, createNewsSuccess);
}

export function* watchGetWorkflowListRequest() {
  yield takeLatest(newsActions.GET_WORKFLOW_LIST.REQUEST, getWorkflowList);
}

export function* watchCreateNewVersionRequest() {
  yield takeEvery(newsActions.CREATE_NEW_VERSION.REQUEST, createNewVersion);
}

export function* watchCreateNewVersionSuccess() {
  yield takeEvery(newsActions.CREATE_NEW_VERSION.SUCCESS, createNewsVersionSuccess);
}

export function* watchGetWorkflowHistoryRequest() {
  yield takeLatest(newsActions.GET_WORKFLOW_HISTORY.REQUEST, getWorkflowHistory);
}

export function* watchSetNewsCategoriesRequest() {
  yield takeEvery(newsActions.SET_NEWS_CATEGORIES.REQUEST, setNewsCategories);
}

export function* watchSetNewsCategoriesSuccess() {
  yield takeEvery(newsActions.SET_NEWS_CATEGORIES.SUCCESS, handleUpdateSuccess);
}

export function* watchSetNewsTagsRequest() {
  yield takeEvery(newsActions.SET_NEWS_TAGS.REQUEST, setNewsTags);
}

export function* watchSetNewsTagsSuccess() {
  yield takeEvery(newsActions.SET_NEWS_TAGS.SUCCESS, handleUpdateSuccess);
}

export function* watchAddGroupsToNewsRequest() {
  yield takeEvery(newsActions.ADD_GROUPS_TO_NEWS.REQUEST, addGroupsToNews);
}

export function* watchCancelPublicationRequest() {
  yield takeEvery(newsActions.CANCEL_PUBLICATION.REQUEST, cancelPublication);
}

export function* watchCancelPublicationSuccess() {
  yield takeEvery(newsActions.CANCEL_PUBLICATION.SUCCESS, handleUpdateSuccess);
}

export function* watchSetPublicationDateRequest() {
  yield takeEvery(newsActions.SET_PUBLICATION_DATE.REQUEST, setPublicationDate);
}

export function* watchSetPublicationDateSuccess() {
  yield takeEvery(newsActions.SET_PUBLICATION_DATE.SUCCESS, handleUpdateSuccess);
}

export function* watchAddGroupsToNewsSuccess() {
  yield takeEvery(newsActions.ADD_GROUPS_TO_NEWS.SUCCESS, handleUpdateSuccess);
}

export function* watchSendDirectPushTemplateRequest() {
  yield takeEvery(newsActions.SEND_DIRECT_PUSH_TEMPLATE.REQUEST, sendDirectPushTemplate);
}

export function* watchSendDirectPushTemplateSuccess() {
  yield takeEvery(newsActions.SEND_DIRECT_PUSH_TEMPLATE.SUCCESS, handleSendDirectPushSuccess);
}

export function* watchSendNewsIdsToDownloadPdfRequest() {
  yield takeEvery(newsActions.DOWNLOAD_PDF_NEWS.REQUEST, downloadPdfRequest);
}

export function* watchSendNewsIdsToDownloadPdfSuccess() {
  yield takeEvery(newsActions.DOWNLOAD_PDF_NEWS.SUCCESS, downloadPdf);
}

export function* watchDeleteUserFeedItemsRequest() {
  yield takeEvery(newsActions.DELETE_USER_FEED_ITEMS.REQUEST, deleteUserFeedItems);
}
