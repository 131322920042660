import * as ActionTypes from '../actions/Tenants';
import * as AuthActionTypes from '../actions/Auth';

const initialState = {
  tenants: [],
  tenant: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.RESET_TENANT:
    case AuthActionTypes.LOGOUT.SUCCESS:
      return { ...initialState };
    case ActionTypes.GET_TENANTS.SUCCESS:
      return { ...state, tenants: Object.values(action.payload) };
    case ActionTypes.GET_TENANT.SUCCESS:
      return { ...state, tenant: action.payload };
    case ActionTypes.SET_TENANTS_TABLE_FILTER:
      return { ...state, tableFilter: action.payload };
    case ActionTypes.SET_TENANTS_TABLE_SORTING:
      return { ...state, tableSorting: action.payload };
    default:
      return state;
  }
}
