import React from 'react';
import * as serviceWorker from './serviceWorker';

import { BrowserRouter } from 'react-router-dom';

import './assets/base.scss';
import configureStore from './config/configureStore';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.css';
import { Provider } from 'react-redux';
import Main from './layout/Main';
import rootSaga from './sagas';
import { createRoot } from 'react-dom/client';

const store = configureStore();
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

store.runSaga(rootSaga);

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <Main></Main>
    </BrowserRouter>
  </Provider>
);

serviceWorker.unregister();
