import { all, takeEvery, put, takeLatest } from 'redux-saga/effects';
import { apiRequest } from './index';
import ChartsApi from '../api/Charts';
import * as chartsActions from 'actions/Charts';
import history from 'history/browser';
import { toast } from 'react-toastify';

const getCharts = apiRequest.bind(null, chartsActions.getCharts, ChartsApi.getCharts);

const getChart = apiRequest.bind(null, chartsActions.getChart, ChartsApi.getChart);

const getChartByAttributeId = apiRequest.bind(null, chartsActions.getChartByAttributeId, ChartsApi.getChartByAttributeId);

function* updateChart({ id, data, options }) {
  const { response, error } = yield ChartsApi.updateChart(id, data);
  if (error) {
    return yield put({
      type: chartsActions.UPDATE_CHART.FAILURE,
      payload: response,
    });
  }
  yield put({
    type: chartsActions.UPDATE_CHART.SUCCESS,
    payload: response,
  });

  if (options === 'redirect') {
    yield history.push('/charts');
  }
}

function* deleteChart({ id }) {
  const { error } = yield ChartsApi.deleteChart(id);
  if (error) {
    yield put({ type: chartsActions.DELETE_CHART.FAILURE, payload: error });
  } else {
    yield put({ type: chartsActions.DELETE_CHART.SUCCESS, payload: id });
  }
}

function* createChart({ data, options }) {
  const { response, error } = yield ChartsApi.createChart(data);
  if (error) {
    return yield put({
      type: chartsActions.CREATE_CHART.FAILURE,
      payload: response,
    });
  }
  yield put({
    type: chartsActions.CREATE_CHART.SUCCESS,
    payload: response,
  });
}

const chartSaveHandler = () => {
  toast.success('Gespeichert');
};

/******************************************************************************/
/******************************* WATCHERS *************************************/
/******************************************************************************/

export function* rootWatcherChartsRequest() {
  yield all([
    takeLatest(chartsActions.GET_CHARTS.REQUEST, getCharts),
    takeLatest(chartsActions.GET_CHART.REQUEST, getChart),
    takeEvery(chartsActions.UPDATE_CHART.REQUEST, updateChart),
    takeEvery(chartsActions.UPDATE_CHART.SUCCESS, chartSaveHandler),
    takeEvery(chartsActions.CREATE_CHART.REQUEST, createChart),
    takeEvery(chartsActions.CREATE_CHART.SUCCESS, chartSaveHandler),
    takeEvery(chartsActions.DELETE_CHART.REQUEST, deleteChart),
    takeLatest(chartsActions.GET_CHART_BY_ATTRIBUTE_ID.REQUEST, getChartByAttributeId),
  ]);
}
