import * as ActionTypes from 'actions/Survey';

const initialState = {
  survey: null,
  surveys: [],
  selectedSurveys: {},
};
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.RESET_SURVEY:
      return { ...initialState };
    case ActionTypes.GET_SURVEYS.SUCCESS:
      return {
        ...state,
        surveys: action.payload,
      };
    case ActionTypes.GET_SURVEY.SUCCESS:
      return {
        ...state,
        survey: action.payload,
      };
    case ActionTypes.SET_SURVEYS_TABLE_FILTER:
      return {
        ...state,
        tableFilter: action.payload,
      };
    default:
      return state;
  }
}
