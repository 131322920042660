import * as ActionTypes from "actions/Documents";
import { get } from "lodash";

const initialState = {
  documents: {},
  document: null,
  challenges: null,
  publicChallenges: null,
  publicPages: [],
  publicWiki: null,
  simulationDocs: null,
  vitalMonitorDocs: null,
  targetChallengesDocs: null,
  wiki: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.GET_DOCUMENTS.SUCCESS:
      return {
        ...state,
        documents: action.payload,
        challenges: get(action.payload, "challenges"),
        publicChallenges: get(action.payload, "publicChallenges"),
        publicWiki: get(action.payload, "publicWiki"),
        simulationDocs: get(action.payload, "simulationDocs"),
        vitalMonitorDocs: get(action.payload, "vitalMonitorDocs"),
        targetChallengesDocs: get(action.payload, "targetChallenges"),
        publicPages: get(action.payload, "publicPages"),
        wiki: get(action.payload, "wiki"),
      };
    case ActionTypes.GET_DOCUMENT.SUCCESS:
    case ActionTypes.GET_DOCUMENT_BY_VERSION.SUCCESS:
      return {
        ...state,
        document: action.payload,
      };
    default:
      return state;
  }
}
