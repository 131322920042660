import { createRequestTypes, action } from '../utils/actions';
import { REQUEST, SUCCESS, FAILURE } from '../utils/constants';

export const GET_CALENDAR_ENTRIES = createRequestTypes('GET_CALENDAR_ENTRIES');
export const CREATE_CALENDAR_ENTRY = createRequestTypes('CREATE_CALENDAR_ENTRY');
export const UPDATE_CALENDAR_ENTRY = createRequestTypes('UPDATE_CALENDAR_ENTRY');
export const DELETE_CALENDAR_ENTRY = createRequestTypes('DELETE_CALENDAR_ENTRY');
export const SET_ENTRIES = 'SET_ENTRIES';
export const SET_CALENDAR_DATE_RANGE = 'SET_CALENDAR_DATE_RANGE';

export const getCalendarEntries = {
  request: (data) => action(GET_CALENDAR_ENTRIES[REQUEST], {data}),
  success: (payload) => action(GET_CALENDAR_ENTRIES[SUCCESS], {payload}),
  failure: (payload) => action(GET_CALENDAR_ENTRIES[FAILURE], {payload}),
};

export const createCalendarEntry = {
  request: (data) => action(CREATE_CALENDAR_ENTRY[REQUEST], {data}),
  success: (payload) => action(CREATE_CALENDAR_ENTRY[SUCCESS], {payload}),
  failure: (payload) => action(CREATE_CALENDAR_ENTRY[FAILURE], {payload}),
};

export const updateCalendarEntry = {
  request: (data) => action(UPDATE_CALENDAR_ENTRY[REQUEST], {data}),
  success: (payload) => action(UPDATE_CALENDAR_ENTRY[SUCCESS], {payload}),
  failure: (payload) => action(UPDATE_CALENDAR_ENTRY[FAILURE], {payload}),
};

export const deleteCalendarEntry = {
  request: (id) => action(DELETE_CALENDAR_ENTRY[REQUEST], {id}),
  success: (payload) => action(DELETE_CALENDAR_ENTRY[SUCCESS], {payload}),
  failure: (payload) => action(DELETE_CALENDAR_ENTRY[FAILURE], {payload}),
};

export const setEnties = (payload) => ({
  type: SET_ENTRIES,
  payload,
});

export const setCalendarDateRange = (payload) => {
  return ({
    type: SET_CALENDAR_DATE_RANGE,
    payload
  });
}
