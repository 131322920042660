import React from 'react';

import nav_hover from '../../assets/utils/images/nav_hover.svg';

const NavItem = ({ currentPath, path, title, icon, handlePress, link }) => {
  const navigateTo = () => {
    handlePress && handlePress(path);
  };

  const isActive = () => {
    return currentPath === `/${path}`;
  };

  return (
    // eslint-disable-next-line
    <a className="nav-i" href={link} target="_blank" onClick={navigateTo}>
      <div className="nav-i-iw">
        <img src={nav_hover} className="nav-i-hover" alt="Hover" />
        <img src={require(`../../assets/utils/images/${isActive() ? icon + '__active' : icon}.svg`)} className="nav-i-img" alt="Nav" />
      </div>
      <div className={`nav-i-title${isActive() ? '__active' : ''}`}>{title}</div>
    </a>
  );
};

export default NavItem;
