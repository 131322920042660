import { createRequestTypes, action } from '../utils/actions';
import { REQUEST, SUCCESS, FAILURE } from '../utils/constants';

export const GET_PRODUCT_LIST = createRequestTypes('GET_PRODUCT_LIST');
export const GET_FILTERED_PRODUCT_LIST = createRequestTypes('GET_FILTERED_PRODUCT_LIST');
export const GET_PRODUCT = createRequestTypes('GET_PRODUCT');
export const CREATE_PRODUCT = createRequestTypes('CREATE_PRODUCT');
export const UPDATE_PRODUCT = createRequestTypes('UPDATE_PRODUCT');
export const DELETE_PRODUCT = createRequestTypes('DELETE_PRODUCT');
export const GET_PRODUCT_CONSTANTS = createRequestTypes('GET_PRODUCT_CONSTANTS');
export const GET_PRODUCT_BY_VERSION = createRequestTypes('GET_PRODUCT_BY_VERSION');
export const SET_PRODUCT_CATEGORIES = createRequestTypes('SET_PRODUCT_CATEGORIES');
export const SELECT_PRODUCT = 'SELECT_PRODUCT';
export const DOWNLOAD_PDF_PRODUCT = createRequestTypes('DOWNLOAD_PDF_PRODUCT');
export const SET_PRODUCT_TABLE_FILTER = 'SET_PRODUCT_TABLE_FILTER';
export const SET_PRODUCT_TABLE_SORTING = 'SET_PRODUCT_TABLE_SORTING';

export const RESET_PRODUCT = 'RESET_PRODUCT';

export const getProductConstants = {
  request: () => action(GET_PRODUCT_CONSTANTS[REQUEST]),
  success: (payload) => action(GET_PRODUCT_CONSTANTS[SUCCESS], { payload }),
  failure: (payload) => action(GET_PRODUCT_CONSTANTS[FAILURE], { payload }),
};

export const getProducts = {
  request: (data = {}) => action(GET_PRODUCT_LIST[REQUEST], { data }),
  success: (payload) => action(GET_PRODUCT_LIST[SUCCESS], { payload }),
  failure: (payload) => action(GET_PRODUCT_LIST[FAILURE], { payload }),
};

export const getFilteredProducts = {
  request: (data) => action(GET_FILTERED_PRODUCT_LIST[REQUEST], { data }),
  success: (payload) => action(GET_FILTERED_PRODUCT_LIST[SUCCESS], { payload }),
  failure: (payload) => action(GET_FILTERED_PRODUCT_LIST[FAILURE], { payload }),
};

export const getProduct = {
  request: (id) => action(GET_PRODUCT[REQUEST], { id }),
  success: (payload) => action(GET_PRODUCT[SUCCESS], { payload }),
  failure: (payload) => action(GET_PRODUCT[FAILURE], { payload }),
};

export const createProduct = {
  request: (data) => action(CREATE_PRODUCT[REQUEST], { data }),
  success: (payload) => action(CREATE_PRODUCT[SUCCESS], { payload }),
  failure: (payload) => action(CREATE_PRODUCT[FAILURE], { payload }),
};

export const updateProduct = {
  request: (id, data) => action(UPDATE_PRODUCT[REQUEST], { id, data }),
  success: (payload) => action(UPDATE_PRODUCT[SUCCESS], { payload }),
  failure: (payload) => action(UPDATE_PRODUCT[FAILURE], { payload }),
};

export const deleteProduct = {
  request: (id) => action(DELETE_PRODUCT[REQUEST], { id }),
  success: (payload) => action(DELETE_PRODUCT[SUCCESS], { payload }),
  failure: (payload) => action(DELETE_PRODUCT[FAILURE], { payload }),
};

export const setProductCategories = {
  request: (id, data) => action(SET_PRODUCT_CATEGORIES[REQUEST], { id, data }),
  success: (payload) => action(SET_PRODUCT_CATEGORIES[SUCCESS], { payload }),
  failure: (payload) => action(SET_PRODUCT_CATEGORIES[FAILURE], { payload }),
};

export const downloadPdf = {
  request: (data) => action(DOWNLOAD_PDF_PRODUCT[REQUEST], { data }),
  success: (payload) => action(DOWNLOAD_PDF_PRODUCT[SUCCESS], { payload }),
  failure: (payload) => action(DOWNLOAD_PDF_PRODUCT[FAILURE], { payload }),
};

export const selectProduct = (data) => ({
  type: SELECT_PRODUCT,
  payload: data,
});

export const setProductTableFilter = (payload) => ({
  type: SET_PRODUCT_TABLE_FILTER,
  payload,
});

export const setProductTableSorting = (payload) => ({
  type: SET_PRODUCT_TABLE_SORTING,
  payload,
});

export const resetProduct = () => ({
  type: RESET_PRODUCT,
});
