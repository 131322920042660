import { takeEvery, all, put, takeLatest } from 'redux-saga/effects';
import { apiRequest } from './index';
import CubeGroupsApi from '../api/CubeGroups';
import * as cubeGroupsActions from 'actions/CubeGroups';
import { toast } from 'react-toastify';
import history from 'history/browser';

const getCubeGroups = apiRequest.bind(null, cubeGroupsActions.getCubeGroups, CubeGroupsApi.getCubeGroups);
const getAllCubeGroups = apiRequest.bind(null, cubeGroupsActions.getAllCubeGroups, CubeGroupsApi.getAllCubeGroups);
const getCubeGroup = apiRequest.bind(null, cubeGroupsActions.getCubeGroup, CubeGroupsApi.getCubeGroup);

function* createCubeGroup({ data, options }) {
  const { response, error } = yield CubeGroupsApi.createCubeGroup(data);
  if (error) {
    yield put({
      type: cubeGroupsActions.CREATE_CUBE_GROUP.FAILURE,
      payload: error,
    });
    return;
  }
  yield put({
    type: cubeGroupsActions.CREATE_CUBE_GROUP.SUCCESS,
    payload: response,
  });
}

function* deleteCubeGroup({ id }) {
  const { error } = yield CubeGroupsApi.deleteCubeGroup(id);
  if (error) {
    yield put({ type: cubeGroupsActions.DELETE_CUBE_GROUP.FAILURE, payload: error });
  } else {
    yield put({ type: cubeGroupsActions.DELETE_CUBE_GROUP.SUCCESS, payload: id });
  }
}

function* updateCubeGroup({ id, data, options }) {
  const { response, error } = yield CubeGroupsApi.updateCubeGroup(data, id);
  if (error) {
    yield put({
      type: cubeGroupsActions.UPDATE_CUBE_GROUP.FAILURE,
      payload: error,
    });
    return;
  }
  yield put({
    type: cubeGroupsActions.UPDATE_CUBE_GROUP.SUCCESS,
    payload: response,
  });

  if (options === 'redirect') {
    yield history.push('/cube-groups');
  }
}

const successSaveHandler = () => {
  toast.success('Gespeichert');
};

/******************************************************************************/
/******************************* WATCHERS *************************************/

/******************************************************************************/
export function* rootWatcherCubeGroups() {
  yield all([
    takeLatest(cubeGroupsActions.GET_CUBE_GROUPS.REQUEST, getCubeGroups),
    takeLatest(cubeGroupsActions.GET_ALL_CUBE_GROUPS.REQUEST, getAllCubeGroups),
    takeLatest(cubeGroupsActions.GET_CUBE_GROUP.REQUEST, getCubeGroup),
    takeEvery(cubeGroupsActions.UPDATE_CUBE_GROUP.REQUEST, updateCubeGroup),
    takeEvery(cubeGroupsActions.CREATE_CUBE_GROUP.REQUEST, createCubeGroup),
    takeEvery(cubeGroupsActions.DELETE_CUBE_GROUP.REQUEST, deleteCubeGroup),
    takeEvery(cubeGroupsActions.UPDATE_CUBE_GROUP.SUCCESS, successSaveHandler),
    takeEvery(cubeGroupsActions.CREATE_CUBE_GROUP.SUCCESS, successSaveHandler),
  ]);
}
