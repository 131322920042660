import axios from 'axios';
import { get, omit } from 'lodash';

import { dmsUrl } from 'config/environment';

const getCubeGroups = (data) => {
  const params = {
    size: data.size || 100,
    page: data.page || 0,
  };
  if (data.textContains) params.textContains = get(data, 'textContains', '');
  if (data.sortBy) {
    params[`sort.${data.sortBy}.direction`] = get(data, 'sortDirection', '');
    params[`sort.${data.sortBy}.order`] = 0;
  }
  return axios
    .get(`${dmsUrl}/api/groups/v1.0/`, { params: params })
    .then((response) => ({ response: response.data }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const getCubeGroup = (id) => {
  return axios
    .get(`${dmsUrl}/api/groups/${id}/v1.0/`)
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: get(err, 'response.data.message', 'Die Anfrage ist fehlgeschlagen') || 'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const getAllCubeGroups = () => {
  return axios
    .get(`${dmsUrl}/api/groups/all/v1.0/`)
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: get(err, 'response.data.message', 'Die Anfrage ist fehlgeschlagen') || 'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const createCubeGroup = (data) => {
  return axios
    .post(`${dmsUrl}/api/groups/v1.0/`, omit(data, 'redirect'))
    .then((response) => {
      if (data.redirect) data.redirect(response.data.id);
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: get(err, 'response.data.message', 'Die Anfrage ist fehlgeschlagen') || 'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const updateCubeGroup = (data, id) => {
  return axios
    .put(`${dmsUrl}/api/groups/${id}/v1.0/`, omit(data, 'redirect'))
    .then((response) => {
      if (data.redirect) data.redirect();
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: get(err, 'response.data.message', 'Die Anfrage ist fehlgeschlagen') || 'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const deleteCubeGroup = (id) => {
  return axios
    .delete(`${dmsUrl}/api/groups/${id}/v1.0/`)
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: get(err, 'response.data.message', 'Die Anfrage ist fehlgeschlagen') || 'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

export default {
  getCubeGroups,
  getCubeGroup,
  createCubeGroup,
  updateCubeGroup,
  deleteCubeGroup,
  getAllCubeGroups,
};
