import * as healthProfileActions from 'actions/HealthProfile';
import { all, takeEvery, takeLatest } from 'redux-saga/effects';
import { apiRequest } from './index';
import HealthProfilesApi from '../api/HealthProfiles';

const getHealthProfiles = apiRequest.bind(null, healthProfileActions.getHealthProfiles, HealthProfilesApi.getHealthProfiles);
const getHealthProfile = apiRequest.bind(null, healthProfileActions.getHealthProfile, HealthProfilesApi.getHealthProfile);

export function* rootWatcherHealthProfile() {
  yield all([takeLatest(healthProfileActions.GET_HEALTH_PROFILES.REQUEST, getHealthProfiles), takeLatest(healthProfileActions.GET_HEALTH_PROFILE.REQUEST, getHealthProfile)]);
}
