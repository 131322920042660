import * as ActionTypes from 'actions/User';
import * as AuthActionTypes from 'actions/Auth';
import { get } from 'lodash';

const initialState = {
  users: [],
  user: null,
  results: null,
  usersPage: 0,
  selectedUsers: {},
  tableFilter: '',
  tableSorting: [{ desc: true, id: "joinDate" }],
  userProducts: [],
};
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.RESET_USER_PROPS:
    case AuthActionTypes.LOGOUT.SUCCESS:
      return { ...initialState };
    case ActionTypes.SET_USERS_TABLE_FILTER:
      return { ...state, tableFilter: action.payload };
    case ActionTypes.SET_USERS_TABLE_SORTING:
      return { ...state, tableSorting: action.payload };
    case ActionTypes.GET_USERS.SUCCESS:
      return {
        ...state,
        users: get(action.payload, 'content'),
        usersPage: get(action, 'payload.currentPage', 0),
        usersPages: get(action, 'payload.totalPages', 0),
      };
    case ActionTypes.SELECT_USERS:
      return {
        ...state,
        selectedUsers: action.payload,
      };
    case ActionTypes.GET_USER.SUCCESS:
      return {
        ...state,
        user: action.payload,
      };
    case ActionTypes.GET_USER_WITH_GROUPS.SUCCESS:
      return {
        ...state,
        userWithGroups: action.payload,
        user: get(action, 'payload.user', null),
        userGroups: get(action, 'payload.userGroups', []),
      };
    case ActionTypes.GET_USER_RESULTS.SUCCESS:
      return {
        ...state,
        results: action.payload,
      };
    case ActionTypes.GET_USER_PRODUCTS.SUCCESS:
      return {
        ...state,
        userProducts: action.payload,
      };
    case ActionTypes.DELETE_USER.SUCCESS:
      return {
        ...state,
        users: state.users.filter((user) => user.id !== action.payload),
      };
    case ActionTypes.DELETE_USERS.SUCCESS:
      return {
        ...state,
        users: state.users.filter((user) => !action.payload.includes(user.id)),
      };
    default:
      return state;
  }
}
